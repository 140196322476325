import {
  ApolloClient, InMemoryCache, split,
} from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import store from './store'

const apiUrl = process.env.NODE_ENV === 'production' ? 'https://sokolink-api.herokuapp.com/graphql' : 'http://localhost:5000/graphql'
const socketUrl = process.env.NODE_ENV === 'production' ? 'wss://sokolink-api.herokuapp.com/graphql' : 'ws://localhost:5000/graphql'

const wsLink = new WebSocketLink({
  uri: socketUrl,
  options: {
    reconnect: true,
  },
})

const authLink = setContext(async (_, { headers }) => {
  const user = store.getState().user?.user
  const token = await user?.getIdToken()
  const authHeader = token ? { AuthToken: token } : {}
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...authHeader,
    },
  }
})

const httpLink = createUploadLink({
  uri: apiUrl,
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(httpLink),
)

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
})

export default client
