import { gql } from '@apollo/client'

export const GET_SETTLEMENTS = gql`
query getTransactions(
  $limit: Int!
  $offset: Int!
  $transactionId: ID
  $status: String
  $period: PeriodFilter
) {
  settlements(
    limit: $limit
    offset: $offset
    transactionId: $transactionId
    status: $status
    period: $period
  ) {
    transactionId
    storeName
    deliveryPartnerCharges
    gateWayCharges
    gateWayChargesBearer
    totalCharges
    netAmount
    grossAmount
    transactionStatus
    transactionType
    paymentMethod
    payoutBankName
    payoutAccountName
    payoutAccountNumber
    transactionDetails
    previousSettlementDate
    postedOn
  }
}
`

export const GET_PAYMENTS = gql`
query getTransactions(
  $limit: Int!
  $offset: Int!
  $transactionId: ID
  $status: String
  $period: PeriodFilter
) {
  payments(
    limit: $limit
    offset: $offset
    transactionId: $transactionId
    status: $status
    period: $period
  ) {
    transactionId
    grossAmount
    sokolinkCharges
    deliveryPartnerCharges
    gateWayCharges
    gateWayChargesBearer
    totalCharges
    grossAmount
    netAmount
    transactionStatus
    transactionType
    paymentMethod
    orderNo
    customer {
      name
      phone
    }
    transactionDetails
    postedOn
  }
}
`
