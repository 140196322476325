import { gql } from '@apollo/client'

export const GET_ORDERS = gql`
query getOrders(
  $limit: Int!
  $offset: Int!
  $status: [String!]!
  $orderNo: String
  $period: PeriodFilter
) {
  merchantOrders(
    limit: $limit
    offset: $offset
    status: $status
    orderNo: $orderNo
    period: $period
  ) {
    id
    storeId
    orderNo
    orderType
    deliveryType
    amount
    discountAmount
    discountCode
    deliveryFees
    serviceFee
    total
    paymentStatus
    orderStatus
    customer {
      name
      phone
    }
    rider {
      id
      name
      phone
    }
    products {
      name
      category
      price
      variantOption
      extras
      customizations {
        title
        options {
          name
          price
        }
      }
      quantity
      instructions
    }
    table {
      id
      name
      room {
        id
        name
      }
    }
    deliveryLocation {
      placeId
      lat
      lng
      name
    }
    pastRadiusConsent
    note
    trackingLink
    postedOn
  }
}
`
export const UPDATE_ORDER = gql`
mutation updateOrder($orderNo: ID!, $status: String!, $prepTime: Int $cancelReason: String) {
  updateOrder(orderNo: $orderNo, status: $status, prepTime: $prepTime, cancelReason: $cancelReason) {
    id
    storeId
    orderNo
    orderType
    deliveryType
    discountAmount
    discountCode
    amount
    deliveryFees
    serviceFee
    total
    paymentStatus
    orderStatus
    customer {
      name
      phone
    }
    rider {
      id
      name
      phone
    }
    products {
      id
      name
      category
      price
      variantOption
      extras
      customizations{
      title
      options{
        name
        price
      }
      }
      quantity
      instructions
    }
    table {
      id
      name
      room { id, name }
    }
    deliveryLocation {
      placeId
      lat
      lng
      name
    }
    note
    trackingLink
    postedOn
  }
}
`

export const ORDER_SUBSCRIPTION = gql`
subscription OnorderUpdated($orderNo: String!) {
  orderUpdated(orderNo: $orderNo) {
    id
    storeId
    orderNo
    orderType
    deliveryType
    amount
    discountAmount
    discountCode
    deliveryFees deliveryFees
    total
    serviceFee
    total
    paymentStatus
    orderStatus
    customer {
      name
      phone
    }
    rider {
      id
      name
      phone
    }
    products {
      id
      name
      category
      price
      variantOption
      extras
      customizations{
      title
      options{
        name
        price
      }
      }
      quantity
      instructions
    }
    table {
      id
      name
      room { id, name }
    }
    deliveryLocation {
      placeId
      lat
      lng
      name
    }
    note
    trackingLink
    postedOn
  }
}
`

export const NEW_ORDER = gql`
subscription OnNewOrder($merchantId: ID!, $storeId: ID) {
  newOrder(merchantId: $merchantId, storeId: $storeId) {
    id
    storeId
    orderNo
    orderType
    deliveryType
    amount
    discountAmount
    discountCode
    deliveryFees
    serviceFee
    total
    paymentStatus
    orderStatus
    customer {
      name
      phone
    }
    rider {
      id
      name
      phone
    }
    products {
      id
      name
      category
      price
      variantOption
      extras
      customizations{
      title
      options{
        name
        price
      }
      }
      quantity
      instructions
    }
    table {
      id
      name
      room { id, name }
    }
    deliveryLocation {
      placeId
      lat
      lng
      name
    }
    note
    trackingLink
    postedOn
  }
}
`

export const GET_STORE_RIDERS = gql`
query GetStoreRiders($storeId: ID!) {
  storeRiders(storeId: $storeId) {
    id
    name
    phone
    isOnline
    storeDistance
  }
}
`

export const ASSIGN_RIDER_ORDER = gql`
mutation assignRiderOrder($riderId: ID!, $orderNo: ID!) {
  assignRiderOrder(riderId: $riderId, orderNo: $orderNo) {
    rider {
      id
      name
      phone
    }
  }
}
`
