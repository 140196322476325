import { gql } from '@apollo/client'

export const ADD_SMS_CAMPAIGN = gql`
mutation AddCampaign(
    $name: String!
    $senderName: String!
    $storeId: ID
    $targetCustomers: String!
    $customerLimit: Int
    $customerOffset: Int
    $sortField: String
    $sortOrder: String
    $message: String!
  ) {
    createSmsCampaign(
      name: $name
      senderName: $senderName
      storeId: $storeId
      targetCustomers: $targetCustomers
      customerLimit: $customerLimit
      customerOffset: $customerOffset
      sortField: $sortField
      sortOrder: $sortOrder
      message: $message
    ) {
      id
      merchantId
      storeId
      name
      senderName
      targetCustomers
      customerLimit
      customerOffset
      sortField
      sortOrder
      message
      status
      reach
      sentOn
      createdOn
    }
  }
`

export const EDIT_SMS_CAMPAIGN = gql`
mutation EditCampaign(
  $id: ID!
  $name: String!
  $senderName: String!
  $storeId: ID
  $targetCustomers: String!
  $customerLimit: Int
  $customerOffset: Int
  $sortField: String
  $sortOrder: String
  $message: String!
) {
  updateSmsCampaign(
    id: $id
    name: $name
    senderName: $senderName
    storeId: $storeId
    targetCustomers: $targetCustomers
    customerLimit: $customerLimit
    customerOffset: $customerOffset
    sortField: $sortField
    sortOrder: $sortOrder
    message: $message
  ) {
    id
    merchantId
    storeId
    name
    senderName
    targetCustomers
    customerLimit
    customerOffset
    sortField
    sortOrder
    message
    status
    reach
    sentOn
    createdOn
  }
}
`

export const SMS_CAMPAIGNS = gql`
query SmsCampaigns(
    $status: String
    $limit: Int!
    $offset: Int!
    $name: String
  ) {
    smsCampaigns(
      status: $status
      limit: $limit
      offset: $offset
      name: $name
    ) {
      id
      merchantId
      storeId
      name
      senderName
      targetCustomers
      customerLimit
      customerOffset
      sortField
      sortOrder
      message
      status
      reach
      sentOn
      createdOn
    }
  }  
`

export const BROADCAST_SMS_CAMPAIGN = gql`
mutation BroadcastCampaign(
  $id: ID!
) {
  broadcastSmsCampaign(
    id: $id
  ) {
    id
    merchantId
    storeId
    name
    senderName
    targetCustomers
    customerLimit
    customerOffset
    sortField
    sortOrder
    message
    status
    reach
    sentOn
    createdOn
  }
}
`
