import { useLazyQuery } from '@apollo/client'
import {
  Box, Skeleton, Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import ErrorLoading from '../layout/ErrorLoading'
import { EXPERIENCE_SUMMARY } from '../../graphql/customers'

const LoadingSkeleton = () => (
  <Skeleton
    sx={{
      height: '80px', borderRadius: 2, my: 1,
    }}
    variant="rectangular"
    width="100%"
    animation="pulse"
  />
)

export default function CustomersExperienceSummary({ storeId, period }) {
  const [summary, setSummary] = useState(null)
  const [error, setError] = useState(null)

  const handleResponse = (data) => {
    setError(null)
    if (data.customerExperienceSummary) {
      setSummary(data.customerExperienceSummary)
    }
  }
  const [getSummary, { loading }] = useLazyQuery(EXPERIENCE_SUMMARY, {
    variables: {
      storeId: storeId !== 'all' ? storeId : null,
      period,
    },
    fetchPolicy: 'network-only',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })

  useEffect(() => {
    getSummary()
  }, [storeId])
  if (loading) return <LoadingSkeleton />
  if (error || !summary) return <ErrorLoading fullHeight={false} visible={error && !loading} reload={getSummary} />
  const {
    avgRating, avgAcceptanceTime, avgPreparationTime, avgDeliveryTime, cancellationRate,
  } = summary
  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', overflowX: 'auto',
      }}
      >
        <Box sx={{
          flex: 0.19, textAlign: 'center', p: 1, mr: 1, border: '1px solid #ddd', borderRadius: 1,
        }}
        >
          <Typography fontSize={20}>Avg Rating</Typography>
          <Typography>
            {avgRating}
            <Typography component="span" fontSize={11} sx={{ ml: 1 }}>stars</Typography>
          </Typography>
        </Box>
        <Box sx={{
          flex: 0.19, textAlign: 'center', p: 1, mr: 1, border: '1px solid #ddd', borderRadius: 1,
        }}
        >
          <Typography fontSize={20}>Avg Acceptance Time</Typography>
          <Typography>
            {avgAcceptanceTime}
            <Typography component="span" fontSize={11} sx={{ ml: 1 }}>mins</Typography>
          </Typography>
        </Box>
        <Box sx={{
          flex: 0.19, textAlign: 'center', p: 1, mr: 1, border: '1px solid #ddd', borderRadius: 1,
        }}
        >
          <Typography fontSize={20}>Avg Preparation Time</Typography>
          <Typography>
            {avgPreparationTime}
            <Typography component="span" fontSize={11} sx={{ ml: 1 }}>mins</Typography>
          </Typography>
        </Box>
        <Box sx={{
          flex: 0.19, textAlign: 'center', p: 1, mr: 1, border: '1px solid #ddd', borderRadius: 1,
        }}
        >
          <Typography fontSize={20}>Avg Delivery Time</Typography>
          <Typography>
            {avgDeliveryTime}
            <Typography component="span" fontSize={11} sx={{ ml: 1 }}>mins</Typography>
          </Typography>
        </Box>
        <Box sx={{
          flex: 0.19, textAlign: 'center', p: 1, mr: 1, border: '1px solid #ddd', borderRadius: 1,
        }}
        >
          <Typography fontSize={20}>Cancellation Rate</Typography>
          <Typography>
            {cancellationRate}
            <Typography component="span" fontSize={11} sx={{ ml: 1 }}>%</Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
