import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Box, Button } from '@mui/material'
import ErrorLoading from '../components/layout/ErrorLoading'
import CenterLoader from '../components/spinners/CenterLoader'
import '../styles/stores.css'
import Pagination from '../components/layout/Pagination'
import FilterForm from '../components/layout/FilterForm'
import FilterName from '../components/inputs/FilterName'
import NoData from '../components/layout/NoData'
import AddCouponModal from '../components/coupons/AddCouponModal'
import CouponsView from '../components/coupons/CouponsView'
import { COUPONS } from '../graphql/coupons'

export default function CouponsPage() {
  const [coupons, setCoupons] = useState(null)
  const limit = 10
  const [page, setPage] = useState(1)
  const [error, setError] = useState(null)
  const start = page === 1 ? 0 : (page - 1) * limit
  const [code, setCode] = useState('')
  const [codeSearch, setCodeSearch] = useState('')
  const [addModalOpen, toggleAddModal] = useState(false)

  const handleResponse = (data) => {
    setError(null)
    setCoupons(data.discountCoupons)
  }
  const [getCoupons, { loading }] = useLazyQuery(COUPONS, {
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  const getCouponsReq = () => {
    getCoupons({
      variables: {
        limit,
        offset: start,
        code: codeSearch || null,
      },
    })
  }
  const clearCodeSearch = () => {
    setCode('')
    setCodeSearch('')
  }
  const handleCodeSearch = (e) => {
    setCode(e.target.value)
    if (e.target.value?.length >= 4) {
      setCodeSearch(e.target.value)
    }
    if (!e.target.value) {
      clearCodeSearch()
    }
  }
  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextPage = () => {
    if (!(coupons.length < limit)) {
      setPage(page + 1)
    }
  }

  const addNewCoupon = (coupon) => {
    setCoupons([coupon, ...coupons])
  }

  const removeCoupon = (id) => {
    setCoupons(coupons.filter((c) => (c.id !== id)))
  }

  const updateCoupon = (coupon) => {
    const newCoupons = coupons.map((c) => {
      if (c.id === coupon.id) return coupon
      return c
    })
    setCoupons(newCoupons)
  }

  useEffect(() => {
    getCouponsReq()
  }, [page, codeSearch])

  return (
    <div className="page-content">
      <Box sx={{
        my: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between',
      }}
      >
        <div className="page-tittle no-margin">Discount Coupons</div>
        <Button variant="contained" onClick={() => { toggleAddModal(true) }}>Create Coupon</Button>
      </Box>
      {addModalOpen ? (
        <AddCouponModal
          close={() => { toggleAddModal(false) }}
          variables={{
            limit,
            offset: start,
            code: codeSearch || null,
          }}
          addNewCoupon={addNewCoupon}
          updateCoupon={updateCoupon}
        />
      ) : null}
      <FilterForm>
        <FilterName name="Code" value={code} changeHandler={handleCodeSearch} clear={clearCodeSearch} label="Search Code:" type="text" placeholder="code" />
      </FilterForm>
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getCoupons} />
      <CouponsView visible={!loading && !error && coupons} coupons={coupons} removeCoupon={removeCoupon} />
      <NoData visible={!loading && !error && !coupons?.length} />
      <Pagination visible={!loading && !error && (coupons ? coupons.length : false)} page={page} next={nextPage} prev={prevPage} />
    </div>
  )
}
