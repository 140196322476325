/* eslint-disable react/destructuring-assignment */
import {
  AUTHENTICATE_USER,
  SET_USER_INFO,
  LOGOUT_USER,
} from '../actions/types'

const initialState = {
  user: null,
  userInfo: null,
}

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATE_USER:
      return {
        ...state,
        user: action.payload,
      }
    case SET_USER_INFO:
      localStorage.setItem('userInfo', JSON.stringify(action.payload))
      return {
        ...state,
        userInfo: action.payload,
      }
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        userInfo: null,
      }
    default:
      return state
  }
}
