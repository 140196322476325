/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react'
// import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { Download } from '@mui/icons-material'
import {
  Box, Button, IconButton, InputLabel, useTheme,
} from '@mui/material'
import DateRange from '../shared/DateRange'
import { initDateRange, makePeriod } from '../../utils/daterangeinit'

export default function DataDownloadMenu({ defaultRangeDays, download }) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const { initialStartDate, initialEndDate } = initDateRange(defaultRangeDays ?? 30)
  const [selectedRange, setSelectedRange] = useState([initialStartDate, initialEndDate])
  const [activeTab, setActiveTab] = useState(defaultRangeDays)

  const period = makePeriod(selectedRange)

  const theme = useTheme()
  const primaryColor = theme.palette.primary.main

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const changeRange = (days) => {
    const { initialStartDate: newStartDate, initialEndDate: newEndDate } = initDateRange(days)
    setSelectedRange([newStartDate, newEndDate])
    setActiveTab(days)
  }

  const handleDownload = () => {
    download(period)
    setOpen(false)
  }
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  return (
    <div className="download-btn-cont">
      <IconButton ref={anchorRef} onClick={handleToggle} size="small">
        <Download fontSize="small" />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper sx={{ border: '1px solid #ddd' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ p: 1 }}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  // eslint-disable-next-line react/jsx-no-bind
                    onKeyDown={handleListKeyDown}
                    sx={{ display: 'flex', mb: 1 }}
                  >
                    <MenuItem onClick={() => { changeRange(0) }} value={1} sx={{ color: activeTab === 0 ? primaryColor : '', pl: 0 }}>Today</MenuItem>
                    <MenuItem onClick={() => { changeRange(7) }} value={7} sx={{ color: activeTab === 7 ? primaryColor : '' }}>Last 7 days</MenuItem>
                    <MenuItem onClick={() => { changeRange(30) }} sx={{ color: activeTab === 30 ? primaryColor : '' }}>30 Days</MenuItem>
                    <MenuItem onClick={() => { changeRange(365) }} sx={{ color: activeTab === 365 ? primaryColor : '' }}>1 Year</MenuItem>
                  </MenuList>
                  <InputLabel sx={{ mb: 0.5 }}>Custom Date Range</InputLabel>
                  <DateRange size="large" style={{ width: '100%' }} value={selectedRange} onChange={(range) => { setSelectedRange(range); setActiveTab(null) }} />
                  <Button variant="outlined" fullWidth endIcon={<Download />} sx={{ mt: 2 }} onClick={handleDownload}>Download</Button>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
