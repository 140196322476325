import {
  Box, Button, DialogActions, Divider, FormControlLabel, Radio, RadioGroup, TextField, Typography,
} from '@mui/material'
import { Save } from '@mui/icons-material'
import toast from 'react-hot-toast'
import { useReducer } from 'react'
import { useMutation } from '@apollo/client'
import ResponsiveModal from '../layout/ResponsiveModal'
import { UPDATE_STORE } from '../../graphql/locations'

function Actions({ loading, close }) {
  return (
    <DialogActions sx={{
      display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
    }}
    >
      <Button onClick={close} sx={{ mr: 2 }}>Cancel</Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<Save />}
        variant="contained"
        form="save-service-charges-form"
      >
        {loading ? 'Saving...' : 'Save'}
      </Button>
    </DialogActions>
  )
}

// Action Types
const UPDATE_CHARGE = 'UPDATE_CHARGE'

// Reducer function
const chargesReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_CHARGE:
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], ...action.payload.updatedCharge },
      }
    default:
      return state
  }
}

export default function ServiceCharges({
  storeId, update, close, initialCharges,
}) {
  const [serviceCharges, dispatchCharges] = useReducer(chargesReducer, initialCharges || {
    delivery: { type: 'percentage', value: 0, maxValue: null },
    pickup: { type: 'percentage', value: 0, maxValue: null },
    dinein: { type: 'percentage', value: 0, maxValue: null },
    takeaway: { type: 'percentage', value: 0, maxValue: null },
  })

  const services = ['delivery', 'pickup', 'dinein', 'takeaway']

  const updateChargeField = (id, field, value) => {
    dispatchCharges({
      type: UPDATE_CHARGE,
      payload: {
        id,
        updatedCharge: { [field]: value },
      },
    })
  }

  const [updateReq, { loading }] = useMutation(UPDATE_STORE, {
    onError: () => { toast.error('Error updating service charges.') },
    onCompleted: (data) => {
      update(data.updateStore)
      toast.success('Service charges  updated successfully')
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    const formattedCharges = {}
    services.forEach((id) => {
      const { type, value, maxValue } = serviceCharges[id]
      formattedCharges[id] = { type, value: parseFloat(value), maxValue: maxValue ? parseFloat(maxValue) : null }
    })
    updateReq({
      variables: {
        id: storeId,
        serviceCharges: formattedCharges,
      },
    })
  }

  return (
    <ResponsiveModal
      title="Service Charges"
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions loading={loading} close={close} />}
    >
      <Box component="form" id="save-service-charges-form" onSubmit={onSave}>
        {
            services.map((id) => (
              <Box key={id}>
                <Box sx={{
                  display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1,
                }}
                >
                  <Typography sx={{ textTransform: 'capitalize' }}>{id}</Typography>
                  <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    value={serviceCharges[id].type}
                    onChange={(e) => { updateChargeField(id, 'type', e.target.value) }}
                  >
                    <FormControlLabel value="flat" control={<Radio />} label="Flat" />
                    <FormControlLabel value="percentage" control={<Radio />} label="Percentage" />
                  </RadioGroup>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <TextField sx={{ flex: 0.49 }} type="number" value={serviceCharges[id].value !== null ? serviceCharges[id].value : ''} onChange={(e) => { updateChargeField(id, 'value', e.target.value) }} label="Enter Value" size="small" required />
                  <TextField sx={{ flex: 0.49 }} type="number" value={serviceCharges[id].maxValue !== null ? serviceCharges[id].maxValue : ''} onChange={(e) => { updateChargeField(id, 'maxValue', e.target.value) }} label="Maximum Value (Ksh)" size="small" />
                </Box>
                {id !== 'takeaway' && (<Divider sx={{ mt: 2, mb: 1 }} />)}
              </Box>
            ))
        }
      </Box>
    </ResponsiveModal>
  )
}
