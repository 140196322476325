import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLazyQuery, useSubscription } from '@apollo/client'
import Papa from 'papaparse'
import FileSaver from 'file-saver'
import toast from 'react-hot-toast'
import '../styles/orders.css'
import {
  Box, Button, TextField,
} from '@mui/material'
import { debounce } from 'lodash'
import { Refresh } from '@mui/icons-material'
import ErrorLoading from '../components/layout/ErrorLoading'
import CenterLoader from '../components/spinners/CenterLoader'
import FilterForm from '../components/layout/FilterForm'
import Pagination from '../components/layout/Pagination'
import FilterStatus from '../components/orders/FilterStatus'
import { GET_ORDERS, NEW_ORDER } from '../graphql/orders'
import OrdersView from '../components/orders/OrdersView'
import NoData from '../components/layout/NoData'
import { formatDownloadOrders } from '../utils/formatter'
import { ORDERS_DOWNLOAD_LIMIT } from '../utils/limits'

function Orders({ match, userInfo }) {
  const { storeId } = match.params
  const [orders, setOrders] = useState(null)
  const limit = 10
  const [page, setPage] = useState(1)
  const [error, setError] = useState(null)
  const start = page === 1 ? 0 : (page - 1) * limit
  const [searchValue, setSearchValue] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [status, setStatus] = useState('all')

  const debouncedSearch = debounce((searchText) => {
    setSearchQuery(searchText)
  }, 1000)

  const handleResponse = (data) => {
    setError(null)
    setOrders(data.merchantOrders)
  }
  const [getOrders, { loading }] = useLazyQuery(GET_ORDERS, {
    fetchPolicy: 'network-only',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  const getOrdersReq = () => {
    getOrders({
      variables: {
        limit,
        storeId,
        offset: start,
        orderNo: searchQuery || null,
        status: status === 'all' ? ['pending', 'preparing', 'dispatched', 'completed', 'cancelled'] : [status],
      },
    })
  }
  const handleDownloadResponse = (data) => {
    const downloadData = data.merchantOrders || []
    const formattedData = formatDownloadOrders(downloadData)
    const dataStr = JSON.stringify(formattedData)
    const csv = Papa.unparse(dataStr)
    const blob = new Blob([decodeURIComponent(encodeURI(csv))], {
      type: 'text/csv;charset=utf-8;',
    })
    toast.dismiss('downloadspinner')
    toast.success('Data downloaded')
    FileSaver.saveAs(blob, 'orders.csv')
  }
  const [getDownloadData] = useLazyQuery(GET_ORDERS, {
    fetchPolicy: 'cache-and-network',
    onError: () => { toast.dismiss('downloadspinner'); toast.error('Error downloading data') },
    onCompleted: handleDownloadResponse,
  })

  const handleSearch = (event) => {
    const searchText = event.target.value
    setSearchValue(searchText)
    if (!searchText) {
      setSearchQuery('')
    } else {
      debouncedSearch(searchText)
    }
  }

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextPage = () => {
    if (!(orders.length < limit)) {
      setPage(page + 1)
    }
  }
  const download = (period) => {
    toast.loading('Downloading data...', { id: 'downloadspinner' })
    getDownloadData(
      {
        variables: {
          limit: ORDERS_DOWNLOAD_LIMIT,
          offset: 0,
          period,
          status: ['pending', 'preparing', 'dispatched', 'completed', 'cancelled'],
        },
      },
    )
  }

  const handleNewOrder = ({ subscriptionData }) => {
    const newOrder = subscriptionData.data?.newOrder
    setOrders([newOrder, ...orders])
  }

  // eslint-disable-next-line no-unused-vars
  const { data, loading: subLoading, error: subError } = useSubscription(NEW_ORDER, {
    shouldResubscribe: true,
    variables: {
      merchantId: userInfo?.merchantId,
      storeId: userInfo?.storeId,
    },
    onSubscriptionData: handleNewOrder,

  })

  useEffect(() => {
    getOrdersReq()
  }, [page, status, searchQuery])

  return (
    <div className="page-content">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="page-tittle">All Orders</div>
        <Button endIcon={<Refresh />} onClick={() => { getOrdersReq() }} sx={{ color: 'gray' }}>Refresh</Button>
      </Box>
      <FilterForm download={download} defaultRangeDays={0}>
        <FilterStatus label="Order status" value={status} changeHandler={(e) => setStatus(e.target.value)} />
        <TextField type="search" size="small" value={searchValue} onChange={handleSearch} label="Order No:" variant="outlined" />
      </FilterForm>
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getOrders} />
      <OrdersView visible={!loading && !error && orders} orders={orders} />
      <NoData visible={!loading && !error && !orders?.length} />
      <Pagination visible={!loading && !error && orders?.length} page={page} next={nextPage} prev={prevPage} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
})

export default connect(mapStateToProps, {})(Orders)
