/* eslint-disable react/jsx-props-no-spreading */
import { React, useReducer, useState } from 'react'
import {
  Box, Button, DialogActions, TextField,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import toast from 'react-hot-toast'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import ResponsiveModal from '../layout/ResponsiveModal'
import { ADD_CUSTOMIZATION, EDIT_CUSTOMIZATION } from '../../graphql/products'
import CustomizationOptions from '../add-item/CustomizationOptions'
import { useCustomizations } from './context/customizations'

function Actions({ close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        loading={loading}
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mr: 2 }}
        form="save-customization-id"
      >
        {loading ? 'Saving...' : 'Save'}
      </Button>
    </DialogActions>
  )
}

function optionsReducer(options, action) {
  switch (action.type) {
    case 'UPDATE_OPTION':
      return options.map((option, index) => (index === action.optionIndex
        ? { ...option, [action.key]: action.newValue }
        : option))
    case 'ADD_OPTION':
      return [...options, { name: '', price: '', discountPrice: '' }]
    case 'DELETE_OPTION':
      return options.filter((option, index) => index !== action.optionIndex)
    default:
      return options
  }
}

export default function SaveCustomization({
  close, customization, update,
}) {
  const { addCustomization } = useCustomizations()
  const [title, setTitle] = useState(customization?.title || '')
  const [required, setRequired] = useState(customization?.required || false)
  const [minSelect, setMin] = useState(customization?.minSelect || 1)
  const [maxSelect, setMax] = useState(customization?.maxSelect || 1)
  const [options, optionsDispatch] = useReducer(optionsReducer, customization?.options || [{ name: '', price: '' }])

  const { catalogueId } = useParams()

  const addOption = () => {
    optionsDispatch({ type: 'ADD_OPTION' })
  }
  const handleOptionChange = (i, key, e) => {
    optionsDispatch({
      type: 'UPDATE_OPTION', optionIndex: i, key, newValue: e.target.value,
    })
  }
  const removeOption = (i) => {
    optionsDispatch({ type: 'DELETE_OPTION', optionIndex: i })
  }

  const [createReq, { loading }] = useMutation(ADD_CUSTOMIZATION, {
    onError: () => { toast.error('Error creating customization.') },
    onCompleted: (data) => {
      addCustomization(data.addCustomization)
      toast.success('Customization created successfully')
      close()
    },
  })

  const [updateReq, { loading: updating }] = useMutation(EDIT_CUSTOMIZATION, {
    onError: () => { toast.error('Error updating customization.') },
    onCompleted: (data) => {
      toast.success(`Customization ${title} updated successfully`)
      update(data.updateCustomization)
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    if (customization) {
      updateReq({
        variables: {
          id: customization.id,
          title,
          required,
          minSelect: parseInt(minSelect, 10),
          maxSelect: parseInt(maxSelect, 10),
          options: options.map((v) => ({ name: v.name, price: parseFloat(v.price) || 0 })),
        },
      })
    } else {
      createReq({
        variables: {
          catalogueId,
          title,
          required,
          minSelect: parseInt(minSelect, 10),
          maxSelect: parseInt(maxSelect, 10),
          options: options.map((v) => ({ name: v.name, price: parseFloat(v.price) || 0 })),
        },
      })
    }
  }
  return (
    <ResponsiveModal
      title={customization ? 'Edit Customization' : 'Create Customization'}
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions loading={loading || updating} close={close} />}
    >
      <Box component="form" id="save-customization-id" onSubmit={onSave}>
        <TextField type="text" size="small" required value={title} onChange={(e) => { setTitle(e.target.value) }} label="Title" variant="outlined" fullWidth />
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={required} onChange={(e) => { setRequired(e.target.checked) }} />} label="Require Customer to select an item?" />
        </FormGroup>
        <Box sx={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1,
        }}
        >
          <TextField type="number" size="small" required value={minSelect} onChange={(e) => { setMin(e.target.value) }} label="Minimum Select Items" variant="outlined" sx={{ flex: 0.48 }} />
          <TextField type="number" size="small" required value={maxSelect} onChange={(e) => { setMax(e.target.value) }} label="Maximum Select Items" defaultValue={options?.length} variant="outlined" sx={{ flex: 0.48 }} />
        </Box>
        <CustomizationOptions options={options} deleteOption={removeOption} addOption={addOption} handleOptionChange={handleOptionChange} custom />
      </Box>
    </ResponsiveModal>
  )
}
