import React from 'react'
import PhoneIcon from '@mui/icons-material/Phone'
import './styles/Welcome.css'
// import { Email } from '@mui/icons-material'

export default function Welcome() {
  return (
    <div className="welcome">
      <h2>Welcome to Sokolink Merchant Panel</h2>
      <p>On this panel, you can manage your sales channels as well as carry out numerous operational processes like order management and payment processing. If you need assistance, please contact us at the phone number listed below.</p>
      <div className="support">
        <div className="contact">
          <PhoneIcon style={{ color: '#4c0979', fontSize: '25' }} />
          <strong>0702029444</strong>
        </div>
        {/* <div className="contact">
          <Email style={{ color: '#4c0979', fontSize: '25' }} />
          <strong>support@sokolink.co</strong>
        </div> */}
      </div>
    </div>
  )
}
