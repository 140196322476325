/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  Delete, Download, Edit, Share,
} from '@mui/icons-material'
import { Chip, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import { formatMoney } from '../../utils/formatter'
import DeleteDialog from '../shared/DeleteDialog'
import AddCouponModal from './AddCouponModal'
import DownloadCouponDialog from './DownloadCoupon'
import ShareCoupon from './ShareCoupon'
import './styles/CouponsView.css'
import { DELETE_COUPON } from '../../graphql/coupons'

function Coupon({ coupon: serverCoupon, removeCoupon }) {
  const [coupon, setCoupon] = useState(serverCoupon)
  const {
    title, code, startDate, endDate, discountType,
    discountAmount, id, maxUsageLimit, usageCount,
  } = coupon
  const [editOpen, toggleEdit] = useState(false)
  const [downloadOpen, toggleDownload] = useState(false)
  const [shareOpen, toggleShare] = useState(false)
  const [deleteOpen, toggleDelete] = useState(false)

  const amount = discountType === 'fixed' ? formatMoney(discountAmount) : `${discountAmount}%`
  const deleteMsg = `Are you sure you want to delete coupon ${code.toUpperCase()} ?`
  let status = 'active'
  if (Date.now() > new Date(endDate).valueOf()) {
    status = 'expired'
  }
  if (Date.now() < new Date(startDate).valueOf()) {
    status = 'pending'
  }

  const chipColor = () => {
    if (status === 'pending') return 'primary'
    if (status === 'expired') return 'error'
    return 'success'
  }

  const [deleteCouponReq] = useMutation(DELETE_COUPON, {
    onError: () => { toast.error(`Error removing discount coupon ${title}.`) },
    onCompleted: () => { toast.success(`Coupon ${title} successfully`); removeCoupon(id) },
  })
  const handleDelete = () => {
    deleteCouponReq({
      variables: {
        id,
      },
    })
    toggleDelete(false)
  }
  const updateCoupon = (clientCoupon) => {
    setCoupon(clientCoupon)
  }
  return (
    <>
      { editOpen ? <AddCouponModal close={() => { toggleEdit(false) }} coupon={coupon} updateCoupon={updateCoupon} /> : null }
      <DownloadCouponDialog visible={downloadOpen} close={() => { toggleDownload(false) }} coupon={coupon} />
      <ShareCoupon visible={shareOpen} close={() => { toggleShare(false) }} coupon={coupon} />
      <DeleteDialog visible={deleteOpen} close={() => { toggleDelete(false) }} title="Delete Coupon" msg={deleteMsg} onDelete={handleDelete} />
      <tr className="coupon">
        <td onClick={() => { toggleEdit(true) }}>
          <u><p>{title}</p></u>
        </td>
        <td>
          <p>{code}</p>
        </td>
        <td>
          <p>{amount}</p>
        </td>
        <td>
          <p>{`${usageCount} / ${maxUsageLimit}`}</p>
        </td>
        <td>
          <Chip color={chipColor()} label={status} />
        </td>
        <td className="store-actions">
          <IconButton sx={{ mr: 1.5 }} onClick={() => { toggleEdit(true) }}>
            <Edit />
          </IconButton>
          <IconButton color="primary" sx={{ mr: 1.5 }} onClick={() => { toggleDownload(true) }}>
            <Download />
          </IconButton>
          <IconButton sx={{ mr: 1.5, color: 'green' }} onClick={() => { toggleShare(true) }}>
            <Share />
          </IconButton>
          <IconButton sx={{ mr: 1.5, color: 'red' }} onClick={() => { toggleDelete(true) }}>
            <Delete />
          </IconButton>
        </td>
      </tr>
    </>
  )
}

export default function CouponsView({ visible, coupons, removeCoupon }) {
  if (!visible) {
    return null
  }
  return (
    <div className="table-container">
      <table className="coupons-table">
        <thead>
          <tr>
            <th className="title-hd">Title</th>
            <th className="code-hd">Code</th>
            <th className="value-hd">Value</th>
            <th className="usage-hd">Usage</th>
            <th className="status-hd">Status</th>
            <th className="actions-hd">Actions</th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((c) => <Coupon key={c.code} coupon={c} removeCoupon={removeCoupon} />)}
        </tbody>
      </table>
    </div>

  )
}
