import { Box, Typography } from '@mui/material'
import React from 'react'

const NoData = ({ visible }) => {
  if (!visible) {
    return null
  }
  return (
    <Box sx={{
      width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2,
    }}
    >
      <Box sx={{ background: '#ddd', p: 1, borderRadius: 1 }}>
        <Typography>No Data Found</Typography>
      </Box>
    </Box>
  )
}

export default NoData
