import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import {
  Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography,
} from '@mui/material'
import { UPDATE_MERCHANT } from '../../graphql/merchant'

export default function BusinessInfo({ merchant, updateMerchant }) {
  const [businessName, setbusinessName] = useState(merchant.businessName)
  const [businessDesc, setbusinessDesc] = useState(merchant.businessDesc)
  const [location, setLocation] = useState(merchant.location)
  const [phone, setPhone] = useState(merchant.phone)
  const [category, setCategory] = useState(merchant.category)

  const categories = ['Restaurant', 'Fast Food', 'Grocery', 'Pharmacy', 'Bakery', 'Liquor Store', 'Flower Shop', 'Mall']

  const handleErrors = () => {
    toast.error('error updating details')
  }

  const handleResponse = (data) => {
    updateMerchant(data.updateMerchantDetails)
    toast.success('details updated')
  }

  const [updateSettingsReq, { loading }] = useMutation(UPDATE_MERCHANT, { onError: handleErrors, onCompleted: handleResponse })

  const handleSubmit = (e) => {
    e.preventDefault()
    updateSettingsReq({
      variables: {
        businessName,
        location,
        businessDesc,
        category,
      },
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '5px',
      }}
      >
        <Typography>Business Details</Typography>
        <Button type="submit">
          { loading ? 'Saving...' : 'Save Details' }
        </Button>
      </Box>
      <Divider />
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth type="text" required label="Business Name" placeholder="business name" value={businessName} onChange={(e) => setbusinessName(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth type="text" required label="Phone Number" placeholder="phone number" value={phone} onChange={(e) => setPhone(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth type="text" required label="Location Details (street, building, floor)" placeholder="more about the location" value={location} onChange={(e) => setLocation(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel sx={{ mt: -0.5 }}>Business Category</InputLabel>
            <Select
              value={category}
              label="Business Category"
              onChange={(e) => setCategory(e.target.value)}
              type="text"
              size="small"
              required
            >
              {categories.map((c) => (<MenuItem key={c} value={c}>{c}</MenuItem>))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField type="text" size="small" value={businessDesc} onChange={(e) => setbusinessDesc(e.target.value)} label="Business Description" placeholder="more about your business" variant="outlined" fullWidth multiline rows={3} />
        </Grid>
      </Grid>
    </form>
  )
}
