import { gql } from '@apollo/client'

export const CREATE_ROOM = gql`
mutation CREATE_ROOM($storeId: ID, $name: String!) {
  createRoom(storeId: $storeId, name: $name) {
    id
    storeId
    name
  }
}
`

export const UPDATE_ROOM = gql`
mutation UPDATE_ROOM($id: ID!, $name: String) {
  updateRoom(id: $id, name: $name) {
    id
    storeId
    name
  }
}
`

export const GET_STORE_ROOMS = gql`
query GetStoreRooms($id: ID!){
  store(id: $id) {
    id
    rooms{ id, name, tables{ id, storeId, name, seats } }
}
}    
`

export const DELETE_ROOM = gql`
mutation DELETE_ROOM($id: ID!) {
  deleteRoom(id: $id) {
    id
  }
}
`

export const CREATE_TABLE = gql`
mutation CREATE_TABLE($roomId: ID!, $name: String!, $seats: Int!) {
  createRoomTable(roomId: $roomId, name: $name, seats: $seats) {
    id
    storeId
    roomId
    name
    seats
  }
}
`

export const UPDATE_TABLE = gql`
mutation UPDATE_TABLE($id: ID!, $name: String, $seats: Int) {
  updateRoomTable(id: $id, name: $name, seats: $seats) {
    id
    storeId
    roomId
    name
    seats
  }
}
`

export const DELETE_TABLE = gql`
mutation DELETE_TABLE($id: ID!) {
  deleteRoomTable(id: $id) {
    id
  }
}
`
