import React, { useState } from 'react'
import LocationInput from './LocationInput'
import Map from './AddressMap'
import ResponsiveModal from '../layout/ResponsiveModal'

export default function SetAddressDialog({ mapLocation, onChange, close }) {
  const [location, setLocation] = useState(mapLocation)

  const save = (newLocation) => {
    onChange(newLocation)
    close()
  }

  return (
    <ResponsiveModal
      title="Set Address"
      visible
      cancellable
      cancel={close}
      fullScreen
    >
      { !location ? <LocationInput visible={!location} onChange={(newPlace) => setLocation(newPlace)} />
        : <Map initialLocation={location} save={save} resetLocation={() => setLocation(null)} close={close} />}
    </ResponsiveModal>
  )
}
