import { DatePicker } from 'antd'

export default function DateRange({
  value, onChange, size, style,
}) {
  const { RangePicker } = DatePicker
  const dateFormat = 'DD/MM/YYYY'

  return (
    <RangePicker onChange={onChange} value={value} allowClear={false} format={dateFormat} size={size || 'small'} style={style} />
  )
}
