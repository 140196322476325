import {
  Brush, Delete, RemoveRedEye, Save,
} from '@mui/icons-material'
import {
  Box, Button, DialogActions, IconButton, TextField,
} from '@mui/material'
import {
  useCallback, useReducer, useState,
} from 'react'
import {
  GoogleMap, useJsApiLoader, Polygon, DrawingManager,
} from '@react-google-maps/api'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import ResponsiveModal from '../layout/ResponsiveModal'
import { UPDATE_STORE } from '../../graphql/locations'

function Actions({ loading, inDrawingMode, toggleMode }) {
  return (
    <DialogActions sx={{
      display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
    }}
    >
      <Button onClick={toggleMode} endIcon={inDrawingMode ? <RemoveRedEye /> : <Brush />} sx={{ mr: 10 }}>
        { inDrawingMode ? 'View Delivery Zones' : ' Draw Delivery Zone' }
      </Button>
      <Button
        type="submit"
        loading={loading}
        startIcon={<Save />}
        variant="contained"
        form="save-delivery-zones-form"
      >
        {loading ? 'Saving...' : 'Save Zones'}
      </Button>
    </DialogActions>
  )
}

function MapWithADrawingManager({
  zones, mapLocation, inDrawingMode, onPolygonComplete, selectedZone,
  updatePolygonPaths, onPolygonClick,
}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAXEkv3ohotrk5e_9rNXxhXZ9RkOjZk9pY',
    libraries: ['drawing'],
  })

  const containerStyle = {
    width: '70%',
    height: 'calc(100vh - 150px)',
  }

  const center = {
    lat: mapLocation.lat || -3.745,
    lng: mapLocation.lng || -38.523,
  }

  const onLoad = useCallback((loadedMap) => {
    const defaultZoom = 13 // Set your desired default zoom level here
    loadedMap.setZoom(defaultZoom)
    loadedMap.setCenter(center)
  }, [])

  const unselectedPolygonOptions = {
    fillColor: '#2196F3', // Light blue fill color
    fillOpacity: 0.5,
    strokeColor: '#2196F3', // Light blue border color
    strokeOpacity: 0.8,
    strokeWeight: 2,
    clickable: true,
    draggable: false,
    editable: false,
    zIndex: 1,
  }

  const selectedPolygonOptions = {
    fillColor: '#FF4081',
    fillOpacity: 0.8,
    strokeColor: '#FF4081',
    strokeOpacity: 1,
    strokeWeight: 3,
    clickable: true,
    draggable: true,
    editable: true,
    zIndex: 2,
  }

  const handleNewPolygon = (polygon) => {
    onPolygonComplete(polygon)
    polygon.setMap(null)
  }

  const onPolygonLoad = (polygon, zoneId) => {
    // eslint-disable-next-line no-undef
    google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
      const updatedPaths = polygon.getPath().getArray().map((vertex) => ({
        lat: vertex.lat(),
        lng: vertex.lng(),
      }))
      updatePolygonPaths(zoneId, updatedPaths)
    })
    // eslint-disable-next-line no-undef
    google.maps.event.addListener(polygon, 'dragend', () => {
      const updatedPaths = polygon.getPath().getArray().map((vertex) => ({
        lat: vertex.lat(),
        lng: vertex.lng(),
      }))
      updatePolygonPaths(zoneId, updatedPaths)
    })
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={3}
      onLoad={onLoad}
    >
      <DrawingManager
        drawingMode={inDrawingMode && window.google && window.google.maps && window.google.maps.drawing ? window.google.maps.drawing.OverlayType.POLYGON : null}
        onPolygonComplete={handleNewPolygon}
        options={{
          drawingControl: false, // Hide drawing options toolbar
          drawingControlOptions: {
            drawingModes: [
              window.google?.maps?.drawing?.OverlayType.POLYGON,
            ],
          },
        }}
      />
      {zones.map((zone, i) => (
        <Polygon
          key={zone.id}
          paths={zone.paths}
          onLoad={(p) => onPolygonLoad(p, zone.id)}
          onClick={() => {
            onPolygonClick(zone, i)
          }}
          options={selectedZone?.id === zone.id ? selectedPolygonOptions : unselectedPolygonOptions}
        />
      ))}
    </GoogleMap>
  ) : <></>
}

// Action Types
const ADD_ZONE = 'ADD_ZONE'
const DELETE_ZONE = 'DELETE_ZONE'
const UPDATE_ZONE = 'UPDATE_ZONE'

// Reducer function
const zonesReducer = (state, action) => {
  switch (action.type) {
    case ADD_ZONE:
      return [...state, action.payload]
    case DELETE_ZONE:
      return state.filter((zone, i) => i !== action.payload)
    case UPDATE_ZONE:
      return state.map((zone, i) => (i === action.payload.i ? { ...zone, ...action.payload.updatedZone } : zone))
    default:
      return state
  }
}

function DrawZones({
  storeId, mapLocation, deliveryZones, update, close,
}) {
  const [inDrawingMode, toggleDrawingMode] = useState(false)
  const [zones, dispatchZones] = useReducer(zonesReducer, deliveryZones || [])
  const [selectedZone, setSelectedZone] = useState(null)

  const onPolygonComplete = (polygon) => {
    toggleDrawingMode(false)
    const paths = polygon.getPath().getArray().map((p) => ({ lat: p.lat(), lng: p.lng() }))
    const newZone = { id: `Zone ${zones.length + 1}`, amount: 0, paths }
    dispatchZones({ type: ADD_ZONE, payload: newZone })
  }

  const deleteZone = (i) => {
    dispatchZones({ type: DELETE_ZONE, payload: i })
  }

  const updateZoneField = (i, field, value) => {
    dispatchZones({
      type: UPDATE_ZONE,
      payload: {
        i,
        updatedZone: { [field]: value },
      },
    })
  }

  const updatePolygonPaths = (zoneId, newPaths) => {
    dispatchZones({
      type: UPDATE_ZONE,
      payload: {
        id: zoneId,
        updatedZone: { paths: newPaths },
      },
    })
  }

  const onPolygonClick = (zone, i) => {
    if (zone) {
      const inputs = document.getElementById(`zone${i}`)
      inputs.scrollIntoView('smooth')
    }
    setSelectedZone(zone)
  }

  const [updateReq, { loading }] = useMutation(UPDATE_STORE, {
    onError: () => { toast.error('Error updating delivery zones.') },
    onCompleted: (data) => {
      update(data.updateStore)
      toast.success('Delivery zones  updated successfully')
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    setSelectedZone(null)
    updateReq({
      variables: {
        id: storeId,
        deliveryFormula: 'zones',
        deliveryZones: zones.map((z) => ({ id: z.id, amount: z.amount, paths: z.paths?.map((p) => ({ lat: p.lat, lng: p.lng })) || [] })),
      },
    })
  }

  return (
    <ResponsiveModal
      title="Delivery Zones"
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions loading={loading} close={close} inDrawingMode={inDrawingMode} toggleMode={() => { toggleDrawingMode(!inDrawingMode); setSelectedZone(null) }} />}
      fullScreen
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ height: 'calc(100vh - 155px)', pt: 2, overflowY: 'auto' }}>
          <Box component="form" id="save-delivery-zones-form" onSubmit={onSave}>
            {
              zones.map((zone, i) => (
                <Box
                  id={`zone${i}`}
                  sx={{
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3,
                  }}
                >
                  <TextField type="text" size="small" required value={zone.id} onChange={(e) => updateZoneField(i, 'id', e.target.value)} label="Zone Name" variant="outlined" style={{ flex: 0.48 }} focused={selectedZone?.id === zone.id} onFocus={() => onPolygonClick(zone, i)} />
                  <TextField type="number" size="small" required value={zone.amount} onChange={(e) => updateZoneField(i, 'amount', parseInt(e.target.value, 10))} label="Delivery Fee" variant="outlined" style={{ flex: 0.48 }} />
                  <IconButton onClick={() => { deleteZone(i) }}><Delete /></IconButton>
                </Box>
              ))
            }
          </Box>
        </Box>
        <MapWithADrawingManager mapLocation={mapLocation} inDrawingMode={inDrawingMode} zones={zones} onPolygonComplete={onPolygonComplete} selectedZone={selectedZone} updatePolygonPaths={updatePolygonPaths} onPolygonClick={onPolygonClick} />
      </Box>
    </ResponsiveModal>
  )
}

export default DrawZones
