import React, { useState } from 'react'
import { Box } from '@mui/material'
import '../styles/menu.css'
import CategoriesDnD from '../components/products/CategoriesDnD'
import ProductsTabs from '../components/products/ProductsTabs'
import CustomizationsView from '../components/products/CustomizationsView'
import { CustomizationsProvider } from '../components/products/context/customizations'
import { ProductsProvider } from '../components/products/context/products'

export default function Products() {
  const [activeTab, setActiveTab] = useState('categories')
  return (
    <ProductsProvider>
      <CustomizationsProvider>
        <Box sx={{ paddingY: 2 }}>
          <ProductsTabs value={activeTab} handleChange={(event, newValue) => { setActiveTab(newValue) }} />
          <Box sx={{ paddingX: 1 }}>
            {activeTab === 'categories' ? <CategoriesDnD /> : null}
            {activeTab === 'customizations' ? <CustomizationsView /> : null}
          </Box>
        </Box>
      </CustomizationsProvider>
    </ProductsProvider>
  )
}
