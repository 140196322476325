import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import { InputLabel } from '@mui/material'

import { STORES } from '../../graphql/locations'

export default function SelectStore({
  changeHandler, value, hasAll, required, fullWidth, sx, disabled, noLabel,
}) {
  const [stores, setStores] = useState([])
  const { loading } = useQuery(STORES, {
    variables: {
      limit: 100,
      offset: 0,
    },
    onError: () => {},
    onCompleted: (data) => { setStores(data.stores) },
  })
  return (
    <FormControl fullWidth={fullWidth} sx={sx || { width: '100%', marginY: 2 }}>
      { !noLabel ? <InputLabel sx={{ mt: -0.5 }}>Select Store</InputLabel> : null }
      <Select
        required={required}
        value={value}
        onChange={changeHandler}
        type="text"
        size="small"
        label={!noLabel ? 'Select Store' : undefined}
        disabled={disabled}
      >
        {loading ? <MenuItem value="">loading stores</MenuItem> : null}
        {hasAll ? <MenuItem key="all" value="all">All Stores</MenuItem> : (
          <MenuItem value="">
            <em>Select Store</em>
          </MenuItem>
        ) }
        {stores.map((s) => (<MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>))}
      </Select>
    </FormControl>
  )
}
