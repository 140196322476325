import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { Box, IconButton } from '@mui/material'
import { useMutation } from '@apollo/client'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { Delete } from '@mui/icons-material'
import { ADD_COVER, DELETE_COVER } from '../../graphql/merchant'
import './styles/Logo.css'

function CoverImage({ url, removeImage }) {
  const handleErrors = () => {
    // do nothing
  }
  const handleResponse = () => {
    removeImage()
  }

  const [mutate, { loading }] = useMutation(DELETE_COVER, { onError: handleErrors, onCompleted: ((res) => handleResponse(res)) })
  return (
    <Box sx={{
      position: 'relative',
      width: '150px',
      height: '150px',
      ml: '10px',
      mb: '10px',
      border: '1px solid #ddd',
      borderRadius: '5px',
      opacity: loading ? 0.5 : 1,
    }}
    >
      <img src={url} alt="cover" />
      <IconButton
        size="small"
        sx={{
          position: 'absolute', top: 0, left: 2, color: 'red',
        }}
        onClick={() => { mutate({ variables: { imageLink: url } }) }}
      >
        <Delete />
      </IconButton>
    </Box>
  )
}

export default function CoverPhoto({ merchant }) {
  const [coverUrls, setCoverUrls] = useState(merchant.coverPhotos)
  const handleErrors = () => {
    // error updating details
    toast.error('error updating cover photo')
  }

  const handleResponse = (data) => {
    // details updated
    setCoverUrls([...coverUrls, data.addCoverPhoto])
    toast.success('cover photo updated')
  }
  const [addCoverReq, { loading }] = useMutation(ADD_COVER, { onError: handleErrors, onCompleted: handleResponse })
  const handleImageUpload = (e) => {
    const image = e.target.files[0]
    if (image) {
      addCoverReq({
        variables: {
          image,
        },
      })
    }
  }
  const removeImage = (url) => {
    setCoverUrls(coverUrls.filter((u) => (u !== url)))
  }
  return (
    <div className="business-logo">
      <h3>Business Cover Photos</h3>
      <Box
        sx={{
          position: 'relative', display: 'flex', flexWrap: 'wrap', p: '10px',
        }}
        className="covers"
      >
        { coverUrls.map((u) => (<CoverImage key={u} url={u} removeImage={() => removeImage(u)} />)) }
      </Box>
      <div className="upload-btn-wrapper">
        <button type="button" disabled={loading || coverUrls.length >= 6} className="upload-btn upload-btn-row">
          <h5>{loading ? 'Uploading...' : 'Add Image'}</h5>
          <AddBoxIcon style={{ fontSize: '20px', color: ' #808080' }} />
        </button>
        <input type="file" name="file" accept="images/*" required disabled={loading || coverUrls.length >= 6} onChange={handleImageUpload} />
      </div>
    </div>
  )
}
