/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState } from 'react'
import {
  GoogleMap, useJsApiLoader, MarkerF,
} from '@react-google-maps/api'

export default function DeliveryMap({ deliveryLocation }) {
  if (!deliveryLocation) return null
  const initialPos = { lat: deliveryLocation.lat, lng: deliveryLocation.lng }
  const [markerPos] = useState(initialPos)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAXEkv3ohotrk5e_9rNXxhXZ9RkOjZk9pY',
  })

  const containerStyle = {
    width: '100%',
    height: '100vh',
  }

  const onLoad = useCallback((map) => {
    const defaultZoom = 16
    map.setZoom(defaultZoom)
    map.setCenter(initialPos)
  }, [])

  return isLoaded ? (
    <GoogleMap
      onLoad={onLoad}
      mapContainerStyle={containerStyle}
      center={initialPos}
      options={{
        disableDefaultUI: true,
      }}
    >
      <MarkerF position={markerPos} />
    </GoogleMap>
  ) : <div>Loading map...</div>
}
