/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
import { Chip } from '@mui/material'
import './styles/TransactionsView.css'
import { formatDate, formatMoney } from '../../utils/formatter'
import PaymentModal from './PaymentModal'
import SettlementModal from './SettlementModal'

function Transaction({ transaction }) {
  const {
    transactionId, transactionType, grossAmount, netAmount, totalCharges, transactionStatus, postedOn,
  } = transaction
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      {transactionType === 'payment' || transactionType === 'reversal' ? <PaymentModal visible={modalOpen} close={() => setModalOpen(false)} transaction={transaction} /> : null}
      {transactionType === 'settlement' ? <SettlementModal visible={modalOpen} close={() => setModalOpen(false)} transaction={transaction} /> : null}
      <tr className="transaction">
        <td className="transid" onClick={() => setModalOpen(true)}>
          <h4><u>{transactionId}</u></h4>
        </td>
        <td className="type">
          <Chip label={transactionType} />
        </td>
        <td className="date">
          <p>{formatDate(postedOn)}</p>
        </td>
        <td className="status">
          <span className={transactionStatus}>{transactionStatus}</span>
        </td>
        <td className="amount">{formatMoney(grossAmount)}</td>
        <td className="amount">{formatMoney(totalCharges)}</td>
        <td className="amount">{formatMoney(netAmount)}</td>
      </tr>
    </>
  )
}

export default function TransactionsView({ visible, transactions }) {
  if (!visible) {
    return null
  }
  return (
    <div className="table-container">
      <table className="transactions-table">
        <thead>
          <tr>
            <th className="transid-hd">Id</th>
            <th className="type-hd">Type</th>
            <th className="date-hd">Created On</th>
            <th className="status-hd">Status</th>
            <th className="amount-hd">Gross Amount</th>
            <th className="amount-hd">Total Charges</th>
            <th className="amount-hd">Net Amount</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((trans) => <Transaction key={trans.transactionId} transaction={trans} />)}
        </tbody>
      </table>
    </div>
  )
}
