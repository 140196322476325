// ChartComponent.js
import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'
import { Box } from '@mui/material'

const OrderTypePieChart = ({ data }) => {
  const chartRef = useRef(null)

  useEffect(() => {
    if (!chartRef.current || !data) {
      return // Skip if the chart or data is not ready
    }

    const {
      deliveryOrders, pickupOrders, dineinOrders, takeawayOrders,
    } = data

    const ctx = chartRef.current.getContext('2d')

    // Update the existing chart or create a new one
    if (chartRef.current.chart) {
      // If the chart already exists, update its data
      chartRef.current.chart.data.datasets[0].data = [deliveryOrders, pickupOrders, dineinOrders, takeawayOrders]
      chartRef.current.chart.update()
    } else {
      // If the chart doesn't exist, create a new one
      const myChart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: ['Delivery', 'Pickup', 'Dinein', 'Takeaway'],
          datasets: [{
            label: 'Order Types',
            data: [deliveryOrders, pickupOrders, dineinOrders, takeawayOrders],
            backgroundColor: [
              '#8ad09d', // Greenish
              '#ffcf56', // Yellowish
              '#66c5cc', // Bluish
              '#ff6d70', // Reddish
            ],
            hoverOffset: 4,
          }],
        },
        options: {
          elements: {
            arc: {
              borderWidth: 0, // Set borderWidth to 0 to make it a pie chart
            },
          },
          radius: 140, // Set the desired radius here
        },
      })

      chartRef.current.chart = myChart
    }
  }, [data])

  return (
    <Box sx={{
      width: '100%', height: '340px', position: 'relative',
    }}
    >
      <canvas ref={chartRef} width="100" height="100px" style={{ maxHeight: '340px' }} />
    </Box>
  )
}

export default OrderTypePieChart
