/* eslint-disable react/jsx-props-no-spreading */
import { React, useReducer, useState } from 'react'
import {
  Box, Button, Checkbox, DialogActions, FormControl, FormControlLabel, IconButton, InputAdornment, TextField, Tooltip,
} from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import SaveIcon from '@mui/icons-material/Save'
import toast from 'react-hot-toast'
import { isMoment } from 'moment'
import { useMutation } from '@apollo/client'
import { Autorenew } from '@mui/icons-material'
import ResponsiveBigModal from '../layout/ResponsiveBigModal'
import { CREATE_COUPON, UPDATE_COUPON } from '../../graphql/coupons'
import SelectStore from '../locations/SelectStore'
import DayAvailability from '../shared/DayAvailability'

const defaultTimings = {
  sunday: { status: true, from: '00:00', to: '00:00' },
  monday: { status: true, from: '00:00', to: '00:00' },
  tuesday: { status: true, from: '00:00', to: '00:00' },
  wednesday: { status: true, from: '00:00', to: '00:00' },
  thursday: { status: true, from: '00:00', to: '00:00' },
  friday: { status: true, from: '00:00', to: '00:00' },
  saturday: { status: true, from: '00:00', to: '00:00' },
}

function generateCouponCode(length) {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const codeLength = length || 8
  let couponCode = ''

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < codeLength; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length)
    couponCode += charset.charAt(randomIndex)
  }

  return couponCode
}

function Actions({ onSave, close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        onClick={onSave}
        disabled={loading}
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mr: 2 }}
      >
        {loading ? 'Saving...' : 'Save Coupon'}
      </Button>
    </DialogActions>
  )
}

const timingsReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ITEM':
      return {
        ...state, // Copy the existing state
        [action.day]: { // Update the specific day
          ...state[action.day], // Copy the existing day's properties
          ...action.newValue, // Update with new values
        },
      }
    default:
      return state
  }
}

export default function AddCouponModal({
  close, coupon, addNewCoupon, updateCoupon,
}) {
  const initalStartDate = coupon ? new Date(coupon.startDate).toLocaleString() : Date.now()
  const initialEndDate = coupon ? new Date(coupon.endDate).toLocaleString() : Date.now()
  const [title, setTitle] = useState(coupon?.title || '')
  const [code, setCode] = useState(coupon?.code || '')
  const [startDate, setStartDate] = useState(initalStartDate)
  const [endDate, setEndDate] = useState(initialEndDate)
  const [discountType, setDiscountType] = useState(coupon?.discountType || 'fixed')
  const [discountAmount, setDiscountAmount] = useState(coupon?.discountAmount || '')
  const [limitPerCustomer, setLimitPerCustomer] = useState(coupon?.limitPerCustomer || 'one-time')
  const [maxUsageLimit, setmaxUsageLimit] = useState(coupon?.maxUsageLimit || 100)
  const [targetCustomers, setTargetCustomers] = useState(coupon?.targetCustomers || 'all')
  const [store, setStore] = useState(coupon?.store?.id || 'all')
  const [minOrderAmount, setMinOrderAmount] = useState(coupon?.minOrderAmount || 0)
  const [maxAmount, setMaxDiscountAmount] = useState(coupon?.maxAmount || 'all')
  const initialTimings = coupon?.timings || defaultTimings
  const timingsArr = Object.keys(initialTimings).filter((d) => d !== '__typename')
  const formattedTimings = {}
  timingsArr.forEach((d) => {
    const { status, from, to } = initialTimings[d]
    formattedTimings[d] = { status, from, to }
  })
  const [timings, dispatch] = useReducer(timingsReducer, formattedTimings)
  const [visible, setVisible] = useState(coupon ? coupon.visible : true)

  const updateTiming = (day, newValue) => {
    dispatch({ type: 'UPDATE_ITEM', day, newValue })
  }

  const [createCouponReq, { loading }] = useMutation(CREATE_COUPON, {
    onError: () => { toast.error('Error creating discount coupon.') },
    onCompleted: (data) => {
      toast.success('Coupon created successfully')
      addNewCoupon(data.createDiscountCoupon)
      close()
    },
  })

  const [updateCouponReq, { loading: updating }] = useMutation(UPDATE_COUPON, {
    onError: () => { toast.error('Error updating discount coupon.') },
    onCompleted: (data) => {
      toast.success(`Coupon ${title} updated successfully`)
      updateCoupon(data.updateDiscountCoupon)
      close()
    },
  })

  const onSave = () => {
    const start = new Date(isMoment(startDate) ? startDate.toDate() : startDate)
    const end = new Date(isMoment(endDate) ? endDate.toDate() : endDate)
    start.setHours(0); end.setHours(0)
    start.setMinutes(0); end.setMinutes(0)
    start.setSeconds(0); end.setSeconds(0)
    start.setMilliseconds(0); end.setMilliseconds(0)
    if (coupon) {
      updateCouponReq({
        variables: {
          id: coupon.id,
          title,
          code,
          startDate: start.toDateString(),
          endDate: end.toDateString(),
          discountType,
          discountAmount: parseInt(discountAmount, 10),
          limitPerCustomer,
          maxUsageLimit: parseInt(maxUsageLimit, 10),
          targetCustomers,
          minOrderAmount: parseInt(minOrderAmount, 10),
          maxAmount: parseInt(maxAmount, 10),
          storeId: store !== 'all' ? store : null,
          timings,
          visible,
        },
      })
    } else {
      createCouponReq({
        variables: {
          title,
          code,
          startDate: start.toDateString(),
          endDate: end.toDateString(),
          discountType,
          discountAmount: parseInt(discountAmount, 10),
          limitPerCustomer,
          maxUsageLimit: parseInt(maxUsageLimit, 10),
          targetCustomers,
          minOrderAmount: parseInt(minOrderAmount, 10),
          maxAmount: parseInt(maxAmount, 10),
          storeId: store !== 'all' ? store : null,
          timings,
          visible,
        },
      })
    }
  }

  return (
    <ResponsiveBigModal
      title={coupon ? 'Edit Coupon' : 'Create Coupon'}
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions onSave={onSave} loading={loading || updating} close={close} />}
    >
      <Box component="form">
        <Box sx={{
          display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', mb: 3,
        }}
        >
          <TextField type="text" size="small" required value={title} onChange={(e) => { setTitle(e.target.value) }} label="Title" variant="outlined" style={{ flex: 0.48 }} />
          <TextField
            type="text"
            size="small"
            required
            value={code}
            onChange={(e) => { setCode(e.target.value?.toLocaleUpperCase()) }}
            label="Code"
            variant="outlined"
            style={{ flex: 0.48 }}
            InputProps={{
              readOnly: false,
              endAdornment:
  <InputAdornment position="end">
    <Tooltip title="Auto Generate Code" arrow>
      <IconButton
        size="small"
        onClick={() => { setCode(generateCouponCode(8)) }}
      >
        <Autorenew fontSize="10" />
      </IconButton>
    </Tooltip>
  </InputAdornment>,
            }}
          />
        </Box>
        <Box sx={{
          display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', mb: 3,
        }}
        >
          <DesktopDatePicker
            label="Start Date"
            inputFormat="MM/DD/YYYY"
            value={startDate}
            onChange={(d) => { setStartDate(d) }}
            renderInput={(params) => <TextField size="small" {...params} style={{ flex: 0.48 }} />}
          />
          <DesktopDatePicker
            label="End Date"
            inputFormat="MM/DD/YYYY"
            value={endDate}
            onChange={(d) => { setEndDate(d) }}
            renderInput={(params) => <TextField size="small" {...params} style={{ flex: 0.48 }} />}
          />
        </Box>
        <Box sx={{
          display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', mb: 3,
        }}
        >
          <FormControl style={{ flex: 0.48 }}>
            <InputLabel>Discount Type</InputLabel>
            <Select
              value={discountType}
              label="Discount Type"
              onChange={(e) => { setDiscountType(e.target.value) }}
              type="text"
              size="small"
            >
              <MenuItem value="fixed">Fixed Discount</MenuItem>
              <MenuItem value="percentage">Percentage Discount</MenuItem>
            </Select>
          </FormControl>
          <TextField type="number" size="small" required value={discountAmount} onChange={(e) => { setDiscountAmount(e.target.value) }} label={discountType === 'fixed' ? 'Discount Amount' : '% Discount'} variant="outlined" style={{ flex: 0.48 }} />
        </Box>
        <Box sx={{
          display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', mb: 3,
        }}
        >
          <TextField type="number" size="small" required value={minOrderAmount} onChange={(e) => { setMinOrderAmount(e.target.value) }} label="Minimum Order Amount" variant="outlined" style={{ flex: 0.48 }} />
          <TextField type="number" size="small" required value={maxAmount} onChange={(e) => { setMaxDiscountAmount(e.target.value) }} label="Maximum Discount Amount" variant="outlined" style={{ flex: 0.48 }} />
        </Box>
        <Box sx={{
          display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', mb: 3,
        }}
        >
          <FormControl style={{ flex: 0.48 }}>
            <InputLabel>Usage Limit per Customer</InputLabel>
            <Select
              value={limitPerCustomer}
              label="Usage Limit per Customer"
              onChange={(e) => { setLimitPerCustomer(e.target.value) }}
              type="text"
              size="small"
            >
              <MenuItem value="one-time">One Time</MenuItem>
              <MenuItem value="no-limit">Without Limit</MenuItem>
            </Select>
          </FormControl>
          <TextField type="number" size="small" required value={maxUsageLimit} onChange={(e) => { setmaxUsageLimit(e.target.value) }} label="Maximum Number of Coupons" variant="outlined" style={{ flex: 0.48 }} />
        </Box>
        <Box sx={{
          display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', mb: 3,
        }}
        >
          <FormControl style={{ flex: 0.48 }}>
            <InputLabel>Target Customers</InputLabel>
            <Select
              value={targetCustomers}
              label="Target Customers"
              onChange={(e) => { setTargetCustomers(e.target.value) }}
              type="text"
              size="small"
            >
              <MenuItem value="new">New Customers</MenuItem>
              <MenuItem value="all">All Customers</MenuItem>
            </Select>
          </FormControl>
          <Box style={{ flex: 0.48 }}>
            <SelectStore hasAll value={store} changeHandler={(e) => { setStore(e.target.value) }} />
          </Box>
        </Box>
        <Box sx={{
          display: 'flex', justifyContent: 'space-evenly', alignItems: 'start', mb: 3,
        }}
        >
          <Box sx={{
            flex: 0.48, maxWidth: '48%',
          }}
          >
            <DayAvailability title="Coupon Availability" timings={timings} handleChange={updateTiming} />
          </Box>
          <Box sx={{ flex: 0.48 }}>
            <FormControlLabel control={<Checkbox checked={visible} onChange={(e) => (setVisible(e.target.checked))} />} label="Show on Order Page" />
          </Box>
        </Box>
      </Box>
    </ResponsiveBigModal>
  )
}
