import { gql } from '@apollo/client'

export const COUPONS = gql`
query GetCoupons($limit: Int!, $offset: Int!, $code: String) {
  discountCoupons(limit: $limit, offset: $offset, code: $code) {
    id
    title
    code
    startDate
    endDate
    discountType
    discountAmount
    limitPerCustomer
    maxUsageLimit
    usageCount
    minOrderAmount
    maxAmount
    targetCustomers
    store {
      id
      name
    }
    timings {
      sunday { status from to }
      monday { status from to }
      tuesday { status from to }
      wednesday { status from to }
      thursday { status from to }
      friday { status from to }
      saturday { status from to }
    }
    visible
    createdOn
  }
}
`

export const CREATE_COUPON = gql`
mutation CreateCoupon(
  $title: String!
  $code: String!
  $startDate: String!
  $endDate: String!
  $discountType: String!
  $discountAmount: Int!
  $limitPerCustomer: String!
  $maxUsageLimit: Int!
  $minOrderAmount: Float!
  $maxAmount: Float!
  $targetCustomers: String!
  $storeId: ID
  $timings: TimingsInput!
  $visible: Boolean!
) {
  createDiscountCoupon(
    title: $title
    code: $code
    startDate: $startDate
    endDate: $endDate
    discountType: $discountType
    discountAmount: $discountAmount
    limitPerCustomer: $limitPerCustomer
    maxUsageLimit: $maxUsageLimit
    minOrderAmount: $minOrderAmount
    maxAmount: $maxAmount
    targetCustomers: $targetCustomers
    storeId: $storeId
    timings: $timings
    visible: $visible
  ) {
    id
    title
    code
    startDate
    endDate
    discountType
    discountAmount
    limitPerCustomer
    maxUsageLimit
    usageCount
    minOrderAmount
    maxAmount
    targetCustomers
    store {
      id
      name
    }
    timings {
      sunday { status from to }
      monday { status from to }
      tuesday { status from to }
      wednesday { status from to }
      thursday { status from to }
      friday { status from to }
      saturday { status from to }
    }
    visible
    createdOn
  }
}
`

export const UPDATE_COUPON = gql`
mutation UpdateCoupon(
  $id: ID!
  $title: String!
  $code: String!
  $startDate: String!
  $endDate: String!
  $discountType: String!
  $discountAmount: Int!
  $limitPerCustomer: String!
  $maxUsageLimit: Int!
  $minOrderAmount: Int!
  $maxAmount: Float!
  $targetCustomers: String!
  $storeId: ID
  $timings: TimingsInput!
  $visible: Boolean!
) {
  updateDiscountCoupon(
    id: $id
    title: $title
    code: $code
    startDate: $startDate
    endDate: $endDate
    discountType: $discountType
    discountAmount: $discountAmount
    limitPerCustomer: $limitPerCustomer
    maxUsageLimit: $maxUsageLimit
    minOrderAmount: $minOrderAmount
    maxAmount: $maxAmount
    targetCustomers: $targetCustomers
    storeId: $storeId
    timings: $timings
    visible: $visible
  ) {
    id
    title
    code
    startDate
    endDate
    discountType
    discountAmount
    limitPerCustomer
    maxUsageLimit
    usageCount
    minOrderAmount
    maxAmount
    targetCustomers
    store {
      id
      name
    }
    timings {
      sunday { status from to }
      monday { status from to }
      tuesday { status from to }
      wednesday { status from to }
      thursday { status from to }
      friday { status from to }
      saturday { status from to }
    }
    visible
    createdOn
  }
}
`

export const DELETE_COUPON = gql`
mutation DeleteCoupon(
    $id: ID!
  ) {
    deleteDiscountCoupon(
      id: $id
    ) {
      id
      title
      code
      startDate
      endDate
      discountType
      discountAmount
      limitPerCustomer
      maxUsageLimit
      usageCount
      createdOn
    }
  }
`
