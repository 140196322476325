import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { connect } from 'react-redux'
import ErrorLoading from '../../components/layout/ErrorLoading'
import CenterLoader from '../../components/spinners/CenterLoader'
import Welcome from '../../components/home/Welcome'
import { ANALYTICS } from '../../graphql/merchant'
import Analytics from '../../components/home/OrderAnalytics'

function Home() {
  const [analytics, setAnalytics] = useState(null)
  const [period, setPeriod] = useState(7)
  const [error, setError] = useState(null)
  const handleResponse = (data) => {
    setError(null)
    if (data.analytics) {
      setAnalytics(data.analytics)
    }
  }
  const [getAnalytics, { loading }] = useLazyQuery(ANALYTICS, {
    variables: {
      period,
    },
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  useEffect(() => {
    getAnalytics()
  }, [period])
  return (
    <div className="page-content">
      <Welcome />
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getAnalytics} />
      {!loading && !error && analytics ? (<Analytics analytics={analytics} period={period} changePeriod={(p) => { setPeriod(p) }} />) : null}
    </div>
  )
}
const mapStateToProps = (state) => ({
  merchantInfo: state.merchant.merchantInfo,
})

export default connect(mapStateToProps, {})(Home)
