export default function SmallLoader({ visible, color }) {
  if (!visible) {
    return null
  }
  return (
    <div className="center-loader">
      <div className="loadingio-spinner-rolling-g5d57ih12s">
        <style>
          {
        `@keyframes ldio-ywusr850rmf {
          0% { transform: translate(-50%,-50%) rotate(0deg); }
          100% { transform: translate(-50%,-50%) rotate(360deg); }
        }
        .center-loader{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .ldio-ywusr850rmf div {
          position: absolute;
          width: 60px;
          height: 60px;
          border: 10px solid ${color || '#0a82f3'};
          border-top-color: transparent;
          border-radius: 50%;
        }
        .ldio-ywusr850rmf div {
          animation: ldio-ywusr850rmf 0.6896551724137931s linear infinite;
          top: 50px;
          left: 50px
        }
        .loadingio-spinner-rolling-g5d57ih12s {
          width: 40px;
          height: 40px;
          display: inline-block;
          overflow: hidden;
          background: none;
        }
        .ldio-ywusr850rmf {
          width: 100%;
          height: 100%;
          position: relative;
          transform: translateZ(0) scale(0.4);
          backface-visibility: hidden;
          transform-origin: 0 0; /* see note above */
        }
        .ldio-ywusr850rmf div { box-sizing: content-box; }
        /* generated by https://loading.io/ */
        
        `
        }
        </style>
        <div className="ldio-ywusr850rmf">
          <div />
        </div>
      </div>
    </div>
  )
}
