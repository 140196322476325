import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import './styles/Pagination.css'

export default function Pagination({
  visible, page, next, prev,
}) {
  if (!visible) {
    return null
  }
  return (
    <div className="pagination-cont">
      <div className="pagination">
        <button type="button" className="pagination-btn" onClick={prev}>
          <ArrowBackIosIcon style={{ color: 'darkgray', fontSize: '24' }} />
          Prev
        </button>
        <div className="current-page">
          {`Page ${page}`}
        </div>
        <button type="button" className="pagination-btn" onClick={next}>
          Next
          <ArrowForwardIosIcon style={{ color: 'darkgray', fontSize: '24' }} />
        </button>
      </div>
    </div>
  )
}
