// ChartComponent.js
import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'
import { Box } from '@mui/material'

const CustomerDoughnut = ({ data }) => {
  const chartRef = useRef(null)

  const { newCustomers, repeatCustomers } = data

  useEffect(() => {
    if (!chartRef.current || !data) {
      return // Skip if the chart or data is not ready
    }

    const ctx = chartRef.current.getContext('2d')

    // Update the existing chart or create a new one
    if (chartRef.current.chart) {
      // If the chart already exists, update its data
      chartRef.current.chart.data.datasets[0].data = [newCustomers, repeatCustomers]
      chartRef.current.chart.update()
    } else {
      // If the chart doesn't exist, create a new one
      const myChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ['New', 'Repeat'],
          datasets: [{
            label: 'Customers',
            data: [newCustomers, repeatCustomers],
            backgroundColor: [
              '#8ad09d', // Greenish
              '#ffcf56', // Yellowish
            ],
            hoverOffset: 4,
          }],
        },
        options: {
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
          radius: 140,
        },
      })

      chartRef.current.chart = myChart
    }
  }, [data])

  return (
    <Box sx={{
      width: '100%', height: '340px', position: 'relative',
    }}
    >
      <canvas ref={chartRef} width="100" height="100px" style={{ maxHeight: '340px' }} />
    </Box>
  )
}

export default CustomerDoughnut
