import React from 'react'
import { Skeleton, Box } from '@mui/material'

const LoadingSkeleton = () => (
  <Box sx={{
    width: '100%',
    minHeight: 'calc(100vh - 300px)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  }}
  >
    <Box sx={{
      width: '100%', flex: 0.3, display: 'flex', flexDirection: 'column',
    }}
    >
      <Skeleton sx={{ flex: 1 }} variant="rectangular" width="100%" animation="wave" />
    </Box>
    <Box sx={{
      width: '100%', flex: 0.3, display: 'flex', flexDirection: 'column',
    }}
    >
      <Skeleton sx={{ flex: 1 }} variant="rectangular" width="100%" animation="wave" />
    </Box>
    <Box sx={{
      width: '100%', flex: 0.3, display: 'flex', flexDirection: 'column',
    }}
    >
      <Skeleton sx={{ flex: 1 }} variant="rectangular" width="100%" animation="wave" />
    </Box>
  </Box>
)

export default LoadingSkeleton
