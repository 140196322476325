import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Box, Button } from '@mui/material'
import ErrorLoading from '../../components/layout/ErrorLoading'
import CenterLoader from '../../components/spinners/CenterLoader'
import '../../styles/stores.css'
import Pagination from '../../components/layout/Pagination'
import { Room } from '../../components/locations/QrControlsDialog'
import SaveRoomDialog from '../../components/locations/SaveRoom'
import { GET_MERCHANT_ROOMS } from '../../graphql/merchant'

export default function FloorMap() {
  const [rooms, setRooms] = useState([])
  const limit = 10
  const [page, setPage] = useState(1)
  const [error, setError] = useState(null)
  const start = page === 1 ? 0 : (page - 1) * limit
  const [addOpen, toggleAdd] = useState(false)

  const handleResponse = (data) => {
    setError(null)
    setRooms(data?.merchant.rooms)
  }
  const [getRooms, { loading }] = useLazyQuery(GET_MERCHANT_ROOMS, {
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  const getRoomsReq = () => {
    getRooms({
      variables: {
        id: 13,
        offset: start,
      },
    })
  }
  const deleteRoom = (id) => {
    setRooms(rooms.filter((r) => (r.id !== id)))
  }
  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextPage = () => {
    if (!(rooms.length < limit)) {
      setPage(page + 1)
    }
  }
  useEffect(() => {
    getRoomsReq()
  }, [page])
  return (
    <div className="page-content">
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1,
      }}
      >
        <div className="page-tittle no-margin">Mall Rooms & Tables</div>
        <Button variant="contained" onClick={() => { toggleAdd(true) }}>Add Room</Button>
      </Box>
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getRooms} />
      {addOpen ? (
        <SaveRoomDialog
          addRoom={(room) => { setRooms([...rooms, room]) }}
          close={() => { toggleAdd(false) }}
        />
      ) : null}
      {!loading && !error && rooms ? (
        <Box>
          {rooms?.map((r) => <Room room={r} deleteRoom={deleteRoom} />)}
        </Box>
      ) : null}
      <Pagination visible={!loading && !error && (rooms ? rooms.length : false)} page={page} next={nextPage} prev={prevPage} />
    </div>
  )
}
