// ChartComponent.js
import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'
import { Box } from '@mui/material'
import 'chartjs-adapter-moment'
import moment from 'moment'

const OrderTypeLineChart = ({ data }) => {
  const chartRef = useRef(null)
  const overlayRef = useRef(null)

  useEffect(() => {
    if (!chartRef.current || !data) {
      return // Skip if the chart or data is not ready
    }

    const ctx = chartRef.current.getContext('2d')

    if (data.length === 0) {
      // Handle empty data scenario, e.g., display a message or hide the chart
      // For now, let's clear the chart and display a message
      if (chartRef.current.chart) {
        chartRef.current.chart.destroy()
        chartRef.current.chart = null
      }

      // Show the overlay
      overlayRef.current.style.display = 'block'

      return
    }

    // Extracting data for orders and revenue
    const orderedData = data.reduce((acc, { date, count, revenue }) => {
      const formattedDate = moment(new Date(parseInt(date, 10))).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      acc.labels.push(formattedDate)
      acc.counts.push(count)
      acc.revenue.push(revenue)
      return acc
    }, { labels: [], counts: [], revenue: [] })

    // Extracting labels (dates) and counts for the chart
    // const labels = Object.keys(orderedData)
    // const counts = labels.map((date) => orderedData[date])

    // Update the existing chart or create a new one
    if (chartRef.current.chart) {
      // If the chart already exists, update its data
      chartRef.current.chart.data.labels = orderedData.labels
      chartRef.current.chart.data.datasets[0].data = orderedData.counts
      chartRef.current.chart.data.datasets[1].data = orderedData.revenue
      chartRef.current.chart.update()
    } else {
      // If the chart doesn't exist, create a new one
      const myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: orderedData.labels,
          datasets: [{
            label: 'Order Count',
            data: orderedData.counts,
            borderColor: '#4fc3f7', // Blue color for order count
            fill: false,
            yAxisID: 'count',
          }, {
            label: 'Revenue',
            data: orderedData.revenue,
            borderColor: '#ff9800', // Red color for revenue
            fill: false,
            yAxisID: 'revenue',
          }],
        },
        options: {
          scales: {
            count: {
              type: 'linear', // Use linear scale for order count
              position: 'left', // Position of the y-axis
              beginAtZero: true,
            },
            revenue: {
              type: 'linear', // Use linear scale for revenue
              position: 'right', // Position of the y-axis
              beginAtZero: true,
              grid: {
                drawOnChartArea: false, // Don't draw gridlines on the chart area
              },
            },
            x: {
              type: 'time',
              time: {
                unit: 'day',
                parser: 'YYYY-MM-DD', // Date format
              },
            },
          },
          y: {
            beginAtZero: true,
          },
        },
      })

      chartRef.current.chart = myChart
    }
  }, [data])

  return (
    <Box sx={{
      width: '100%', height: '340px', position: 'relative',
    }}
    >
      <canvas ref={chartRef} width="100" height="100px" style={{ maxHeight: '340px' }} />
      <div
        ref={overlayRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: data.length === 0 ? 'block' : 'none',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          textAlign: 'center',
          fontSize: '20px',
          lineHeight: '340px', // Adjust to center vertically
        }}
      >
        No data available
      </div>
    </Box>
  )
}

export default OrderTypeLineChart
