/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import './styles/OverviewInput.css'

export default function OverviewInput({
  type, label, changeHandler, value, placeholder, required, readOnly,
}) {
  return (
    <div className="overview-input">
      <label>{label}</label>
      <input type={type} readOnly={readOnly} required={!!required} placeholder={placeholder} value={value} onChange={changeHandler} />
    </div>
  )
}
