import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import './styles/ManagersView.css'
import { IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { REMOVE_USER } from '../../graphql/users'
import DeleteDialog from '../shared/DeleteDialog'
import { formatGraphqlError } from '../../utils/formatter'
import SaveUser from './SaveUserDialog'

function User({ user: initialUser, removeUser }) {
  const [user, setUser] = useState(initialUser)
  const {
    id, role, name, email, phone,
  } = user
  // const status = isVerified ? 'active' : 'pending'
  const [editOpen, toggleEdit] = useState(false)
  const [deleteOpen, toggleDelete] = useState(false)

  const handleErrors = (e) => {
    toast.error(formatGraphqlError(e, 'error removing user'))
  }

  const handleResponse = () => {
    toast.success('user removed')
    removeUser(id)
    toggleDelete(false)
  }

  const [removeUserReq, { loading: deleting }] = useMutation(REMOVE_USER, { onError: handleErrors, onCompleted: handleResponse, variables: { email } })

  return (
    <tr className="user">
      <DeleteDialog title="Remove User" msg={`Are you sure you want to remove user ${name}`} loading={deleting} visible={deleteOpen} close={() => { toggleDelete(false) }} onDelete={removeUserReq} />
      {editOpen ? <SaveUser user={user} close={() => { toggleEdit(false) }} update={(u) => { setUser(u) }} /> : null }
      <td className="name">
        <p>{name}</p>
      </td>
      <td className="email">
        <p>{email}</p>
      </td>
      <td className="role">
        <p>{role}</p>
      </td>
      <td className="status">
        {/* <Chip label={status} sx={{ backgroundColor: status === 'active' ? 'green' : '#0a82f3', my: 1 }} /> */}
        <p>{phone || '--'}</p>
      </td>
      <td className="actions">
        <IconButton sx={{ mr: 2 }} onClick={() => toggleEdit(true)}>
          <Edit />
        </IconButton>
        <IconButton sx={{ color: 'red' }} onClick={() => toggleDelete(true)}>
          <Delete />
        </IconButton>
      </td>
    </tr>
  )
}

export default function UsersView({ users, removeUser }) {
  return (
    <div className="table-container">
      <table className="users-table">
        <thead>
          <tr>
            <th className="name-hd">Name</th>
            <th className="email-hd">Email</th>
            <th className="role-hd">Role</th>
            <th className="status-hd">Phone</th>
            <th className="actions-hd">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u) => <User key={u.id} user={u} removeUser={removeUser} />)}
        </tbody>
      </table>
    </div>

  )
}
