import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Button, Box } from '@mui/material'
import AddBoxIcon from '@mui/icons-material/AddBox'
import ErrorLoading from '../components/layout/ErrorLoading'
import CenterLoader from '../components/spinners/CenterLoader'
import '../styles/stores.css'
import { STORES } from '../graphql/locations'
import Pagination from '../components/layout/Pagination'
import FilterForm from '../components/layout/FilterForm'
import FilterName from '../components/inputs/FilterName'

import LocationsView from '../components/locations/LocationsView'
import SaveStoreDialog from '../components/locations/SaveStore'

export default function BusinessStores() {
  const [locations, setLocations] = useState(null)
  const limit = 10
  const [page, setPage] = useState(1)
  const [error, setError] = useState(null)
  const start = page === 1 ? 0 : (page - 1) * limit
  const [name, setName] = useState('')
  const [nameSearch, setNameSearch] = useState('')
  const [phone, setPhone] = useState('')
  const [phoneSearch, setPhoneSearch] = useState('')
  const [addOpen, toggleAdd] = useState(false)

  const handleResponse = (data) => {
    setError(null)
    setLocations(data.stores)
  }
  const [getLocations, { loading }] = useLazyQuery(STORES, {
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  const getLocationsReq = () => {
    getLocations({
      variables: {
        limit,
        offset: start,
        name,
        phone,
      },
    })
  }
  const clearNameSearch = () => {
    setName('')
    setNameSearch('')
  }
  const handleNameSearch = (e) => {
    setName(e.target.value)
    if (e.target.value?.length >= 4) {
      setNameSearch(e.target.value)
    }
    if (!e.target.value) {
      clearNameSearch()
    }
  }
  const clearPhoneSearch = () => {
    setPhone('')
    setPhoneSearch('')
  }
  const handlePhoneSearch = (e) => {
    setPhone(e.target.value)
    if (e.target.value?.length >= 8) {
      setPhoneSearch(e.target.value)
    }
    if (!e.target.value) {
      clearPhoneSearch()
    }
  }
  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextPage = () => {
    if (!(locations.length < limit)) {
      setPage(page + 1)
    }
  }
  const handleFiltering = (e) => {
    e.preventDefault()
    if (page === 1) {
      getLocationsReq()
    }
    setPage(1)
  }
  useEffect(() => {
    getLocationsReq()
  }, [page, nameSearch, phoneSearch])
  return (
    <div className="page-content">
      { addOpen ? <SaveStoreDialog close={() => (toggleAdd(false))} add={(s) => { setLocations([s, ...locations]) }} /> : null }
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
      }}
      >
        <div className="page-tittle no-margin">Stores</div>
        <Button
          variant="contained"
          endIcon={<AddBoxIcon style={{ color: '#fff', fontSize: '24', marginRight: '5' }} />}
          onClick={() => { toggleAdd(true) }}
        >
          Add Store
        </Button>
      </Box>
      <FilterForm submitHandler={handleFiltering}>
        <FilterName name="Name" value={name} clear={clearNameSearch} label="Search Name:" type="text" placeholder="name" changeHandler={handleNameSearch} />
        <FilterName name="Phone" value={phone} clear={clearPhoneSearch} label="Search Phone:" type="text" placeholder="phone" changeHandler={handlePhoneSearch} />
      </FilterForm>
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getLocations} />
      <LocationsView visible={!loading && !error && locations} locations={locations} deleteLocation={(id) => setLocations(locations.filter((el) => el.id !== id))} />
      <Pagination visible={!loading && !error && (locations ? locations.length : false)} page={page} next={nextPage} prev={prevPage} />
    </div>
  )
}
