import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import {
  Box, Button, DialogActions, IconButton, InputAdornment, TextField,
} from '@mui/material'
import {
  AddBox, Delete, LocationOn, Save,
} from '@mui/icons-material'
import ResponsiveModal from '../layout/ResponsiveModal'
import { ADD_STORE, UPDATE_STORE } from '../../graphql/locations'
import SetAddressDialog from '../maps/SetAddressDialog'

function Actions({ close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<Save />}
        variant="contained"
        sx={{ mr: 2 }}
        form="save-store-form"
      >
        {loading ? 'Saving...' : 'Save Store'}
      </Button>
    </DialogActions>
  )
}

function StoreLogo({
  image, storeId, removeImage, update, handleImageUpload,
}) {
  const src = image && typeof (image) === 'object' ? URL.createObjectURL(image) : image
  const handleErrors = () => {
    // do nothing
  }
  const handleResponse = (data) => {
    removeImage()
    update(data.updateStore)
  }

  const [mutate, { loading }] = useMutation(UPDATE_STORE, { onError: handleErrors, onCompleted: ((res) => handleResponse(res)) })

  const removeImg = () => {
    if (typeof (image) === 'object') {
      removeImage()
    } else {
      mutate({
        variables: {
          id: storeId,
          logoUrl: image,
        },
      })
    }
  }
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      { src
        ? (
          <div className={`image-holder ${loading ? 'deleting-opacity' : ''}`}>
            <div className="delete-img" onClick={removeImg}>
              <Delete style={{ fontSize: '24px', color: 'red' }} />
            </div>
            <img src={src} alt="logo" />
          </div>
        )
        : (
          <div className="upload-btn-wrapper">
            <button type="button" className="upload-btn upload-btn-row">
              <h5>Add Logo</h5>
              <AddBox style={{ fontSize: '20px', color: ' #808080' }} />
            </button>
            <input type="file" name="file" accept="images/*" onChange={handleImageUpload} />
          </div>
        )}
    </Box>
  )
}

export default function SaveStoreDialog({
  store, add, update, close,
}) {
  const [locationName, setlocationName] = useState(store?.name || '')
  const [locationPhone, setlocationPhone] = useState(store?.phone || '')
  const [mapLocation, setmapLocation] = useState(store?.mapLocation || '')
  const [locationDetails, setLocationDetails] = useState(store?.details || '')
  const [mapOpen, setMapOpen] = useState(false)
  const [image, setImage] = useState(store?.logo || null)

  const handleResponse = (data) => {
    if (store) {
      toast.success('store updated')
      update(data.updateStore)
    } else {
      toast.success('store created')
      add(data.addStore)
    }
    close()
  }
  const [addStoreReq, { loading }] = useMutation(ADD_STORE, { onError: () => { toast.error('error creating store') }, onCompleted: handleResponse })
  const [updateStoreReq, { loading: updating }] = useMutation(UPDATE_STORE, {
    onError: () => { toast.error('error updating store') },
    onCompleted: handleResponse,
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (store) {
      // update
      updateStoreReq({
        variables: {
          id: store.id,
          name: locationName,
          details: locationDetails,
          mapLocation: {
            placeId: mapLocation.placeId,
            name: mapLocation.name,
            lat: mapLocation.lat,
            lng: mapLocation.lng,
          },
          phone: locationPhone,
          logo: image && typeof (image) === 'object' ? image : null,
        },
      })
    } else {
      addStoreReq({
        variables: {
          name: locationName,
          details: locationDetails,
          mapLocation,
          phone: locationPhone,
        },
      })
    }
  }
  return (
    <>
      {mapOpen ? <SetAddressDialog mapLocation={mapLocation} onChange={(v) => { setmapLocation(v) }} close={() => (setMapOpen(false))} /> : null}
      <ResponsiveModal
        title={store ? 'Edit Store' : 'Add Store'}
        visible
        cancellable
        cancel={close}
        dialogActions={<Actions loading={loading || updating} close={close} />}
      >
        <Box component="form" id="save-store-form" onSubmit={handleSubmit}>
          <TextField type="text" size="small" required value={locationName} onChange={(e) => { setlocationName(e.target.value) }} label="Store Name" variant="outlined" fullWidth />
          <TextField type="text" size="small" required value={locationDetails} onChange={(e) => { setLocationDetails(e.target.value) }} label="Location Details (street, building, floor)" variant="outlined" fullWidth sx={{ mt: 2 }} />
          <TextField type="text" size="small" required value={locationPhone} onChange={(e) => { setlocationPhone(e.target.value) }} label="Store Phone" variant="outlined" fullWidth sx={{ mt: 2 }} />
          <TextField
            type="text"
            size="small"
            value={mapLocation?.name}
            InputProps={{
              readOnly: true,
              endAdornment:
  <InputAdornment position="end">
    <IconButton
      size="small"
      onClick={() => { setMapOpen(true) }}
    >
      <LocationOn fontSize="20" />
    </IconButton>
  </InputAdornment>,
            }}
            label="Map location"
            variant="outlined"
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />
          { store ? <StoreLogo image={image} handleImageUpload={(e) => { setImage(e.target.files[0]) }} update={update} removeImage={() => { setImage(null) }} storeId={store.id} /> : null }
        </Box>
      </ResponsiveModal>
    </>
  )
}
