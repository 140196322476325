import React, { useEffect, useState } from 'react'
import {
  Box, Typography, Button, Divider,
} from '@mui/material'
import { Wallet } from '@mui/icons-material'
import { useLazyQuery } from '@apollo/client'
import SaveCampaignDialog from './SaveCampaignDialog'
import { formatMoney } from '../../utils/formatter'
import { SMS_BALANCE } from '../../graphql/merchant'

export default function SmsPageHeader({ onNewCampaign }) {
  const [addCampaignOpen, toggleAddCampaign] = useState(false)

  const [balance, setBalance] = useState(0)

  const handleResponse = (data) => {
    if (data.smsBalance) {
      setBalance(data.smsBalance.smsBalance)
    }
  }

  const [getSmsBalance, { loading }] = useLazyQuery(SMS_BALANCE, {
    fetchPolicy: 'cache-and-network',
    onError: () => { },
    onCompleted: handleResponse,
  })

  useEffect(() => {
    getSmsBalance()
  }, [])

  return (
    <Box>
      <Box sx={{
        mb: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      >
        { addCampaignOpen ? <SaveCampaignDialog close={() => { toggleAddCampaign(false) }} onNewCampaign={onNewCampaign} /> : null }
        <Typography>Sms Marketing</Typography>
        <Box>
          <Button
            variant="outlined"
            startIcon={<Wallet />}
            onClick={() => { getSmsBalance(true) }}
            sx={{ mr: 2, minWidth: '100px' }}
          >
            {loading ? <Typography>......</Typography> : formatMoney(balance)}
          </Button>
          <Button
            variant="contained"
            onClick={() => { toggleAddCampaign(true) }}
          >
            Create New Campaign
          </Button>
        </Box>
      </Box>
      <Divider sx={{ mx: -2 }} />
    </Box>
  )
}
