/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './styles/modals.css'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function ResponsiveModal({
  visible, title, cancellable, cancel, dialogActions, children,
  sx, maxWidth, fullScreen,
}) {
  if (!visible) {
    return null
  }
  const handleCancellable = () => {
    if (cancellable) {
      cancel()
    }
  }
  return (
    <BootstrapDialog
      onClose={() => {}}
      aria-labelledby="customized-dialog-title"
      open={visible}
      fullWidth
      maxWidth={maxWidth || 'sm'}
      sx={sx}
      fullScreen={fullScreen}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCancellable}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        { children }
      </DialogContent>
      {dialogActions}
    </BootstrapDialog>

  )
}
