import { KeyboardArrowRight, Save } from '@mui/icons-material'
import {
  Box, Button, CircularProgress, DialogActions, FormControl, InputLabel, MenuItem, Select, TextField, Typography,
} from '@mui/material'
import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ResponsiveBigModal from '../layout/ResponsiveBigModal'
import SelectStore from '../locations/SelectStore'
import SelectTemplate from './SelectTemplate'
import ErrorLoading from '../layout/ErrorLoading'
import { ChatPreview } from './TemplateBuilder'
import { ADD_WHATSAPP_CAMPAIGN, EDIT_WHATSAPP_CAMPAIGN } from '../../graphql/whatsappcampaigns'

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api' : 'https://sokolink-api.herokuapp.com/api'

function Actions({
  step, back, close, loading, disabled,
}) {
  const handleBackClick = () => {
    if (step === 0) { close() } else {
      back()
    }
  }
  return (
    <DialogActions>
      <Button onClick={handleBackClick} sx={{ mr: 2 }}>
        { step === 1 ? 'Back' : 'Cancel' }
      </Button>
      { step === 0
        ? (
          <Button
            type="submit"
            form="save-campaign-form"
            endIcon={<KeyboardArrowRight />}
            variant="contained"
            sx={{ mr: 2 }}
          >
            Continue
          </Button>
        ) : (
          <Button
            type="submit"
            form="save-campaign-form"
            disabled={loading || disabled}
            startIcon={<Save />}
            variant="contained"
            sx={{ mr: 2 }}
          >
            {loading ? 'Saving...' : 'Save Campaign'}
          </Button>
        )}
    </DialogActions>
  )
}

function TemplateDataStep({
  templateName, bodyParams, onBodyParamChange, initializeBodyParams, firebaseUser,
  headerImage, onHeaderImageChange,
}) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [template, setTemplate] = useState(null)

  const header = template?.components?.find((component) => component.type === 'HEADER')
  const body = template?.components?.find((component) => component.type === 'BODY')

  const renderSampleDataInputs = (component) => {
    const variableMatches = component.text.match(/\{\{(\d+)\}\}/g)
    if (!variableMatches) return null

    const uniqueVariables = [...new Set(variableMatches.map((match) => parseInt(match.match(/\d+/)[0], 10)))]
    if (!uniqueVariables.length) return null

    return (
      <>
        <Typography variant="subtitle1" sx={{ mb: -1 }}>Message Body Data:</Typography>
        {uniqueVariables.map((variable, i) => (
          <TextField
            size="small"
            required
            key={variable}
            label={`{{${variable}}}`}
            value={bodyParams[i] || ''}
            onChange={(e) => { onBodyParamChange(i, e.target.value) }}
            fullWidth
            sx={{ mt: 2 }}
          />
        ))}
      </>
    )
  }

  useEffect(async () => {
    try {
      setLoading(true)
      const token = await firebaseUser?.getIdToken()
      // Send GET request to the server
      const response = await fetch(`${baseUrl}/waba/templates/${templateName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          AuthToken: `${token}`,
        },
      })
      if (!response.ok) {
        setLoading(false)
        setError(true)
      }
      const res = await response.json()
      setTemplate(res.data)
      initializeBodyParams(res.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
      setError(e)
    }
  }, [])

  if (loading) {
    return (
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', my: 3,
      }}
      >
        <CircularProgress size={20} />
      </Box>
    )
  }

  if (error) return (<div><ErrorLoading visible reload={() => { window.location.reload() }} /></div>)

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <Box sx={{ flex: 0.47 }}>
        {header?.format === 'IMAGE'
          ? (
            <>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Header Image Link:</Typography>
              <TextField
                size="small"
                type="url"
                required
                label="Header Image Link"
                value={headerImage}
                onChange={(e) => { onHeaderImageChange(e.target.value) }}
                fullWidth
                sx={{ mb: 1 }}
              />
            </>
          ) : null }
        { body ? renderSampleDataInputs(body) : null }
      </Box>
      <Box sx={{ flex: 0.47 }}>
        { template ? <ChatPreview components={template.components} headerImageSrc={headerImage} bodyParams={bodyParams} /> : null }
      </Box>
    </Box>
  )
}

function SaveCampaignDialog({
  close, campaign, onUpdateCampaign, firebaseUser, onNewCampaign,
}) {
  const [step, setStep] = useState(0)
  const [name, setName] = useState(campaign?.name || '')
  const [targetCustomers, setTargetCustomers] = useState(campaign?.targetCustomers || 'all')
  const [store, setStore] = useState(campaign?.storeId || 'all')
  const [limit, setLimit] = useState(campaign?.customerLimit || '')
  const [offset, setOffset] = useState(campaign?.customerOffset || 0)
  const [order, setOrder] = useState(campaign?.sortOrder || '')
  const [sort, setSort] = useState(campaign?.sortField || '')
  const [templateName, setTemplateName] = useState(campaign?.templateName || null)
  const [headerImage, setHeaderImage] = useState(campaign?.headerImageUrl || '')
  const [bodyParams, setBodyParams] = useState(campaign?.bodyParams || [])

  const handleTemplateChange = (e) => {
    setTemplateName(e.target.value)
  }

  const initializeBodyParams = (template) => {
    const body = template?.components?.find((component) => component.type === 'BODY')
    const variableMatches = body.text.match(/\{\{(\d+)\}\}/g) || []
    const uniqueVariables = [...new Set(variableMatches.map((match) => parseInt(match.match(/\d+/)[0], 10)))]
    const sampleData = body.example?.body_text?.[0] || []
    const params = uniqueVariables.map((v, i) => {
      if (bodyParams[i]) return bodyParams[i]
      return sampleData[i] || ''
    })
    setBodyParams(params)
  }

  const handleBodyParamChange = (index, value) => {
    const newParams = bodyParams.map((v, i) => {
      if (i === index) return value
      return v
    })
    setBodyParams(newParams)
  }

  const [addCampaignReq, { loading }] = useMutation(ADD_WHATSAPP_CAMPAIGN, {
    onError: () => { toast.error('Error creating campaign') },
    onCompleted: (data) => {
      toast.success('Campaign created successfully')
      onNewCampaign(data.createWhatsappCampaign)
      close()
    },
  })

  const [updateProductReq, { loading: updating }] = useMutation(EDIT_WHATSAPP_CAMPAIGN, {
    onError: () => { toast.error('Error updating campaign.') },
    onCompleted: (data) => {
      toast.success('Campaign updated successfully')
      onUpdateCampaign(data.updateWhatsappCampaign)
      close()
    },
  })
  const onSave = (e) => {
    e.preventDefault()
    if (step === 0) {
      setStep(1)
      return
    }
    if (campaign) {
      updateProductReq({
        variables: {
          id: campaign.id,
          name,
          templateName,
          storeId: store === 'all' ? null : store,
          targetCustomers,
          customerLimit: parseInt(limit, 10) || null,
          customerOffset: parseInt(offset, 10) || null,
          sortField: sort,
          sortOrder: order,
          bodyParams,
          headerImageUrl: headerImage,
        },
      })
    } else {
      addCampaignReq({
        variables: {
          name,
          templateName,
          storeId: store === 'all' ? null : store,
          targetCustomers,
          customerLimit: parseInt(limit, 10) || null,
          customerOffset: parseInt(offset, 10) || null,
          sortField: sort,
          sortOrder: order,
          bodyParams,
        },
      })
    }
  }
  return (
    <ResponsiveBigModal
      title={campaign ? 'Save Campaign' : 'Create Campaign'}
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions disabled={campaign?.status === 'sent'} loading={loading || updating} next={() => { setStep(1) }} close={close} step={step} back={() => { setStep(0) }} />}
    >
      <Box component="form" id="save-campaign-form" onSubmit={onSave}>
        { step === 0
          ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Box sx={{ flex: 0.45 }}>
                <TextField type="text" size="small" required value={name} onChange={(e) => { setName(e.target.value) }} label="Campaign Name" variant="outlined" fullWidth />
                <SelectStore fullWidth sx={{ my: 3 }} hasAll value={store} changeHandler={(e) => { setStore(e.target.value) }} />
                <TextField type="number" size="small" fullWidth value={limit} onChange={(e) => { setLimit(e.target.value) }} label="Customers Limit" variant="outlined" />
                <FormControl fullWidth sx={{ mt: 3 }}>
                  <InputLabel sx={{ mt: -0.5 }}>Sort Field</InputLabel>
                  <Select
                    value={sort}
                    label="Sort Field"
                    onChange={(e) => { setSort(e.target.value) }}
                    type="text"
                    size="small"
                    required={!!order}
                  >
                    <MenuItem value="">No Sorting</MenuItem>
                    <MenuItem value="created_on">Date Created</MenuItem>
                    <MenuItem value="num_orders">Number Of Orders</MenuItem>
                    <MenuItem value="amount_spent">Amount Spent</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ flex: 0.45 }}>
                <SelectTemplate fullWidth onChange={handleTemplateChange} value={templateName} />
                <FormControl fullWidth sx={{ my: 3 }}>
                  <InputLabel>Target Customers</InputLabel>
                  <Select
                    value={targetCustomers}
                    label="Target Customers"
                    onChange={(e) => { setTargetCustomers(e.target.value) }}
                    type="text"
                    size="small"
                  >
                    <MenuItem value="all">All Customers</MenuItem>
                    <MenuItem value="new">New Customers</MenuItem>
                    <MenuItem value="repeat">Repeat Customers</MenuItem>
                  </Select>
                </FormControl>
                <TextField type="number" size="small" fullWidth value={offset} onChange={(e) => { setOffset(e.target.value) }} label="Customers Offset" variant="outlined" />
                <FormControl fullWidth sx={{ mt: 3 }}>
                  <InputLabel sx={{ mt: -0.5 }}>Sort Order</InputLabel>
                  <Select
                    value={order}
                    label="Sort Order"
                    onChange={(e) => { setOrder(e.target.value) }}
                    type="text"
                    size="small"
                    required={!!sort}
                  >
                    <MenuItem value="">No Order</MenuItem>
                    <MenuItem value="ASC">Ascending</MenuItem>
                    <MenuItem value="DESC">Descending</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          ) : (
            <TemplateDataStep templateName={templateName} headerImage={headerImage} onHeaderImageChange={(v) => { setHeaderImage(v) }} bodyParams={bodyParams} onBodyParamChange={handleBodyParamChange} firebaseUser={firebaseUser} initializeBodyParams={initializeBodyParams} />
          )}
      </Box>
    </ResponsiveBigModal>
  )
}

const mapStateToProps = (state) => ({
  firebaseUser: state.user.user,
})
export default connect(mapStateToProps, { })(SaveCampaignDialog)
