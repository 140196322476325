import React, { forwardRef, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  Box, Button, IconButton, Typography,
} from '@mui/material'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import {
  Delete, Edit, KeyboardArrowRight,
} from '@mui/icons-material'
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'
import { GET_CATEGORIES, REMOVE_CATEGORY, SORT_CATEGORIES } from '../../graphql/products'
import ProductsView from './ProductsView'
import CreateCategory from './CreateCategory'
import CenterLoader from '../spinners/CenterLoader'
import ErrorLoading from '../layout/ErrorLoading'
import DeleteDialog from '../shared/DeleteDialog'
import { useProducts } from './context/products'

const Category = forwardRef(({
  category: serverCategory, isActive, setActiveCategory, deleted,
}, ref) => {
  const [category, setCategory] = useState(serverCategory)
  const [editOpen, toggleEdit] = useState(false)
  const [deleteOpen, toggleDelete] = useState(false)
  const name = category.category

  const [removeCategoryReq] = useMutation(REMOVE_CATEGORY, {
    onError: () => {
      toast.error(`'error deleting category ${name}`)
    },
    onCompleted: () => {
      toast.success(`category ${name} deleted successfully.`)
      deleted(category.id)
    },
  })

  const deleteCategory = () => {
    removeCategoryReq({
      variables: {
        id: category?.id,
      },
    })
    toggleDelete(false)
  }

  return (
    <Box ref={ref} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {editOpen ? <CreateCategory updateCategory={(c) => { setActiveCategory(); setCategory(c) }} close={() => { toggleEdit(false) }} category={category} /> : null}
      { deleteOpen ? <DeleteDialog visible={deleteOpen} close={() => { toggleDelete(false) }} title="Delete Category" msg={`Deleting ${name} will delete all items under it. Are you sure you want to proceed.`} onDelete={deleteCategory} /> : null }
      <Button sx={{ textTransform: 'initial', textAlign: 'start' }} onClick={setActiveCategory}>{category.category}</Button>
      {isActive ? (
        <Box>
          <IconButton sx={{ mr: 1 }} onClick={() => { toggleEdit(true) }}>
            <Edit />
          </IconButton>
          <IconButton sx={{ color: 'red' }} onClick={() => { toggleDelete(true) }} size="small">
            <Delete />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  )
})

export default function CategoriesDnD() {
  const { changeCategory } = useProducts()
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState(null)
  const [newOpen, toggleNew] = useState(false)
  const [error, setError] = useState(null)

  const { catalogueId } = useParams()

  const { loading } = useQuery(GET_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      catalogueId,
    },
    onError: (e) => { setError(e) },
    onCompleted: (data) => {
      setCategories(data.categories)
      setCategory(data.categories[0])
      changeCategory(data.categories[0])
    },
  })

  const [sortCatalogueReq] = useMutation(SORT_CATEGORIES, {
    variables: {
      ids: categories.map((c) => (c.id)),
    },
    onError: () => { },
    onCompleted: () => { },
  })

  if (loading) return <CenterLoader visible />

  if (error) return <ErrorLoading visible />

  const addCategory = (c) => {
    setCategories([...categories, c])
  }

  const deleteCategory = (id) => {
    const i = categories.findIndex((c) => (c.id === id))
    setCategories(categories.filter((c) => (c.id !== id)))
    if (i) {
      setCategory(categories[i - 1])
    }
  }

  const onSortEnd = (oldIndex, newIndex) => {
    setCategories((array) => arrayMoveImmutable(array, oldIndex, newIndex))
    sortCatalogueReq()
  }

  return (
    <>
      {newOpen ? <CreateCategory addCategory={addCategory} close={() => { toggleNew(false) }} /> : null}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{
          width: '250px', border: '1px solid #e0e0e0', borderRadius: '5px', overflow: 'hidden',
        }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#e0e0e0',
            padding: 1,
          }}
          >
            <Typography>Categories</Typography>
          </Box>
          <SortableList
            onSortEnd={onSortEnd}
            style={{ height: 'calc(100vh - 242px)', overflowY: 'auto' }}
          >
            {categories?.map((c) => {
              const isActive = category === c
              return (
                <SortableItem key={c.id}>
                  <Category ref={c.id} category={c} isActive={isActive} setActiveCategory={() => { setCategory(c); changeCategory(c) }} deleted={deleteCategory} />
                </SortableItem>
              )
            })}
          </SortableList>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#e0e0e0',
            py: 0.5,
          }}
          >
            <Button size="small" onClick={() => { toggleNew('true') }} startIcon={<KeyboardArrowRight />}>
              <u>Add Category</u>
            </Button>
          </Box>
        </Box>
        <ProductsView category={category} />
      </Box>
    </>
  )
}
