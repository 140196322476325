import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import {
  Box, Button, FormControl, InputLabel, MenuItem, Select, TextField,
} from '@mui/material'
import './styles/MpesaDetails.css'
import { UPDATE_WALLET_INFO } from '../../graphql/wallet'

const banks = [
  {
    bankName: 'Equity Bank Kenya',
    bankNo: '68',
    swiftCode: 'EQBLKENA',
  },
  {
    bankName: 'Mpesa',
    bankNo: 'MPS',
    swiftCode: 'MPS',
  },
  {
    bankName: 'Kenya Commercial Bank Limited',
    bankNo: '01',
    swiftCode: 'KCBLKENX',
  },
  {
    bankName: 'Co-operative Bank of Kenya Limited',
    bankNo: '11',
    swiftCode: 'KCOOKENA',
  },
  {
    bankName: 'CFC Stanbic Bank Kenya Limited',
    bankNo: '31',
    swiftCode: 'SBICKENX',
  },
  {
    bankName: 'NIC Bank Limited',
    bankNo: '41',
    swiftCode: 'NINCKENA',
  },
  {
    bankName: 'Jamii Bora Bank',
    bankNo: '51',
    swiftCode: 'CIFIKENA',
  },
  {
    bankName: 'Housing Finance Bank',
    bankNo: '61',
    swiftCode: 'HFCOKENA',
  },
  {
    bankName: 'Oriental Commercial Bank Limited',
    bankNo: '14',
    swiftCode: 'MORBKENA',
  },
  {
    bankName: 'Victoria Commercial Bank Limited',
    bankNo: '54',
    swiftCode: 'VICMKENA',
  },
  {
    bankName: 'ABSA Bank Kenya PLC',
    bankNo: '10276',
    swiftCode: 'BARCKENX',
  },
  {
    bankName: 'Standard Chartered Bank Limited',
    bankNo: '02',
    swiftCode: 'SCBLKENX',
  },
  {
    bankName: 'Diamond Trust Bank Limited',
    bankNo: '23',
    swiftCode: 'DTKEKENA',
  },
]

export default function BankDetails({ bankDetails, storeId }) {
  const [bank, setBank] = useState(bankDetails?.bankNo)
  const [accNo, setAccNo] = useState(bankDetails.bankAccountNumber || '')
  const [accName, setAccName] = useState(bankDetails.bankAccountName || '')
  const [accBranch, setAccBranch] = useState(bankDetails.bankBranch || '')

  const handleErrors = () => {
    toast.error('error updating bank details')
  }
  const handleResponse = () => {
    toast.success('bank details updated')
  }
  const [updateWalletInfo, { loading }] = useMutation(UPDATE_WALLET_INFO, { onError: handleErrors, onCompleted: handleResponse })

  const handleSubmit = (e) => {
    e.preventDefault()
    updateWalletInfo({
      variables: {
        storeId: bankDetails.storeId || storeId,
        bankName: banks.find((b) => b.bankNo === bank).bankName,
        bankNo: banks.find((b) => b.bankNo === bank).bankNo,
        swiftCode: banks.find((b) => b.bankNo === bank).swiftCode,
        bankAccountName: accName,
        bankBranch: accBranch,
        bankAccountNumber: accNo,
      },
    })
  }

  return (
    <form className="wallet-section" onSubmit={handleSubmit}>
      <div className="wallet-section-header">
        <h3>Bank Details</h3>
        <Button type="submit" variant="outlined" disabled={loading}>
          {loading ? 'Saving...' : 'Save Details'}
        </Button>
      </div>
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', pt: 3, mb: 3,
      }}
      >
        <FormControl style={{ flex: 0.48 }}>
          <InputLabel>Bank Name</InputLabel>
          <Select
            value={bank}
            label="Bank Name"
            onChange={(e) => setBank(e.target.value)}
            type="text"
            size="small"
          >
            { banks.map((b) => (<MenuItem key={b.bankNo} value={b.bankNo}>{b.bankName}</MenuItem>)) }
          </Select>
        </FormControl>
        <TextField type="text" size="small" required value={accNo} onChange={(e) => { setAccNo(e.target.value) }} label="Account number" variant="outlined" style={{ flex: 0.48 }} />
      </Box>
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', mb: 3,
      }}
      >
        <TextField type="text" size="small" required value={accName} onChange={(e) => { setAccName(e.target.value) }} label="Account name" variant="outlined" style={{ flex: 0.48 }} />
        <TextField type="text" size="small" required value={accBranch} onChange={(e) => { setAccBranch(e.target.value) }} label="Bank Branch" variant="outlined" style={{ flex: 0.48 }} />
      </Box>
    </form>
  )
}
