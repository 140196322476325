import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import {
  Box, Button, Divider, Grid, TextField, Typography,
} from '@mui/material'
import { UPDATE_MERCHANT } from '../../graphql/merchant'

export default function ContactPerson({ merchant, updateMerchant }) {
  const [personName, setPersonName] = useState(merchant?.personName)
  const [personId, setPersonId] = useState(merchant?.personId)
  const [personPhone, setPersonPhone] = useState(merchant?.personPhone)
  const [personEmail, setPersonEmail] = useState(merchant?.personEmail)

  const handleErrors = () => {
    toast.error('error updating contact person details')
  }

  const handleResponse = (data) => {
    updateMerchant(data.updateMerchantDetails)
    toast.success('contact person details updated')
  }

  const [updateSettingsReq, { loading }] = useMutation(UPDATE_MERCHANT, { onError: handleErrors, onCompleted: handleResponse })

  const handleSubmit = (e) => {
    e.preventDefault()
    updateSettingsReq({
      variables: {
        personName,
        personId,
        personPhone,
        personEmail,
      },
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '5px',
      }}
      >
        <Typography>Contact Person Details</Typography>
        <Button type="submit">
          { loading ? 'Saving...' : 'Save Details' }
        </Button>
      </Box>
      <Divider />
      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth type="text" required label="Person Name" placeholder="name" value={personName} onChange={(e) => setPersonName(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth type="text" required label="ID No" placeholder="id no" value={personId} onChange={(e) => setPersonId(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth type="number" required label="Phone Number" placeholder="phone" value={personPhone} onChange={(e) => setPersonPhone(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField size="small" fullWidth type="email" required label="Email Address" placeholder="emai" value={personEmail} onChange={(e) => setPersonEmail(e.target.value)} />
        </Grid>
      </Grid>
    </form>
  )
}
