import React, { useCallback, useState } from 'react'
import Geocode from 'react-geocode'
import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import {
  GoogleMap, useJsApiLoader, MarkerF,
} from '@react-google-maps/api'

Geocode.setApiKey('AIzaSyAXEkv3ohotrk5e_9rNXxhXZ9RkOjZk9pY')
Geocode.setLanguage('en')
Geocode.setRegion('ke')

function MapContainer({
  initialLocation, save, resetLocation,
}) {
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState(initialLocation)
  const initialPos = { lat: initialLocation.lat, lng: initialLocation.lng }
  const [markerPos, setMarkerPos] = useState(initialPos)

  const handleMapDragEnd = async () => {
    setLoading(true)
    const geoCodeReq = Geocode.fromLatLng(`${markerPos.lat}`, `${markerPos.lng}`)
    try {
      const results = await geoCodeReq
      const res = results.results.length ? results.results[0] : null
      const placeLocation = {
        placeId: res.place_id,
        name: res.formatted_address,
        lat: res.geometry.location.lat,
        lng: res.geometry.location.lng,
      }
      setLocation(placeLocation)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const confirmLocation = () => {
    save(location)
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAXEkv3ohotrk5e_9rNXxhXZ9RkOjZk9pY',
  })

  const containerStyle = {
    width: '100%',
    height: 'calc(100vh - 100px)',
  }
  const smallScreenContainerStyle = {
    width: '100%', // Adjusted width for smaller screens
    height: 'calc(100vh - 100px)',
  }
  const viewportWidth = window.innerWidth
  const mapStyle = viewportWidth <= 768 ? smallScreenContainerStyle : containerStyle

  const [center] = useState(initialPos)

  const onLoad = useCallback((map) => {
    const defaultZoom = 16
    map.setZoom(defaultZoom)
    map.setCenter(center)

    const centerChangeHandler = () => {
      const newCenter = map.getCenter()
      setMarkerPos({ lat: newCenter.lat(), lng: newCenter.lng() })
    }

    map.addListener('center_changed', centerChangeHandler)

    return () => {
      // Clean up event listener when the component unmounts
      map.removeListener('center_changed', centerChangeHandler)
    }
  }, [center])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapStyle}
      center={center}
      onLoad={onLoad}
      onDragEnd={handleMapDragEnd}
      options={{
        disableDefaultUI: true,
      }}
    >
      <MarkerF position={markerPos} />
      <div className="location-confirm">
        <Typography fontWeight={500}>Place pin to your exact location</Typography>
        <div className="location-input" onClick={resetLocation}>
          <p>{location ? location.name : 'No Location Selected'}</p>
          <Button>Change</Button>
        </div>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <InfoOutlined color="warning" sx={{ mr: 0.5 }} />
          <Typography fontSize={12}>Ensure location is accurate, your orders will be picked here.</Typography>
        </Box>
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 2, height: 40 }}
          size="large"
          disabled={loading}
          onClick={confirmLocation}
        >
          {loading ? <CircularProgress size={20} /> : 'Confirm Location & Proceed'}
        </Button>
      </div>
    </GoogleMap>
  ) : <div>Loading map...</div>
}
export default MapContainer
