import { InfoOutlined } from '@mui/icons-material'
import {
  Box, Button, DialogActions, Typography,
} from '@mui/material'
import { React } from 'react'
import ResponsiveModal from '../layout/ResponsiveModal'

function Actions({ onDelete, loading, close }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        onClick={onDelete}
        disabled={loading}
        variant="contained"
        sx={{ mr: 2 }}
      >
        { !loading ? 'Delete' : 'Deleting...'}
      </Button>
    </DialogActions>
  )
}

export default function DeleteDialog({
  title, msg, data, visible, onDelete, close,
  sx, maxWidth, loading,
}) {
  return (
    <ResponsiveModal
      visible={visible}
      cancellable
      cancel={close}
      title={title}
      sx={sx}
      maxWidth={maxWidth}
      dialogActions={<Actions onDelete={() => { onDelete(data) }} loading={loading} close={close} />}
    >
      <Box sx={{ textAlign: 'center' }}>
        <InfoOutlined style={{ fontSize: 80, color: 'orange' }} />
        <Box sx={{ fontSize: '20px', fontWeight: '900' }}>
          <Typography>{msg}</Typography>
        </Box>
        <Box sx={{ m: 1 }}>
          This action cannot be undone.
        </Box>
      </Box>
    </ResponsiveModal>

  )
}
