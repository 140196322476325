import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { useMutation } from '@apollo/client'
import Papa from 'papaparse'
import FileSaver from 'file-saver'
import { Box, Button, Typography } from '@mui/material'
import ResponsiveBigModal from '../layout/ResponsiveModal'
import SelectStore from '../locations/SelectStore'
import { UPLOAD_CUSTOMERS } from '../../graphql/customers'

const validateCsvData = (data) => {
  data.forEach((c) => {
    if (!c['Customer Name']) {
      toast.error("A customer in your file doesn't have a name, check and try again", { id: 'file-error', duration: 30000 })
    }
    if (!c['Phone Number']) {
      toast.error("A customer in your file doesn't have a phone number, check and try again", { id: 'file-error', duration: 30000 })
    } else if (!c['Phone Number'].startsWith('+')) {
      toast.error("A customer's phone number must start with '+', check and try again", { id: 'file-error', duration: 30000 })
    }
    if (!c['Marketing Consent']) {
      toast.error("A customer in your file doesn't have a marketing consent value, check and try again", { id: 'file-error', duration: 30000 })
    }
  })
}

export default function UploadCustomers({ close }) {
  const [storeId, setStoreId] = useState('')
  const [fileName, setFileName] = useState('')
  const [customers, setCustomers] = useState([])

  const downloadSample = () => {
    const sampleProductDetails = [{
      'Customer Name': 'Jane Doe',
      'Phone Number': '+254712345678',
      'Email Address': 'janedoe@sokolink.com',
      'Marketing Consent': 'Yes/No',
    }]

    const sampleStr = JSON.stringify(sampleProductDetails)
    const csv = Papa.unparse(sampleStr)
    const blob = new Blob([decodeURIComponent(encodeURI(csv))], {
      type: 'text/csv;charset=utf-8;',
    })
    FileSaver.saveAs(blob, 'customer-upload-sample.csv')
  }

  const handleFileUpload = (e) => {
    setCustomers([])
    toast.dismiss('file-error')
    const fileInput = e.target.files[0]
    setFileName(fileInput.name)
    Papa.parse(fileInput, {
      download: true,
      header: true,
      skipEmptyLines: true,
      error: () => { toast.error('error getting data from CSV file') },
      complete: (results) => {
        if (results.data.length === 0) {
          toast.error('No data in the CSV file')
        }
        validateCsvData(results.data)
        const newCustomers = results.data.map((c) => ({
          name: c['Customer Name'],
          phone: c['Phone Number'],
          email: c['Email Address'],
          consent: c['Marketing Consent'] === 'Yes',
        }))
        setCustomers(newCustomers)
      },
    })
  }

  const handleErrors = () => {
    toast.error('Error uploading customers')
  }
  const handleResponse = () => {
    toast.success(`Added ${customers.length} customers`)
    close()
    window.location.reload()
  }
  const [addCustomersReq, { loading }] = useMutation(UPLOAD_CUSTOMERS, { onError: handleErrors, onCompleted: handleResponse })

  const handleSubmit = (e) => {
    e.preventDefault()
    addCustomersReq({
      variables: {
        storeId,
        customers,
      },
    })
  }
  return (
    <ResponsiveBigModal
      title="Upload Customers"
      visible
      cancellable
      cancel={close}
    >
      <form onSubmit={handleSubmit}>
        <Box>
          <div>
            <Typography>
              To upload store customers, prepare a csv file like the sample below and select a store to upload to.
              {' '}
              <span>
                <u onClick={downloadSample}>Download Sample File</u>
              </span>
            </Typography>
          </div>
          <Box sx={{
            display: 'flex', justifyContent: 'start', alignItems: 'center', mt: 1,
          }}
          >
            <div className="upload-btn-wrapper">
              <button type="button" className="upload-btn upload-btn-row">
                <h5>Select File</h5>
                <AddBoxIcon style={{ fontSize: '20px', color: ' #808080', marginLeft: '10px' }} />
              </button>
              <input type="file" name="file" accept="images/*" multiple required onChange={handleFileUpload} />
            </div>
            <Typography sx={{ color: 'green', ml: 2 }}>{fileName}</Typography>
          </Box>
        </Box>
        <SelectStore required value={storeId} changeHandler={(e) => { setStoreId(e.target.value) }} />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button fullWidth type="submit" variant="outlined" disabled={loading} sx={{ mb: 2 }}>
            {!loading ? 'Upload File' : 'Uploading...'}
          </Button>
        </Box>
      </form>
    </ResponsiveBigModal>
  )
}
