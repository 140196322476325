/* eslint-disable react/jsx-props-no-spreading */
import { React, useState } from 'react'
import {
  Box, Button, DialogActions, TextField,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import ResponsiveBigModal from '../layout/ResponsiveModal'
import { UPDATE_MERCHANT } from '../../graphql/merchant'

function Actions({ close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        loading={loading}
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mr: 2 }}
        form="change-link-form"
      >
        {loading ? 'Saving...' : 'Save Link'}
      </Button>
    </DialogActions>
  )
}

export default function ChangeStoreLink({
  link, close, updateMerchant,
}) {
  const [domain, setDomain] = useState(link || '')

  const [updateReq, { loading }] = useMutation(UPDATE_MERCHANT, {
    onError: () => { toast.error('Error updating store link.') },
    onCompleted: (data) => {
      updateMerchant(data.updateMerchantDetails)
      toast.success('Store link updated successfully')
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    updateReq({
      variables: {
        businessDomain: domain,
      },
    })
  }

  return (
    <ResponsiveBigModal
      visible
      title="Edit Store Link"
      cancellable
      cancel={close}
      dialogActions={<Actions onSave={onSave} loading={loading} close={close} />}
    >
      <Box component="form" id="change-link-form" onSubmit={onSave}>
        <TextField type="text" size="small" fullWidth required value={domain} onChange={(e) => { setDomain(e.target.value) }} label="Domain(.sokolink.com)" variant="standard" />
      </Box>
    </ResponsiveBigModal>
  )
}
