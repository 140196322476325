import { React, useReducer, useState } from 'react'
import {
  Box, Button, DialogActions, ListItemText,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import { KeyboardArrowRight } from '@mui/icons-material'
import ResponsiveModal from '../layout/ResponsiveModal'
import { UPDATE_STORE } from '../../graphql/locations'
import MapContainer from '../maps/DrawZones'
import DayAvailability from '../shared/DayAvailability'
import DistanceCharges from './DistanceCharges'
import ServiceCharges from './ServiceCharges'
import MpesaIntegration from './MpesaIntegration'

const timingsReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ITEM':
      return {
        ...state, // Copy the existing state
        [action.day]: { // Update the specific day
          ...state[action.day], // Copy the existing day's properties
          ...action.newValue, // Update with new values
        },
      }
    default:
      return state
  }
}

function Actions({ close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mr: 2 }}
        form="add-catalogue-form"
      >
        {loading ? 'Saving...' : 'Save Settings'}
      </Button>
    </DialogActions>
  )
}

export default function SaveStoreSettings({
  close, store, update,
}) {
  const [minimumOrderAmount, setMinimumOrderAmount] = useState(store?.minimumOrderAmount)
  const [freeDeliveryOrderAmount, setFreeDeliveryOrderAmount] = useState(store?.freeDeliveryAmount)
  const [deliveryAreaDistance, setDeliveryArea] = useState(store?.deliveryDistance)
  const [maxOwnDeliveryDistance, setMaxOwnDeliveryDistance] = useState(store?.maxOwnDeliveryDistance)
  const [deliveryOption, setDeliveryOption] = useState(store?.deliveryOption)
  const [acceptUnpaidOrders, toggleUnpaidOrders] = useState(store?.acceptUnpaidOrders)
  const [smsAlerts, toggleSmsAlerts] = useState(store?.smsAlerts)
  const [whatsappAlerts, toggleWhatsappAlerts] = useState(store?.whatsappAlerts)
  const [template, setTemplate] = useState(store?.template)
  const [acceptPastRadiusOrders, toggleAcceptPastRadius] = useState(store?.acceptPastRadiusOrders)
  const [distancesOpen, toggleDistancesOpen] = useState(false)
  const [zonesOpen, toggleZonesOpen] = useState(false)
  const [serviceOpen, toggleServiceOpen] = useState(false)
  const [mpesaOpen, toggleMpesa] = useState(false)

  const serverData = store.timings
  const timingsArr = Object.keys(store.timings).filter((d) => d !== '__typename')
  const formattedTimings = {}
  timingsArr.forEach((d) => {
    const { status, from, to } = serverData[d]
    formattedTimings[d] = { status, from, to }
  })
  const [timings, dispatch] = useReducer(timingsReducer, formattedTimings)

  const updateTiming = (day, newValue) => {
    dispatch({ type: 'UPDATE_ITEM', day, newValue })
  }

  const [updateReq, { loading }] = useMutation(UPDATE_STORE, {
    onError: () => { toast.error('Error updating controls.') },
    onCompleted: (data) => {
      toast.success('Controls  updated successfully')
      update(data.updateStore)
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    updateReq({
      variables: {
        id: store.id,
        deliveryOption,
        minimumOrderAmount: parseFloat(minimumOrderAmount),
        freeDeliveryOrderAmount: parseFloat(freeDeliveryOrderAmount),
        deliveryAreaDistance: parseFloat(deliveryAreaDistance),
        maxOwnDeliveryDistance: parseFloat(maxOwnDeliveryDistance),
        acceptUnpaidOrders,
        smsAlerts,
        template,
        whatsappAlerts,
        acceptPastRadiusOrders,
        timings,
      },
    })
  }
  return (
    <>
      {distancesOpen ? <DistanceCharges storeId={store?.id} deliveryBaseRate={store?.deliveryBaseRate} kmCost={store?.costPerKm} storeDeliveryCharges={store?.deliveryCharges} close={() => toggleDistancesOpen(false)} update={update} /> : null}
      {zonesOpen ? <MapContainer storeId={store?.id} mapLocation={store?.mapLocation} deliveryZones={store?.deliveryZones} close={() => toggleZonesOpen(false)} update={update} /> : null}
      {serviceOpen ? <ServiceCharges storeId={store?.id} initialCharges={store?.serviceCharges} close={() => toggleServiceOpen(false)} update={update} /> : null}
      {mpesaOpen ? <MpesaIntegration store={store} close={() => toggleMpesa(false)} update={update} /> : null}
      <ResponsiveModal
        title="Store Controls"
        visible
        cancellable
        cancel={close}
        dialogActions={<Actions onSave={onSave} loading={loading} close={close} />}
      >
        <Box component="form" id="add-catalogue-form" onSubmit={onSave}>
          <Box>
            <ListItemText primary="Storefront Template" />
            <RadioGroup
              row
              name="template-radion-btns"
              value={template}
              onChange={(e) => { setTemplate(e.target.value) }}
              sx={{ mb: 2 }}
            >
              <FormControlLabel value="basic" control={<Radio />} label="Basic" />
              <FormControlLabel value="mart" control={<Radio />} label="Mart" />
            </RadioGroup>
          </Box>
          <DayAvailability title="Store Timings" timings={timings} handleChange={updateTiming} />
          <ListItemText primary="Order Payment" />
          <Box sx={{ mb: 2 }}>
            <FormControlLabel control={<Switch checked={acceptUnpaidOrders} onChange={() => { toggleUnpaidOrders(!acceptUnpaidOrders) }} />} labelPlacement="end" label="Accept Unpaid Orders" />
          </Box>
          <ListItemText primary="Order Notifications" />
          <Box sx={{ mb: 2 }}>
            <FormControlLabel control={<Switch checked={smsAlerts} onChange={() => { toggleSmsAlerts(!smsAlerts) }} />} labelPlacement="end" label="Sms Notifications" />
            <FormControlLabel control={<Switch checked={whatsappAlerts} onChange={() => { toggleWhatsappAlerts(!whatsappAlerts) }} />} labelPlacement="end" label="Whatsapp Notifications" />
          </Box>
          <Box>
            <ListItemText primary="Preferred Delivery Option" />
            <RadioGroup
              row
              name="row-radio-buttons-group"
              value={deliveryOption}
              onChange={(e) => { setDeliveryOption(e.target.value) }}
              sx={{ mb: 1 }}
            >
              <FormControlLabel value="self" control={<Radio />} label="Own Rider" />
              <FormControlLabel value="sokolink" control={<Radio />} label="Sokolink Delivery" />
              <FormControlLabel value="both" control={<Radio />} label="Both(After Max Own Radius)" />
            </RadioGroup>
          </Box>
          <ListItemText primary="Ordering & Delivery Limits" sx={{ mb: 2 }} />
          <Box sx={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3,
          }}
          >
            <TextField type="number" size="small" required value={minimumOrderAmount} onChange={(e) => { setMinimumOrderAmount(e.target.value) }} label="Minimum Order Amount (Ksh)" variant="outlined" style={{ flex: 0.48 }} />
            <TextField type="number" size="small" required value={freeDeliveryOrderAmount} onChange={(e) => { setFreeDeliveryOrderAmount(e.target.value) }} label="Free Delivery Amount (Ksh)" variant="outlined" style={{ flex: 0.48 }} />
          </Box>
          <Box sx={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1,
          }}
          >
            <TextField type="number" size="small" required value={deliveryAreaDistance} onChange={(e) => { setDeliveryArea(e.target.value) }} label="Delivery Radius (Kms)" variant="outlined" style={{ flex: 0.48 }} />
            <TextField type="number" size="small" required value={maxOwnDeliveryDistance} onChange={(e) => { setMaxOwnDeliveryDistance(e.target.value) }} label="Max Own Delivery Radius (Kms)" variant="outlined" style={{ flex: 0.48 }} />
          </Box>
          <FormControlLabel control={<Switch checked={acceptPastRadiusOrders} onChange={() => { toggleAcceptPastRadius(!acceptPastRadiusOrders) }} />} labelPlacement="end" label="Accept Orders Beyond Radius (No Delivery Fee)" sx={{ mb: 1 }} />

          <ListItemText sx={{ mb: 1 }} primary="Configure Store Charges" />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bgcolor: 'ButtonShadow',
              p: 1,
              mb: 1,
              borderRadius: 1,
              cursor: 'pointer',
            }}
            onClick={() => { toggleDistancesOpen(true) }}
          >
            <Typography component="caption">Distance Delivery Charges</Typography>
            <KeyboardArrowRight />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bgcolor: 'ButtonShadow',
              p: 1,
              mb: 1,
              borderRadius: 1,
              cursor: 'pointer',
            }}
            onClick={() => { toggleZonesOpen(true) }}
          >
            <Typography component="caption">Delivery Zones Charges</Typography>
            <KeyboardArrowRight />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bgcolor: 'ButtonShadow',
              p: 1,
              mb: 1,
              borderRadius: 1,
              cursor: 'pointer',
            }}
            onClick={() => { toggleServiceOpen(true) }}
          >
            <Typography component="caption">Service Fee Charges</Typography>
            <KeyboardArrowRight />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bgcolor: 'ButtonShadow',
              p: 1,
              borderRadius: 1,
              cursor: 'pointer',
            }}
            onClick={() => { toggleMpesa(true) }}
          >
            <Typography component="caption">Mpesa Integration</Typography>
            <KeyboardArrowRight />
          </Box>
        </Box>
      </ResponsiveModal>
    </>
  )
}
