import React from 'react'
import {
  Box, Divider, Rating, Typography,
} from '@mui/material'
import { formatDate } from '../../utils/formatter'

function Feedback({ review }) {
  const {
    rating, comment, createdOn, customer, orderNo,
  } = review
  return (
    <tr>
      <td style={{ minWidth: '150px', padding: '10px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Rating value={rating} readOnly />
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          <Typography variant="h6">{rating}</Typography>
        </Box>
      </td>
      <td style={{ minWidth: '150px', maxWidth: '450px' }}>
        <p>{comment}</p>
      </td>
      <td style={{ minWidth: '150px' }}>
        <p>{orderNo || '--'}</p>
      </td>
      <td style={{ minWidth: '150px' }}>
        <p>{customer?.name || '--'}</p>
      </td>
      <td style={{ minWidth: '150px' }}>
        <p>{customer?.phone || '--'}</p>
      </td>
      <td style={{ minWidth: '150px' }}>
        <p>{formatDate(createdOn)}</p>
      </td>
    </tr>
  )
}

export default function FeedbackTable({ reviews }) {
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Rating</th>
            <th>Comment</th>
            <th>Order No</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {reviews.map((r) => <Feedback key={r.id} review={r} />)}
        </tbody>
      </table>
    </div>

  )
}
