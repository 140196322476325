import { KeyboardArrowRight } from '@mui/icons-material'
import { Box, Typography, Divider } from '@mui/material'
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share'

export default function ShareLink({ domain }) {
  const shareUrl = `https://${domain}.sokolink.com/`
  const title = 'Orde Direct From'
  return (
    <Box sx={{ width: '100%', height: '100%', border: '1px solid #ddd' }}>
      <Typography variant="h6" component="div" sx={{ m: 1 }}>Share Store Link</Typography>
      <Divider sx={{ mb: 1.5 }} />
      <WhatsappShareButton
        url={shareUrl}
        title={title}
        className="share-button"
        style={{ width: '100%' }}
      >
        <Box sx={{
          px: 1, py: 0.5, m: 1, border: '1px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: 1,
        }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <WhatsappIcon size={50} round />
            <Typography sx={{ ml: 1 }}>Share on Whatsapp</Typography>
          </Box>
          <KeyboardArrowRight />
        </Box>
      </WhatsappShareButton>
      <FacebookShareButton
        url={shareUrl}
        title={title}
        className="share-button"
        style={{ width: '100%' }}
      >
        <Box sx={{
          px: 1, py: 0.5, m: 1, border: '1px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: 1,
        }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FacebookIcon size={50} round />
            <Typography sx={{ ml: 1 }}>Share on Facebook</Typography>
          </Box>
          <KeyboardArrowRight />
        </Box>
      </FacebookShareButton>
      <FacebookMessengerShareButton
        url={shareUrl}
        title={title}
        className="share-button"
        style={{ width: '100%' }}
      >
        <Box sx={{
          px: 1, py: 0.5, m: 1, border: '1px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: 1,
        }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FacebookMessengerIcon size={50} round />
            <Typography sx={{ ml: 1 }}>Share on Messenger</Typography>
          </Box>
          <KeyboardArrowRight />
        </Box>
      </FacebookMessengerShareButton>
      <TwitterShareButton
        url={shareUrl}
        title={title}
        className="share-button"
        style={{ width: '100%' }}
      >
        <Box sx={{
          px: 1, py: 0.5, m: 1, border: '1px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: 1,
        }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TwitterIcon size={50} round />
            <Typography sx={{ ml: 1 }}>Share on Twitter</Typography>
          </Box>
          <KeyboardArrowRight />
        </Box>
      </TwitterShareButton>
    </Box>
  )
}
