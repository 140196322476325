/* eslint-disable react/destructuring-assignment */
import {
  SET_MERCHANT,
} from '../actions/types'

const initialState = {
  merchantInfo: null,
}

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MERCHANT:
      return {
        ...state,
        merchantInfo: action.payload,
      }
    default:
      return state
  }
}
