import { Box, Button, Divider } from '@mui/material'
import { toast } from 'react-hot-toast'
import Typography from '@mui/material/Typography'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Launch } from '@mui/icons-material'
import QrCode from './QrCode'

export default function LinkCard({ domain }) {
  const link = `https://${domain}.sokolink.com/`
  const copyLink = () => {
    navigator.clipboard.writeText(link)
    toast.success('Link copied to clipboard')
  }
  return (
    <Box sx={{ width: '100%', border: '1px solid #ddd' }}>
      <Typography variant="h6" component="div" sx={{ m: 1 }}>Link and QrCode</Typography>
      <Divider />
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="outlined"
          onClick={copyLink}
          startIcon={<ContentCopyIcon />}
        >
          Copy
        </Button>
        <Button endIcon={<Launch />} variant="outlined" sx={{ ml: 3, textTransform: 'lowercase' }} target="_blank" href={link} rel="noreferrer">{link}</Button>
      </Box>
      <QrCode domain={domain} />
    </Box>
  )
}
