import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'

import { STORES } from '../../graphql/locations'

export default function SelectStores({
  changeHandler, values, hasAll, required, sx, disabled,
}) {
  const [stores, setStores] = useState([])

  const { loading } = useQuery(STORES, {
    variables: {
      limit: 100,
      offset: 0,
    },
    onError: () => {},
    onCompleted: (data) => { setStores(data.stores) },
  })

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    if (value.includes('all')) {
      const allStoreIds = stores.map((s) => s.id)
      changeHandler(allStoreIds)
      return
    }
    changeHandler(value)
  }

  return (
    <FormControl sx={sx || { width: '100%', marginY: 2 }}>
      <Select
        multiple
        required={required}
        value={values}
        onChange={handleChange}
        type="text"
        size="small"
        displayEmpty
        disabled={disabled}
      >
        {loading ? <MenuItem value="">loading stores</MenuItem> : null}
        {hasAll && stores.length !== values?.length ? <MenuItem key="all" value="all">Select All Stores</MenuItem> : null }
        {stores.map((s) => (<MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>))}
      </Select>
    </FormControl>
  )
}
