import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { Delete } from '@mui/icons-material'
import { IconButton, Switch, Tooltip } from '@mui/material'
import { useMutation } from '@apollo/client'
import './styles/StoresView.css'
import { UPDATE_STORE } from '../../graphql/locations'
import DeleteDialog from '../shared/DeleteDialog'

function Store({ store, deleteStore }) {
  const {
    id, name, details, phone, mallActive,
  } = store
  const [status, setStatus] = useState(mallActive)
  const [deleteOpen, toggleDelete] = useState(false)

  const [updateReq, { loading }] = useMutation(UPDATE_STORE, {
    onCompleted: () => { setStatus(!status) },
    onError: () => { toast.error('Error updating store status.') },
  })

  const handleToggle = () => {
    updateReq({
      variables: {
        id,
        mallActive: !status,
      },
    })
  }
  const [deleteReq, { loading: deleting }] = useMutation(UPDATE_STORE, {
    variables: { id, mallId: null },
    onError: () => {
      toast.error(`error removing business ${name}`)
    },
    onCompleted: () => {
      toast.success(`business ${name} removed successfully`)
      deleteStore(id)
      toggleDelete(false)
    },
  })

  return (
    <>
      { deleteOpen ? (
        <DeleteDialog
          visible
          title="Remove Business"
          msg={`Are you sure you want to remove ${name} business from the mall?`}
          onDelete={deleteReq}
          loading={deleting}
          close={() => (toggleDelete(false))}
          maxWidth="xs"
        />
      ) : null }
      <tr className="store">
        <td>
          <p>{id}</p>
        </td>
        <td>
          <p>{name}</p>
        </td>
        <td>
          <p>{details}</p>
        </td>
        <td>
          <p>{phone}</p>
        </td>
        <td>
          <Switch checked={status} disabled={loading} onChange={handleToggle} />
        </td>
        <td>
          <Tooltip title="remove store" placement="bottom">
            <IconButton sx={{ mr: 1.5 }} onClick={() => { toggleDelete(true) }}>
              <Delete sx={{ color: 'red' }} />
            </IconButton>
          </Tooltip>
        </td>
      </tr>
    </>
  )
}

export default function MallStoresView({ visible, stores, deleteStore }) {
  if (!visible) {
    return null
  }
  return (
    <div className="table-container">
      <table className="locations-table">
        <thead>
          <tr>
            <th className="phone-hd">Id</th>
            <th className="name-hd">Name</th>
            <th className="location-hd">Details</th>
            <th className="phone-hd">Phone</th>
            <th className="phone-hd">Status</th>
            <th className="actions-hd">Actions</th>
          </tr>
        </thead>
        <tbody>
          {stores.map((s) => <Store key={s.id} store={s} deleteStore={deleteStore} />)}
        </tbody>
      </table>
    </div>
  )
}
