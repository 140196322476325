/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast'
import { useFormik } from 'formik'
import * as yup from 'yup'
import firebase from '../services/firebase'
import '../styles/auth.css'
import AuthInput from '../components/auth/AuthInput'
import BtnLoader from '../components/spinners/BtnLoader'
import Logo from '../img/logo.png'
import { authenticateUser, setUserInfo } from '../actions/userActions'
import ResetModal from '../components/auth/ResetModal'

const initialValues = {
  email: '',
  password: '',
}
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required('Email is a required field'),
  password: yup
    .string()
    .required('Please enter your password'),
})

function Login({ user, authenticateUser: authenticateUserAction }) {
  const [loading, setLoading] = useState(false)
  const [modalOpen, toggleModal] = useState(false)
  const history = useHistory()

  const handleSubmit = (values) => {
    const {
      email, password,
    } = values
    setLoading(true)
    firebase.auth()
      .signInWithEmailAndPassword(email, password)
      .then((u) => {
        setLoading(false)
        toast.success('Authenticated Successfully.')
        authenticateUserAction(u)
        history.push('/')
      }).catch(() => {
        setLoading(false)
        toast.error('Login error, please confirm email and password then try again.')
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })
  const emailProps = formik.getFieldProps('email')
  const passwordProps = formik.getFieldProps('password')

  if (user?.userToken) {
    return <Redirect to="/" />
  }

  return (
    <div className="auth-page">
      <Toaster />
      <header className="auth-header">
        <div className="brand">
          <img src={Logo} alt="logo" />
        </div>
      </header>
      <div className="auth-holder">
        <div className="auth-intro">
          <h3>Sokolink Dashboard</h3>
          <p>Business Login</p>
        </div>
        <form className="auth-form" onSubmit={formik.handleSubmit}>
          <AuthInput type="email" required label="Email address" placeholder="your@email.com" {...emailProps} />
          {formik.touched.email && formik.errors.email ? (
            <div className="form-error">{formik.errors.email}</div>
          ) : null}
          <AuthInput type="password" required label="Password" placeholder="password" {...passwordProps} />
          {formik.touched.password && formik.errors.password ? (
            <div className="form-error">{formik.errors.password}</div>
          ) : null}
          <button type="submit" disabled={!(formik.isValid && formik.dirty) || loading} className="auth-submit">
            {!loading ? <h5>Login</h5> : null}
            <BtnLoader color="#fff" visible={loading} />
          </button>
          <div className="more">
            <p onClick={() => toggleModal(true)}>Forgot password?</p>
          </div>
        </form>
        <ResetModal visible={modalOpen} close={() => toggleModal(false)} />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  user: state.user.user,
})
export default connect(mapStateToProps, { authenticateUser, setUserInfo })(Login)
