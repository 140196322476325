import React, { useState } from 'react'
import {
  Box, FormControl, InputLabel, MenuItem, Select, TextField,
} from '@mui/material'
import { debounce } from 'lodash'
import CampaignsTable from '../../components/whatsapp/CampaignsTable'
import WhatsappPageHeader from '../../components/whatsapp/WhatsappPageHeader'

export default function WhatsappCampaignsPage() {
  const [name, setName] = useState('')
  const [searchStr, setSearchString] = useState('')
  const [status, setStatus] = useState('all')
  const [newCampaigns, setNewCampaigns] = useState([])

  const debouncedSearch = debounce((searchText) => {
    setSearchString(searchText)
  }, 1000)

  // Function to handle search input change
  const handleSearch = (event) => {
    const searchText = event.target.value
    setName(searchText)
    debouncedSearch(searchText)
  }

  return (
    <div className="page-content">
      <WhatsappPageHeader activeTab="campaigns" onNewCampaign={(c) => { setNewCampaigns([c, ...newCampaigns]) }} />
      <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
        <FormControl sx={{ mr: 3 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={status}
            label="Status"
            onChange={(e) => { setStatus(e.target.value) }}
            type="text"
            size="small"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="sent">Sent</MenuItem>
          </Select>
        </FormControl>
        <TextField
          value={name}
          type="search"
          size="small"
          onChange={handleSearch}
          label="Search name..."
          variant="outlined"
        />
      </Box>
      <CampaignsTable status={status} searchStr={searchStr} newCampaigns={newCampaigns} />
    </div>
  )
}
