import { Add, KeyboardArrowRight, Save } from '@mui/icons-material'
import {
  Box, Grid, Button, DialogActions, FormControl, InputLabel,
  MenuItem, Select, TextField, Paper, Typography,
} from '@mui/material'
import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { connect } from 'react-redux'
import ResponsiveBigModal from '../layout/ResponsiveBigModal'
import SelectStore from '../locations/SelectStore'
import { ADD_SMS_CAMPAIGN, EDIT_SMS_CAMPAIGN } from '../../graphql/smscampaigns'

function Actions({
  step, back, close, loading, disabled,
}) {
  const handleBackClick = () => {
    if (step === 0) { close() } else {
      back()
    }
  }
  return (
    <DialogActions>
      <Button onClick={handleBackClick} sx={{ mr: 2 }}>
        { step === 1 ? 'Back' : 'Cancel' }
      </Button>
      { step === 0
        ? (
          <Button
            type="submit"
            form="save-campaign-form"
            endIcon={<KeyboardArrowRight />}
            variant="contained"
            sx={{ mr: 2 }}
          >
            Continue
          </Button>
        ) : (
          <Button
            type="submit"
            form="save-campaign-form"
            disabled={loading || disabled}
            startIcon={<Save />}
            variant="contained"
            sx={{ mr: 2 }}
          >
            {loading ? 'Saving...' : 'Save Campaign'}
          </Button>
        )}
    </DialogActions>
  )
}

function MessageStep({
  merchant, senderName, message, onMessageChange,
}) {
  const finalText = `${senderName}: ${message}`
  const storeLink = merchant?.customDomain ? `https://${merchant?.customDomain}` : `https://${merchant?.subdomain}.sokolink.com/`
  return (
    <Grid container spacing={1} alignItems="start" justifyContent="space-evenly">
      <Grid item flex={1}>
        <TextField type="text" size="small" required value={message} onChange={(e) => { onMessageChange(e.target.value) }} label="Message" variant="outlined" fullWidth multiline rows={6} />
        <Button
          size="small"
          startIcon={<Add />}
          sx={{ textTransform: 'capitalize', mt: 1 }}
          onClick={() => {
            onMessageChange(`${message}\n${storeLink}`)
          }}
        >
          Add Store Link
        </Button>
      </Grid>
      <Grid item flex={1}>
        <Paper variant="outlined" elevation={3} style={{ padding: '16px', minHeight: '155px' }}>
          <Typography
            variant="body2"
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: finalText.replace(/\n/g, '<br />') }}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

function SaveCampaignDialog({
  merchant, close, campaign, onUpdateCampaign, onNewCampaign,
}) {
  const [step, setStep] = useState(0)
  const [name, setName] = useState(campaign?.name || '')
  const [senderName, setSenderName] = useState(campaign?.senderName || '')
  const [targetCustomers, setTargetCustomers] = useState(campaign?.targetCustomers || 'all')
  const [store, setStore] = useState(campaign?.storeId || 'all')
  const [limit, setLimit] = useState(campaign?.customerLimit || '')
  const [offset, setOffset] = useState(campaign?.customerOffset || 0)
  const [order, setOrder] = useState(campaign?.sortOrder || '')
  const [sort, setSort] = useState(campaign?.sortField || '')
  const [message, setMessage] = useState(campaign?.message || '')

  const [addCampaignReq, { loading }] = useMutation(ADD_SMS_CAMPAIGN, {
    onError: () => { toast.error('Error creating campaign') },
    onCompleted: (data) => {
      toast.success('Campaign created successfully')
      onNewCampaign(data.createSmsCampaign)
      close()
    },
  })

  const [updateCampaignReq, { loading: updating }] = useMutation(EDIT_SMS_CAMPAIGN, {
    onError: () => { toast.error('Error updating campaign.') },
    onCompleted: (data) => {
      toast.success('Campaign updated successfully')
      onUpdateCampaign(data.updateSmsCampaign)
      close()
    },
  })
  const onSave = (e) => {
    e.preventDefault()
    if (step === 0) {
      setStep(1)
      return
    }
    if (campaign) {
      updateCampaignReq({
        variables: {
          id: campaign.id,
          name,
          senderName,
          storeId: store === 'all' ? null : store,
          targetCustomers,
          customerLimit: parseInt(limit, 10) || null,
          customerOffset: parseInt(offset, 10) || null,
          sortField: sort,
          sortOrder: order,
          message,
        },
      })
    } else {
      addCampaignReq({
        variables: {
          name,
          senderName,
          storeId: store === 'all' ? null : store,
          targetCustomers,
          customerLimit: parseInt(limit, 10) || null,
          customerOffset: parseInt(offset, 10) || null,
          sortField: sort,
          sortOrder: order,
          message,
        },
      })
    }
  }
  return (
    <ResponsiveBigModal
      title={campaign ? 'Save Campaign' : 'Create Campaign'}
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions disabled={campaign?.status === 'sent'} loading={loading || updating} next={() => { setStep(1) }} close={close} step={step} back={() => { setStep(0) }} />}
    >
      <Box component="form" id="save-campaign-form" onSubmit={onSave}>
        { step === 0
          ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Box sx={{ flex: 0.45 }}>
                <TextField type="text" size="small" required value={name} onChange={(e) => { setName(e.target.value) }} label="Campaign Name" variant="outlined" fullWidth />
                <SelectStore fullWidth sx={{ my: 3 }} hasAll value={store} changeHandler={(e) => { setStore(e.target.value) }} />
                <TextField type="number" size="small" fullWidth value={limit} onChange={(e) => { setLimit(e.target.value) }} label="Customers Limit" variant="outlined" />
                <FormControl fullWidth sx={{ mt: 3 }}>
                  <InputLabel sx={{ mt: -0.5 }}>Sort Field</InputLabel>
                  <Select
                    value={sort}
                    label="Sort Field"
                    onChange={(e) => { setSort(e.target.value) }}
                    type="text"
                    size="small"
                    required={!!order}
                  >
                    <MenuItem value="">No Sorting</MenuItem>
                    <MenuItem value="created_on">Date Created</MenuItem>
                    <MenuItem value="num_orders">Number Of Orders</MenuItem>
                    <MenuItem value="amount_spent">Amount Spent</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ flex: 0.45 }}>
                <TextField type="text" size="small" required value={senderName} onChange={(e) => { setSenderName(e.target.value) }} label="Sender Name" variant="outlined" fullWidth />
                <FormControl fullWidth sx={{ my: 3 }}>
                  <InputLabel>Target Customers</InputLabel>
                  <Select
                    value={targetCustomers}
                    label="Target Customers"
                    onChange={(e) => { setTargetCustomers(e.target.value) }}
                    type="text"
                    size="small"
                  >
                    <MenuItem value="all">All Customers</MenuItem>
                    <MenuItem value="new">New Customers</MenuItem>
                    <MenuItem value="repeat">Repeat Customers</MenuItem>
                  </Select>
                </FormControl>
                <TextField type="number" size="small" fullWidth value={offset} onChange={(e) => { setOffset(e.target.value) }} label="Customers Offset" variant="outlined" />
                <FormControl fullWidth sx={{ mt: 3 }}>
                  <InputLabel sx={{ mt: -0.5 }}>Sort Order</InputLabel>
                  <Select
                    value={order}
                    label="Sort Order"
                    onChange={(e) => { setOrder(e.target.value) }}
                    type="text"
                    size="small"
                    required={!!sort}
                  >
                    <MenuItem value="">No Order</MenuItem>
                    <MenuItem value="ASC">Ascending</MenuItem>
                    <MenuItem value="DESC">Descending</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          ) : (
            <MessageStep merchant={merchant} senderName={senderName} message={message} onMessageChange={(m) => { setMessage(m) }} />
          )}
      </Box>
    </ResponsiveBigModal>
  )
}

const mapStateToProps = (state) => ({
  merchant: state.merchant.merchantInfo,
})

export default connect(mapStateToProps, { })(SaveCampaignDialog)
