import React, { useEffect, useState } from 'react'
import CustomersIcon from '@mui/icons-material/PeopleOutline'
import CartIcon from '@mui/icons-material/ShoppingCart'
import MoneyIcon from '@mui/icons-material/MonetizationOn'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import './styles/Analytics.css'
import { useLazyQuery } from '@apollo/client'
import { Box, Divider, Typography } from '@mui/material'
import { formatMoney } from '../../utils/formatter'
import ErrorLoading from '../layout/ErrorLoading'
import { ANALYTICS } from '../../graphql/merchant'
import OrderTypePieChart from './OrderTypePie'
import CustomerDoughnut from './CustomerDoughnut'
import OrderTrendLineChart from './OrdersTrend'
import ItemAnalysisBarChart from './ItemAnalysisBar'
import LoadingSkeleton from './Loading'

export default function OrderAnalytics({ period, storeId }) {
  const [analytics, setAnalytics] = useState(null)
  const [error, setError] = useState(null)

  const handleResponse = (data) => {
    setError(null)
    if (data.analytics) {
      setAnalytics(data.analytics)
    }
  }
  const [getAnalytics, { loading }] = useLazyQuery(ANALYTICS, {
    variables: {
      period,
      storeId: storeId !== 'all' ? storeId : null,
    },
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })

  useEffect(() => {
    getAnalytics()
  }, [period])

  if (loading) return <LoadingSkeleton />
  if (error || !analytics) return <ErrorLoading visible={error && !loading} reload={getAnalytics} />
  const {
    totalOrders, deliveryOrders, pickupOrders, dineinOrders, takeawayOrders,
    newCustomers, repeatCustomers, revenue, ordersTrend, itemAnalysis,
  } = analytics
  return (
    <Box>
      <Box sx={{
        width: '100%', p: '10px', mt: '10px', border: '1px solid #ddd',
      }}
      >
        <Typography variant="h5" mb="10px">Order & Revenue Trend</Typography>
        <Divider sx={{ marginX: '-10px' }} />
        <OrderTrendLineChart data={ordersTrend} />
      </Box>
      <Box sx={{
        width: '100%', p: '10px', mt: '10px', border: '1px solid #ddd',
      }}
      >
        <Typography variant="h5" mb="10px">Sales</Typography>
        <Divider sx={{ marginX: '-10px' }} />
        <div className="analytics-container">
          <div className="analytics-row">
            <div className="analytics-holder">
              <div className="icon-holder">
                <MoneyIcon style={{ color: '#ff000093', fontSize: '50' }} />
              </div>
              <div className="data">
                <h2>Total Amount</h2>
                <h1>{formatMoney(revenue)}</h1>
              </div>
            </div>
            <div className="analytics-holder">
              <div className="icon-holder">
                <CartIcon style={{ color: '#ff000093', fontSize: '50' }} />
              </div>
              <div className="data">
                <h2>Total Orders</h2>
                <h1>{totalOrders}</h1>
              </div>
            </div>
          </div>
          <Box className="analytics-row" sx={{ }}>
            <OrderTypePieChart data={{
              deliveryOrders, pickupOrders, dineinOrders, takeawayOrders,
            }}
            />
          </Box>
        </div>
      </Box>
      <Box sx={{
        width: '100%', p: '10px', mt: '10px', border: '1px solid #ddd',
      }}
      >
        <Typography variant="h5" mb="10px">Customers</Typography>
        <Divider sx={{ marginX: '-10px' }} />
        <div className="analytics-container">
          <div className="analytics-group">
            <Box className="analytics-row" sx={{ ml: '-20px' }}>
              <CustomerDoughnut data={{ newCustomers, repeatCustomers }} />
            </Box>
            <div className="analytics-row">
              <div className="analytics-holder">
                <div className="icon-holder">
                  <CustomersIcon style={{ color: '#ff000093', fontSize: '50' }} />
                </div>
                <div className="data">
                  <h2>New Customers</h2>
                  <h1>{newCustomers}</h1>
                </div>
              </div>
              <div className="analytics-holder">
                <div className="icon-holder">
                  <ConnectWithoutContactIcon style={{ color: '#ff000093', fontSize: '50' }} />
                </div>
                <div className="data">
                  <h2>Repeat Customers</h2>
                  <h1>{repeatCustomers}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
      <Box sx={{
        width: '100%', p: '10px', mt: '10px', border: '1px solid #ddd',
      }}
      >
        <Typography variant="h5" mb="10px">Item Analysis</Typography>
        <Divider sx={{ marginX: '-10px' }} />
        <div className="analytics-container">
          <Box sx={{ flex: 4, width: '100%', border: '1px solid #ddd' }}>
            <Box sx={{ py: '10px', borderBottom: '1px solid #ddd' }}>
              <Typography textAlign="center">Top Selling Items</Typography>
            </Box>
            <Box sx={{ display: 'flex', py: 1, borderBottom: '1px solid #ddd' }}>
              <Box sx={{ flex: 3 }}><Typography textAlign="center">Count</Typography></Box>
              <Box sx={{ flex: 7 }}><Typography textAlign="start">Name</Typography></Box>
            </Box>
            {
              itemAnalysis.map((i) => (
                <Box key={i.name} sx={{ display: 'flex', py: 1, borderBottom: '1px solid #ddd' }}>
                  <Box sx={{ flex: 3 }}><Typography textAlign="center" fontSize={12}>{i.count}</Typography></Box>
                  <Box sx={{ flex: 7 }}><Typography textAlign="start" fontSize={12}>{i.name}</Typography></Box>
                </Box>
              ))
            }
          </Box>
          <Box sx={{ flex: 6 }}>
            <ItemAnalysisBarChart data={itemAnalysis} />
          </Box>
        </div>
      </Box>
    </Box>
  )
}
