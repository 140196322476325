import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import Papa from 'papaparse'
import FileSaver from 'file-saver'
import toast from 'react-hot-toast'
import { TextField } from '@mui/material'
import { debounce } from 'lodash'
import ErrorLoading from '../components/layout/ErrorLoading'
import CenterLoader from '../components/spinners/CenterLoader'
import FilterForm from '../components/layout/FilterForm'
import Pagination from '../components/layout/Pagination'
import { GET_PAYMENTS } from '../graphql/transactions'
import FilterStatus from '../components/transactions/FilterStatus'
import TransactionsView from '../components/transactions/TransactionsView'
import NoData from '../components/layout/NoData'
import { formatDownloadPayments } from '../utils/formatter'
import { TRANSACTIONS_DOWNLOAD_LIMIT } from '../utils/limits'

export default function Payments() {
  const [transactions, setTransactions] = useState(null)
  const limit = 10
  const [page, setPage] = useState(1)
  const [error, setError] = useState(null)
  const start = page === 1 ? 0 : (page - 1) * limit
  const [searchValue, setSearchValue] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [status, setStatus] = useState('all')

  const debouncedSearch = debounce((searchText) => {
    setSearchQuery(searchText)
  }, 1000)

  const handleResponse = (data) => {
    setError(null)
    setTransactions(data.payments)
  }
  const [getTransactions, { loading }] = useLazyQuery(GET_PAYMENTS, {
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  const getTransactionsReq = () => {
    getTransactions({
      variables: {
        limit,
        offset: start,
        transactionId: searchQuery?.toLocaleUpperCase() || null,
        status: status === 'all' ? null : status,
      },
    })
  }
  const handleDownloadResponse = (data) => {
    const downloadData = data.payments || []
    const formattedData = formatDownloadPayments(downloadData)
    const dataStr = JSON.stringify(formattedData)
    const csv = Papa.unparse(dataStr)
    const blob = new Blob([decodeURIComponent(encodeURI(csv))], {
      type: 'text/csv;charset=utf-8;',
    })
    toast.dismiss('downloadspinner')
    toast.success('Data downloaded')
    FileSaver.saveAs(blob, 'payments.csv')
  }
  const [getDownloadData] = useLazyQuery(GET_PAYMENTS, {
    fetchPolicy: 'cache-and-network',
    onError: () => { toast.dismiss('downloadspinner'); toast.error('Error downloading data') },
    onCompleted: handleDownloadResponse,
  })

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextPage = () => {
    if (!(transactions.length < limit)) {
      setPage(page + 1)
    }
  }

  const handleSearch = (event) => {
    const searchText = event.target.value
    setSearchValue(searchText)
    if (!searchText) {
      setSearchQuery('')
    } else {
      debouncedSearch(searchText)
    }
  }

  const downloadPayments = (period) => {
    toast.loading('Downloading data...', { id: 'downloadspinner' })
    getDownloadData(
      {
        variables: {
          limit: TRANSACTIONS_DOWNLOAD_LIMIT,
          offset: 0,
          status: status === 'all' ? null : status,
          period,
        },
      },
    )
  }

  useEffect(() => {
    getTransactionsReq()
  }, [page, status, searchQuery])

  return (
    <div className="page-content">
      <div className="page-tittle">Payment Transactions</div>
      <FilterForm download={downloadPayments} defaultRangeDays={30}>
        <FilterStatus value={status} label="Status:" changeHandler={(e) => setStatus(e.target.value)} />
        <TextField type="search" size="small" value={searchValue} onChange={handleSearch} label="Transaction Id" variant="outlined" />
      </FilterForm>
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getTransactions} />
      <TransactionsView visible={!loading && !error && transactions} transactions={transactions} />
      <NoData visible={!loading && !error && !transactions?.length} />
      <Pagination visible={!loading && !error && transactions?.length} page={page} next={nextPage} prev={prevPage} />
    </div>
  )
}
