import React, { useState, useEffect, forwardRef } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import Switch from 'react-switch'
import { Box, CircularProgress, IconButton } from '@mui/material'
import { Edit, Delete } from '@mui/icons-material'
import { toast } from 'react-hot-toast'
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'
import {
  UPDATE_PRODUCT, PRODUCTS, DELETE_PRODUCT, SORT_PRODUCTS,
} from '../../graphql/products'
import './styles/ProductsView.css'
import { formatMoney } from '../../utils/formatter'
import Pagination from '../layout/Pagination'
import NoData from '../layout/NoData'
import SaveProductModal from './SaveProductModal'
import DeleteDialog from '../shared/DeleteDialog'
import ErrorLoading from '../layout/ErrorLoading'
import { useProducts } from './context/products'

const Product = forwardRef(({ product }, ref) => {
  const { deleteProduct } = useProducts()
  const [editOpen, toggleEdit] = useState(false)
  const [deleteOpen, toggleDelete] = useState(false)
  const [inStock, toggleInstock] = useState(product.inStock)
  const {
    name, images, price, discountPrice,
  } = product

  const [updateProductReq, { loading }] = useMutation(UPDATE_PRODUCT, { onCompleted: () => { toggleInstock(!inStock) }, onError: () => {} })

  const [deleteProductReq] = useMutation(DELETE_PRODUCT, {
    onError: () => {
      toast.error('error removing product')
    },
    onCompleted: () => {
      toast.success(`${name} deleted successfully.`)
      deleteProduct(product.id)
    },
  })

  const handleDelete = () => {
    deleteProductReq({
      variables: {
        id: product.id,
      },
    })
    toggleDelete(false)
  }

  const handleToggle = () => {
    updateProductReq({
      variables: {
        id: `${product.id}`,
        inStock: !inStock,
      },
    })
  }

  return (
    <>
      { editOpen ? <SaveProductModal product={product} close={() => { toggleEdit(false) }} /> : null }
      { deleteOpen ? <DeleteDialog visible={deleteOpen} close={() => { toggleDelete(false) }} title="Delete Product" msg={`Are you sure you want to delete ${name}.`} onDelete={handleDelete} /> : null }
      <tr ref={ref}>
        <td style={{ minWidth: '230px' }}>
          <p>{name}</p>
        </td>
        <td style={{ minWidth: '100px', alignContent: 'center' }}>
          <img
            style={{
              width: '70px', height: '40px', margin: '5px 0', borderRadius: '5px',
            }}
            src={images[0] || 'https://res.cloudinary.com/sokolink-co/image/upload/v1638276026/thumbnail-image-vector-graphic-vector-id1147544807_c8gply.jpg'}
            alt="product"
          />
        </td>
        <td style={{ minWidth: '100px' }}>
          <Switch onChange={handleToggle} checked={!!inStock} disabled={loading} />
        </td>
        <td style={{ minWidth: '100px' }}>
          <p>{formatMoney(price)}</p>
        </td>
        <td style={{ minWidth: '100px' }}>
          <p>{formatMoney(discountPrice || 0)}</p>
        </td>
        <td style={{ minWidth: '200px' }}>
          <IconButton sx={{ mr: 5 }} onClick={() => { toggleEdit(true) }}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => { toggleDelete(true) }}>
            <Delete sx={{ color: 'red' }} />
          </IconButton>
        </td>
      </tr>
    </>
  )
})

export default function ProductsView({ category }) {
  const { products, addProducts } = useProducts()
  const limit = 500
  const [page, setPage] = useState(1)

  const start = page === 1 ? 0 : (page - 1) * limit
  const { loading, error } = useQuery(PRODUCTS, {
    variables: {
      limit,
      offset: start,
      categoryId: category?.id,
    },
    fetchPolicy: 'cache-and-network',
    onError: () => { },
    onCompleted: (data) => { addProducts(data.products.products) },
  })
  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextPage = () => {
    if (!(products.length < limit)) {
      setPage(page + 1)
    }
  }
  const [sortProductsReq] = useMutation(SORT_PRODUCTS, {
    variables: {
      ids: products.map((c) => (c.id)),
    },
    onError: () => { },
    onCompleted: () => { },
  })

  const onSortEnd = (oldIndex, newIndex) => {
    addProducts((array) => arrayMoveImmutable(array, oldIndex, newIndex))
    sortProductsReq()
  }

  useEffect(() => {
    setPage(1)
  }, [category])

  if (error) return <ErrorLoading visible reload={() => { }} />

  return (
    <Box sx={{
      height: 'calc(100vh - 165px)', overflowY: 'auto', width: 'calc(100% - 200px)', paddingX: 2,
    }}
    >
      {loading ? (
        <Box sx={{
          width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <CircularProgress size={20} />
        </Box>
      ) : null}
      {!loading && !error ? (
        <div className="table-container">
          <table className="products-table">
            <thead>
              <tr>
                <th className="name-hd">Name</th>
                <th className="image-hd">Image</th>
                <th className="stock-hd">In Stock</th>
                <th className="price-hd">Price</th>
                <th className="discount-hd">Discount</th>
                <th className="actions-hd">Actions</th>
              </tr>
            </thead>
            <SortableList
              onSortEnd={onSortEnd}
              as="tbody"
            >
              {products.map((p) => (
                <SortableItem key={p.id}>
                  <Product ref={p.id} product={p} />
                </SortableItem>
              ))}
            </SortableList>
          </table>
        </div>
      ) : null}
      <Pagination visible={!loading && !error && (products?.length >= limit)} page={page} next={nextPage} prev={prevPage} />
      <NoData visible={!loading && !error && !products?.length} />
    </Box>
  )
}
