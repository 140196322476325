import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import Papa from 'papaparse'
import FileSaver from 'file-saver'
import toast from 'react-hot-toast'
import { debounce } from 'lodash'
import {
  Box, Button, Divider, TextField, Typography,
} from '@mui/material'
import ErrorLoading from '../components/layout/ErrorLoading'
import CenterLoader from '../components/spinners/CenterLoader'
import '../styles/stores.css'
import { CUSTOMERS } from '../graphql/customers'
import Pagination from '../components/layout/Pagination'
import FilterForm from '../components/layout/FilterForm'
import CustomersView from '../components/customers/CustomersView'
import NoData from '../components/layout/NoData'
import { formatDownloadCustomers } from '../utils/formatter'
import UploadCustomers from '../components/customers/UploadCustomers'
import CustomersSummary from '../components/customers/CustomersSummary'
import SelectStore from '../components/locations/SelectStore'
import { CUSTOMERS_DOWNLOAD_LIMIT } from '../utils/limits'
import SaveCustomer from '../components/customers/SaveCustomer'

export default function CustomersPage() {
  const limit = 10
  const [addOpen, toggleAdd] = useState(false)
  const [uploadOpen, toggleUpload] = useState(false)
  const [customers, setCustomers] = useState(null)
  const [page, setPage] = useState(1)
  const [error, setError] = useState(null)
  const start = page === 1 ? 0 : (page - 1) * limit
  const [storeId, setStoreId] = useState('all')
  const [searchValue, setSearchValue] = useState('')
  const [searchQuery, setSearchQuery] = useState('')

  const debouncedSearch = debounce((searchText) => {
    setSearchQuery(searchText)
  }, 1000)

  const handleResponse = (data) => {
    setError(null)
    setCustomers(data.customers)
  }
  const [getCustomers, { loading }] = useLazyQuery(CUSTOMERS, {
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  const getCustomersReq = () => {
    getCustomers({
      variables: {
        limit,
        offset: start,
        storeId: storeId !== 'all' ? storeId : null,
        searchQuery,
      },
    })
  }
  const handleDownloadResponse = (data) => {
    const downloadData = data.customers || []
    const formattedData = formatDownloadCustomers(downloadData)
    const dataStr = JSON.stringify(formattedData)
    const csv = Papa.unparse(dataStr)
    const blob = new Blob([decodeURIComponent(encodeURI(csv))], {
      type: 'text/csv;charset=utf-8;',
    })
    toast.dismiss('downloadspinner')
    toast.success('Data downloaded')
    FileSaver.saveAs(blob, 'customers.csv')
  }
  const [getDownloadData] = useLazyQuery(CUSTOMERS, {
    fetchPolicy: 'cache-and-network',
    onError: () => { toast.dismiss('downloadspinner'); toast.error('Error downloading data') },
    onCompleted: handleDownloadResponse,
  })

  const handleSearch = (event) => {
    const searchText = event.target.value
    setSearchValue(searchText)
    if (!searchText) {
      setSearchQuery('')
    } else {
      debouncedSearch(searchText)
    }
  }

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextPage = () => {
    if (!(customers.length < limit)) {
      setPage(page + 1)
    }
  }

  const download = (period) => {
    toast.loading('Downloading data...', { id: 'downloadspinner' })
    getDownloadData(
      {
        variables: {
          limit: CUSTOMERS_DOWNLOAD_LIMIT,
          offset: 0,
          storeId: storeId !== 'all' ? storeId : null,
          period,
        },
      },
    )
  }

  const onAdd = (newCustomers) => {
    const customer = newCustomers[0]
    if (!customers.find((c) => (c.id === customer.id))) {
      setCustomers([customer, ...customers])
    }
    setCustomers(customers.map((c) => {
      if (c.id === customer.id) {
        return customer
      }
      return c
    }))
  }

  useEffect(() => {
    getCustomersReq()
  }, [page, storeId, searchQuery])

  return (
    <div className="page-content">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography>Business Customers</Typography>
        <Box>
          <Button variant="outlined" onClick={() => { toggleAdd(true) }} sx={{ mr: 1 }}>Add Customer</Button>
          <Button variant="contained" onClick={() => { toggleUpload(true) }}>Upload Customers</Button>
        </Box>
      </Box>
      { uploadOpen ? <UploadCustomers close={() => { toggleUpload(false) }} /> : null }
      { addOpen ? <SaveCustomer close={() => { toggleAdd(false) }} add={onAdd} /> : null }
      <Divider sx={{ marginX: '-20px', mt: 1 }} />
      <FilterForm download={download} defaultRangeDays={365}>
        <SelectStore noLabel hasAll value={storeId} changeHandler={(e) => { setStoreId(e.target.value) }} sx={{ width: 'fit-content', outline: 'none' }} />
        <TextField type="search" size="small" value={searchValue} onChange={handleSearch} label="Search Customer" variant="outlined" sx={{ ml: 2 }} />
      </FilterForm>
      <CustomersSummary storeId={storeId} />
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getCustomers} />
      <CustomersView visible={!loading && !error && customers} customers={customers} deleteCustomer={(id) => setCustomers(customers.filter((el) => el.id !== id))} />
      <NoData visible={!loading && !error && !customers?.length} />
      <Pagination visible={!loading && !error && (customers ? customers.length : false)} page={page} next={nextPage} prev={prevPage} />
    </div>
  )
}
