import React, { createContext, useContext, useState } from 'react'

const ProductsContext = createContext()

export const useProducts = () => useContext(ProductsContext)

export const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState([])
  const [activeCategory, setActiveCategory] = useState(null)

  const addProducts = (newProducts) => {
    setProducts(newProducts)
  }

  const addProduct = (product) => {
    if (product.category?.id === activeCategory.id) {
      setProducts((prevProducts) => [...prevProducts, product])
    }
  }

  const updateProduct = (product) => {
    setProducts((prevProducts) => prevProducts.map((p) => (p.id === product.id ? product : p)))
  }

  const deleteProduct = (id) => {
    setProducts((prevProducts) => (prevProducts.filter((el) => el.id !== id)))
  }

  const changeCategory = (category) => {
    setActiveCategory(category)
  }

  return (
    <ProductsContext.Provider value={{
      products, addProducts, addProduct, updateProduct, deleteProduct, changeCategory,
    }}
    >
      {children}
    </ProductsContext.Provider>
  )
}
