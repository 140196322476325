import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'

import FormControl from '@mui/material/FormControl'
import { GET_CATEGORIES } from '../../graphql/products'

export default function CategorySelect({
  changeHandler, value, required,
}) {
  const { catalogueId } = useParams()
  const [categories, setCategories] = useState([])
  const { loading } = useQuery(GET_CATEGORIES, {
    variables: {
      catalogueId,
    },
    fetchPolicy: 'network-only',
    onError: () => {},
    onCompleted: (data) => { setCategories(data.categories) },
  })
  return (
    <FormControl sx={{ width: '100%', marginY: 2 }}>
      <InputLabel>Category</InputLabel>
      <Select
        required={required}
        value={value}
        onChange={changeHandler}
        type="text"
        label="Category"
      >
        {loading ? <MenuItem value="">loading categories...</MenuItem> : null}
        {categories.map((c) => (<MenuItem key={c.id} value={c.id}>{c.category}</MenuItem>))}
      </Select>
    </FormControl>
  )
}
