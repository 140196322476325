import React, { forwardRef, useState } from 'react'
import {
  Delete, Edit,
} from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'
import { Link } from 'react-router-dom'
import AddCatalogue from './AddCatalogue'
import DeleteDialog from '../shared/DeleteDialog'
import { DELETE_CATALOGUE, SORT_CATALOGUES } from '../../graphql/products'

const Catalogue = forwardRef(({ catalogue: serverCatalogue, deleteCatalogue }, ref) => {
  const [catalogue, setCatalogue] = useState(serverCatalogue)
  const {
    id, title, storeIds, categoriesCount, productsCount,
  } = catalogue
  const deleteMsg = `Are you sure you want to delete catalogue ${title} ?`
  const [editOpen, toggleEdit] = useState(false)
  const [deleteOpen, toggleDelete] = useState(false)

  const updateCatalogue = (c) => {
    setCatalogue(c)
  }

  const [deleteCatalogueReq] = useMutation(DELETE_CATALOGUE, {
    onError: () => { toast.error(`Error removing catalogue ${title}.`) },
    onCompleted: () => { toast.success(`Catalogue ${title} successfully`); deleteCatalogue(id) },
  })
  const handleDelete = () => {
    deleteCatalogueReq({
      variables: {
        id,
      },
    })
    toggleDelete(false)
  }
  return (
    <>
      { editOpen ? <AddCatalogue visible close={() => { toggleEdit(false) }} catalogue={catalogue} update={updateCatalogue} /> : null }
      <DeleteDialog visible={deleteOpen} close={() => { toggleDelete(false) }} title="Delete Catalogue" msg={deleteMsg} onDelete={handleDelete} />
      <tr style={{ height: '50px' }} ref={ref}>
        <td style={{ minWidth: '200px' }}>
          <Link to={`/business/catalogues/${id}`}>
            <u>{title}</u>
          </Link>
        </td>
        <td style={{ minWidth: '150px' }}>{storeIds.length}</td>
        <td style={{ minWidth: '150px' }}>{categoriesCount}</td>
        <td style={{ minWidth: '150px' }}>{productsCount}</td>
        <td style={{ minWidth: '200px' }}>
          <IconButton sx={{ mr: 1.5 }} onClick={() => { toggleEdit(true) }}>
            <Edit />
          </IconButton>
          <IconButton sx={{ mr: 1.5, color: 'red' }} onClick={() => { toggleDelete(true) }}>
            <Delete />
          </IconButton>
        </td>
      </tr>
    </>
  )
})

export default function CataloguesView({ catalogues, deleteCatalogue }) {
  const [sortedCatalogues, setCatalogues] = useState(catalogues)

  const [sortCatalogueReq] = useMutation(SORT_CATALOGUES, {
    variables: {
      ids: sortedCatalogues.map((c) => (c.id)),
    },
    onError: () => { },
    onCompleted: () => { },
  })

  const onSortEnd = (oldIndex, newIndex) => {
    setCatalogues((array) => arrayMoveImmutable(array, oldIndex, newIndex))
    sortCatalogueReq()
  }

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Stores</th>
            <th>No. Of Categories</th>
            <th>No. Of Products</th>
            <th>Actions</th>
          </tr>
        </thead>
        <SortableList
          onSortEnd={onSortEnd}
          as="tbody"
        >
          {sortedCatalogues.map((c) => (
            <SortableItem key={c.id}>
              <Catalogue ref={c.id} catalogue={c} deleteCatalogue={deleteCatalogue} />
            </SortableItem>
          ))}
        </SortableList>
      </table>
    </div>
  )
}
