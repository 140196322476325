import React, { createContext, useContext, useState } from 'react'

const CustomizationsContext = createContext()

export const useCustomizations = () => useContext(CustomizationsContext)

export const CustomizationsProvider = ({ children }) => {
  const [customizations, setCustomizations] = useState([])

  const addCustomizations = (newCustomizations) => {
    setCustomizations([...newCustomizations])
  }

  const addCustomization = (newCustomization) => {
    setCustomizations((prevCustomizations) => [newCustomization, ...prevCustomizations])
  }

  const updateCustomization = (customization) => {
    setCustomizations((prevCustomizations) => prevCustomizations.map((c) => (c.id === customization.id ? customization : c)))
  }

  const deleteCustomization = (id) => {
    setCustomizations((prevCustomizations) => (prevCustomizations.filter((el) => el.id !== id)))
  }

  return (
    <CustomizationsContext.Provider value={{
      customizations, addCustomizations, addCustomization, updateCustomization, deleteCustomization,
    }}
    >
      {children}
    </CustomizationsContext.Provider>
  )
}
