import React from 'react'
import './styles/ErrorLoading.css'
import { Info } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'

const ErrorLoading = ({ visible, reload, fullHeight }) => {
  if (!visible) return null
  return (
    <div className={`error-loading ${fullHeight ? 'full-height' : null}`}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Info sx={{ color: 'red' }} />
        <Typography>Oops! An error occurred.</Typography>
        <Button onClick={reload}>Retry</Button>
      </Box>
    </div>
  )
}

export default ErrorLoading
