import { useLazyQuery } from '@apollo/client'
import {
  Box, Skeleton, Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import ErrorLoading from '../layout/ErrorLoading'
import { SUMMARY } from '../../graphql/customers'

const LoadingSkeleton = () => (
  <Skeleton
    sx={{
      height: '80px', borderRadius: 2, my: 1,
    }}
    variant="rectangular"
    width="100%"
    animation="pulse"
  />
)

export default function CustomersSummary({ storeId }) {
  const [summary, setSummary] = useState(null)
  const [error, setError] = useState(null)

  const handleResponse = (data) => {
    setError(null)
    if (data.customersSummary) {
      setSummary(data.customersSummary)
    }
  }
  const [getSummary, { loading }] = useLazyQuery(SUMMARY, {
    variables: {
      storeId: storeId !== 'all' ? storeId : null,
    },
    fetchPolicy: 'cache-first',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })

  useEffect(() => {
    getSummary()
  }, [storeId])
  if (loading) return <LoadingSkeleton />
  if (error || !summary) return <ErrorLoading fullHeight={false} visible={error && !loading} reload={getSummary} />
  const {
    total, verified, unverified, withConsent, withoutConsent,
  } = summary
  return (
    <Box sx={{ mb: 0.5, mt: -1 }}>
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', py: 1, overflowX: 'auto',
      }}
      >
        <Box sx={{
          flex: 0.19, textAlign: 'center', p: 1, mr: 1, border: '1px solid #ddd', borderRadius: 1,
        }}
        >
          <Typography fontSize={20}>Total Customers</Typography>
          <Typography>{total}</Typography>
        </Box>
        <Box sx={{
          flex: 0.19, textAlign: 'center', p: 1, mr: 1, border: '1px solid #ddd', borderRadius: 1,
        }}
        >
          <Typography fontSize={20}>Verified Customers</Typography>
          <Typography>{verified}</Typography>
        </Box>
        <Box sx={{
          flex: 0.19, textAlign: 'center', p: 1, mr: 1, border: '1px solid #ddd', borderRadius: 1,
        }}
        >
          <Typography fontSize={20}>Unverified Customers</Typography>
          <Typography>{unverified}</Typography>
        </Box>
        <Box sx={{
          flex: 0.19, textAlign: 'center', p: 1, mr: 1, border: '1px solid #ddd', borderRadius: 1,
        }}
        >
          <Typography fontSize={20}>With Consent</Typography>
          <Typography>{withConsent}</Typography>
        </Box>
        <Box sx={{
          flex: 0.19, textAlign: 'center', p: 1, mr: 1, border: '1px solid #ddd', borderRadius: 1,
        }}
        >
          <Typography fontSize={20}>Without Consent</Typography>
          <Typography>{withoutConsent}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
