import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/material'

export default function AppLoading() {
  return (
    <Box sx={{
      display: 'flex', width: '100%', height: '100vh', alignItems: 'center', justifyContent: 'center', background: '#e1e1e1',
    }}
    >
      <CircularProgress />
    </Box>
  )
}
