export function createStoreLink(businessName) { return businessName.replace(/ /g, '-').toLowerCase() }

export function formatDiscount(discount) {
  const { discountPrice, listPrice } = discount
  const percentageDiscount = Math.ceil(((listPrice - discountPrice) / listPrice) * 100)
  return `${percentageDiscount} %`
}

export function formatDate(t) {
  const date = new Date(parseInt(t, 10))
  const day = date.toDateString()
  const time = `${date.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hourCycle: 'h24' })}`
  return `${day} ${time}`
}

export function removeTimeOffset(dateString) {
  const offsetIndex = dateString.indexOf('+') !== -1 ? dateString.indexOf('+') : dateString.indexOf('-')
  return dateString.substring(0, offsetIndex)
}

export function formatShortDate(t) {
  const date = new Date(t)
  const day = date.toDateString()
  return day
}

export function formatMoney(amount) { return (`Ksh ${amount?.toLocaleString()}`) }

export function formatGraphqlError(e, defaultMsg) {
  try {
    const errors = { ...e }
    const errorsArr = errors.graphQLErrors
    const firstObj = errorsArr[0]
    const msg = firstObj.message
    return msg
  } catch (error) {
    return defaultMsg
  }
}

export function formatWhatsappNumber(phone) {
  if (!phone) { return null }
  let formattedNumber
  if (phone.length === 10) {
    formattedNumber = `254${phone.slice(1)}`
  } else if (phone.length === 12) {
    formattedNumber = phone
  } else if (phone.length > 10 && phone.length < 12) {
    formattedNumber = `254${phone.slice(phone.length - 9)}`
  } else if (phone.length === 13) {
    formattedNumber = `${phone.slice(1)}`
  } else {
    formattedNumber = null
  }
  return formattedNumber
}

export function formatDownloadOrders(orders) {
  return orders.map((o) => {
    const date = new Date(parseInt(o.postedOn, 10))
    return {
      order_no: o.orderNo,
      order_type: o.orderType,
      status: o.orderStatus,
      customer_name: o.customer?.name,
      customer_phone: o.customer?.phone,
      order_amount: o.amount,
      delivery_fees: o.deliveryFees,
      total: o.total,
      notes: o.orderDesc,
      delivery_type: o.deliveryType,
      delivery_location: o.deliveryLocation?.name,
      date,
    }
  })
}

export function formatDownloadPayments(transactions) {
  return transactions.map((t) => {
    const date = new Date(parseInt(t.postedOn, 10))
    return {
      id: t.transactionId,
      gross_amount: t.grossAmount,
      payment_gateway_charges: t.gateWayCharges,
      gateway_charges_bearer: t.gateWayChargesBearer,
      delivery_partner_charges: t.deliveryPartnerCharges,
      sokolink_charges: t.sokolinkCharges,
      total_charges: t.totalCharges,
      net_amount: Math.abs(t.netAmount),
      status: t.transactionStatus,
      payment_method: t.paymentMethod,
      order_no: t.orderNo,
      customer: t.customer?.name,
      details: t.transactionDetails,
      date,
    }
  })
}

export function formatDownloadCustomers(customers) {
  return customers.map((c) => {
    const date = new Date(parseInt(c.createdOn, 10))
    return {
      name: c.name,
      phone: c.phone,
      total_orders: c.orderStats?.numOfOrders,
      total_amount: c.orderStats?.averageAmount,
      date,
    }
  })
}

export function formatDownloadReviews(reviews) {
  return reviews.map((r) => {
    const date = new Date(parseInt(r.createdOn, 10))
    return {
      rating: r.rating,
      comment: r.comment,
      customer_name: r.customer?.name || '--',
      customer_phone: r.customer?.phone || '--',
      date,
    }
  })
}

export function formatDownloadSettlements(transactions) {
  return transactions.map((t) => {
    const date = new Date(parseInt(t.postedOn, 10))
    const previousSettlementDate = new Date(parseInt(t.previousSettlementDate, 10))
    return {
      id: t.transactionId,
      transaction_date: date,
      previous_settlement_date: previousSettlementDate,
      store: t.storeName,
      gross_amount: t.grossAmount,
      payment_gateway_charges: t.gateWayCharges,
      gateway_charges_bearer: t.gateWayChargesBearer,
      total_charges: t.totalCharges,
      net_amount: Math.abs(t.netAmount),
      status: t.transactionStatus,
      payment_method: t.paymentMethod,
      bank_name: t.payoutBankName,
      account_no: t.payoutAccountNumber,
      account_name: t.payoutAccountName,
      details: t.transactionDetails,
    }
  })
}

export function formatOrderLabel(orderType, deliveryType) {
  if (orderType === 'pickup') return 'Pickup'
  if (orderType === 'takeaway') return 'Takeaway'
  if (orderType === 'dinein') return 'Dine-In'
  if (orderType === 'delivery') {
    return `Delivery - ${deliveryType}`
  }
  return 'Unknown'
}
