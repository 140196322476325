import { gql } from '@apollo/client'

export const USERS = gql`
query getBusinessUsers($limit: Int!, $offset: Int!, $name: String, $role: String $status: Boolean) {
    users(limit: $limit, offset: $offset, role: $role, name: $name, isVerified: $status) {
      id
      merchantId
      storeId
      username
      name
      email
      phone
      role
      isVerified
    }
  }    
`

export const ADD_USER = gql`
mutation AddUser($name: String!, $email: String!, $password: String!, $storeId: ID, $role: String!) {
  inviteUser(name: $name, email: $email, password: $password, storeId: $storeId, role: $role) {
    id
    merchantId
    storeId
    username
    name
    email
    phone
    role
    isVerified
  }
}
`

export const UPDATE_USER = gql`
mutation UpdateUser(
  $id: ID!
  $name: String
  $phone: String
) {
  updateUser(id: $id, userName: $name, phone: $phone) {
    id
    merchantId
    storeId
    username
    name
    email
    phone
    role
    isVerified
  }
}
`

export const REMOVE_USER = gql`
mutation removeUser($email: String!) {
  deleteUser(email: $email) {
    id
    merchantId
    storeId
    username
    name
    email
    phone
    role
    isVerified
  }
}
`

export const ACCEPT_INVITATION = gql`
mutation acceptInvitation(
  $email: String!
  $token: String!
  $password: String!
) {
  acceptInvitation(email: $email, token: $token, password: $password) {
    id
    merchantId
    storeId
    username
    name
    email
    phone
    role
    isVerified
  }
}
`
