import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'

dayjs.extend(utc) // Enable UTC plugin

export const initDateRange = (days) => {
  // Calculate the initial range for one week backward from the current day
  const today = dayjs()
  const initialEndDate = today.subtract(0, 'day').endOf('day') // End date is yesterday
  const initialStartDate = initialEndDate.subtract(days, 'days').startOf('day') // Start date is one week ago
  return { initialStartDate, initialEndDate }
}

export const makePeriod = (selectedRange) => ({ startDate: dayjs(selectedRange[0]).startOf('day').format(), endDate: dayjs(selectedRange[1]).endOf('day').format() })
