/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import {
  Box, Button, FormControl, IconButton, InputAdornment, OutlinedInput, TextField,
} from '@mui/material'
import { Delete } from '@mui/icons-material'

function Variant({
  index, option, deleteOption, handleOptionChange,
}) {
  const { name, price } = option
  return (
    <Box sx={{
      width: '100%', mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center',
    }}
    >
      <FormControl sx={{ flex: '0.68' }} variant="filled">
        <OutlinedInput
          id="custom-name"
          placeholder="Option Name"
          size="small"
          required
          value={name}
          onChange={(e) => { handleOptionChange(index, 'name', e) }}
          sx={{ mb: '5px' }}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                onClick={() => { deleteOption(index) }}
                edge="end"
              >
                <Delete />
              </IconButton>
            </InputAdornment>
            )}
        />
      </FormControl>
      <TextField
        label="Price(Ksh)"
        type="number"
        value={price}
        onChange={(e) => { handleOptionChange(index, 'price', e) }}
        size="small"
        sx={{ flex: 0.3 }}
      />
    </Box>
  )
}

export default function CustomizationOptions({
  options, addOption, deleteOption, handleOptionChange,
}) {
  return (
    <Box sx={{ marginY: 2 }}>

      <Box sx={{ transition: 'ease' }}>
        { options.map((o, i) => <Variant key={i} index={i} option={o} deleteOption={deleteOption} handleOptionChange={handleOptionChange} />) }
      </Box>

      <Button size="small" sx={{ textDecoration: 'underline', textTransform: 'capitalize' }} onClick={addOption}>Add Option</Button>
    </Box>
  )
}
