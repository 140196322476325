import React from 'react'
import './styles/Balances.css'

export default function Balances({ balance }) {
  const { totalBalance, availableBalance } = balance
  return (
    <div className="balance-section">
      <div className="bal-holder">
        <h4>TOTAL BALANCE</h4>
        <h1>{`KES ${totalBalance}`}</h1>
      </div>
      <div className="bal-holder">
        <h4>AVAILABLE BALANCE</h4>
        <h1>{`KES ${availableBalance}`}</h1>
      </div>
    </div>
  )
}
