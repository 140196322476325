import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import Papa from 'papaparse'
import FileSaver from 'file-saver'
import toast from 'react-hot-toast'
import { Box, Divider, Typography } from '@mui/material'
import ErrorLoading from '../components/layout/ErrorLoading'
import CenterLoader from '../components/spinners/CenterLoader'
import '../styles/stores.css'
import { REVIEWS } from '../graphql/customers'
import Pagination from '../components/layout/Pagination'
import FilterForm from '../components/layout/FilterForm'
import NoData from '../components/layout/NoData'
import { formatDownloadReviews } from '../utils/formatter'
import SelectStore from '../components/locations/SelectStore'
import { CUSTOMERS_DOWNLOAD_LIMIT } from '../utils/limits'
import CustomersExperienceSummary from '../components/customers/CustomersExperienceSummary'
import DateRange from '../components/shared/DateRange'
import { initDateRange, makePeriod } from '../utils/daterangeinit'
import FeedbackTable from '../components/customers/FeedbackTable'

export default function CustomersExperience() {
  const limit = 10
  const [reviews, setReviews] = useState(null)
  const [page, setPage] = useState(1)
  const [error, setError] = useState(null)
  const start = page === 1 ? 0 : (page - 1) * limit
  const [storeId, setStoreId] = useState('all')
  // State for the selected range
  const { initialStartDate, initialEndDate } = initDateRange(30)
  const [selectedRange, setSelectedRange] = useState([initialStartDate, initialEndDate])

  const period = makePeriod(selectedRange)

  const handleResponse = (data) => {
    setError(null)
    setReviews(data.reviews)
  }
  const [getCustomers, { loading }] = useLazyQuery(REVIEWS, {
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  const getReviewsReq = () => {
    getCustomers({
      variables: {
        limit,
        offset: start,
        storeId: storeId !== 'all' ? storeId : null,
      },
    })
  }
  const handleDownloadResponse = (data) => {
    const downloadData = data.reviews || []
    const formattedData = formatDownloadReviews(downloadData)
    const dataStr = JSON.stringify(formattedData)
    const csv = Papa.unparse(dataStr)
    const blob = new Blob([decodeURIComponent(encodeURI(csv))], {
      type: 'text/csv;charset=utf-8;',
    })
    toast.dismiss('downloadspinner')
    toast.success('Data downloaded')
    FileSaver.saveAs(blob, 'reviews.csv')
  }
  const [getDownloadData] = useLazyQuery(REVIEWS, {
    fetchPolicy: 'cache-and-network',
    onError: () => { toast.dismiss('downloadspinner'); toast.error('Error downloading data') },
    onCompleted: handleDownloadResponse,
  })

  const handleRangeChange = (newRange) => {
    setSelectedRange(newRange)
  }

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextPage = () => {
    if (!(reviews.length < limit)) {
      setPage(page + 1)
    }
  }

  const download = (p) => {
    toast.loading('Downloading data...', { id: 'downloadspinner' })
    getDownloadData(
      {
        variables: {
          limit: CUSTOMERS_DOWNLOAD_LIMIT,
          offset: 0,
          storeId: storeId !== 'all' ? storeId : null,
          period: p,
        },
      },
    )
  }

  useEffect(() => {
    getReviewsReq()
  }, [page, storeId])

  return (
    <div className="page-content">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography>Experience & Reviews</Typography>
      </Box>
      <Divider sx={{ marginX: '-20px', mt: 2 }} />
      <FilterForm download={download} defaultRangeDays={30}>
        <SelectStore noLabel hasAll value={storeId} changeHandler={(e) => { setStoreId(e.target.value) }} sx={{ width: 'fit-content', outline: 'none', mr: 1 }} />
        <DateRange value={selectedRange} onChange={handleRangeChange} size="large" />
      </FilterForm>
      <CustomersExperienceSummary storeId={storeId} period={period} />
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getCustomers} />
      {!loading && !error && reviews ? <FeedbackTable reviews={reviews} /> : null }
      <NoData visible={!loading && !error && !reviews?.length} />
      <Pagination visible={!loading && !error && (reviews ? reviews.length : false)} page={page} next={nextPage} prev={prevPage} />
    </div>
  )
}
