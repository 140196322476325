import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import AddBoxIcon from '@mui/icons-material/AddBox'
import BtnLoader from '../spinners/BtnLoader'
import './styles/Logo.css'
import { UPDATE_MERCHANT } from '../../graphql/merchant'

export default function Logo({ merchant }) {
  const [logoUrl, setLogoUrl] = useState(merchant.logo)

  const handleErrors = () => {
    // error updating details
    toast.error('error updating logo')
  }

  const handleResponse = (data) => {
    // details updated
    setLogoUrl(data.updateMerchantDetails.logo)
    toast.success('logo updated')
  }

  const [updateLogoReq, { loading }] = useMutation(UPDATE_MERCHANT, { onError: handleErrors, onCompleted: handleResponse })

  const handleImageUpload = (e) => {
    const image = e.target.files[0]
    if (image) {
      updateLogoReq({
        variables: {
          logoUrl,
          logo: image,
        },
      })
    }
  }

  return (
    <div className="business-logo">
      <h3>Business Logo</h3>
      <div className="logo-holder">
        <div className="adding-logo">
          <BtnLoader visible={loading} color="blue" />
        </div>
        <img src={logoUrl} alt="store logo" />
      </div>
      <div className="upload-btn-wrapper">
        <button type="button" className="upload-btn upload-btn-row">
          <h5>Business Logo</h5>
          <AddBoxIcon style={{ fontSize: '20px', color: ' #808080' }} />
        </button>
        <input type="file" name="file" accept="images/*" required onChange={handleImageUpload} />
      </div>
    </div>
  )
}
