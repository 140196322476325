/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
import {
  Delete, Edit,
} from '@mui/icons-material'
import { useMutation, useQuery } from '@apollo/client'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import {
  Box, CircularProgress, IconButton,
} from '@mui/material'
import DeleteDialog from '../shared/DeleteDialog'
import { DELETE_CUSTOMIZATION, GET_CUSTOMIZATIONS } from '../../graphql/products'
import NoData from '../layout/NoData'
import SaveCustomization from './SaveCustomization'
import ErrorLoading from '../layout/ErrorLoading'
import { useCustomizations } from './context/customizations'

function Customization({ customization }) {
  const { updateCustomization, deleteCustomization } = useCustomizations()
  const {
    id, title, options, required, minSelect, maxSelect,
  } = customization
  const deleteMsg = `Are you sure you want to delete customization ${title} ?`
  const [editOpen, toggleEdit] = useState(false)
  const [deleteOpen, toggleDelete] = useState(false)
  const { catalogueId } = useParams()

  const [deleteReq] = useMutation(DELETE_CUSTOMIZATION, {
    onError: () => { toast.error(`Error removing customization ${title}.`) },
    onCompleted: () => { toast.success(`Customization ${title} deleted successfully`); deleteCustomization(id) },
  })
  const handleDelete = () => {
    deleteReq({
      variables: {
        customizationId: id,
        catalogueId,
      },
    })
    toggleDelete(false)
  }
  return (
    <>
      { editOpen ? <SaveCustomization customization={customization} close={() => { toggleEdit(false) }} update={(c) => { updateCustomization(c) }} /> : null }
      <DeleteDialog visible={deleteOpen} close={() => { toggleDelete(false) }} title="Delete Catalogue" msg={deleteMsg} onDelete={handleDelete} />
      <tr>
        <td style={{ minWidth: '220px' }} onClick={() => { toggleEdit(true) }}>
          <u>{title}</u>
        </td>
        <td style={{ minWidth: '150px' }}>{required ? 'Yes' : 'No'}</td>
        <td style={{ minWidth: '150px' }}>{options?.length}</td>
        <td style={{ minWidth: '150px' }}>{minSelect}</td>
        <td style={{ minWidth: '150px' }}>{maxSelect}</td>
        <td style={{ minWidth: '200px' }}>
          <IconButton sx={{ mr: 1.5 }} onClick={() => { toggleEdit(true) }}>
            <Edit />
          </IconButton>
          <IconButton sx={{ mr: 1.5, color: 'red' }} onClick={() => { toggleDelete(true) }}>
            <Delete />
          </IconButton>
        </td>
      </tr>
    </>
  )
}

function CustomizationsTable({ customizations, deleteCustomization }) {
  return (
    <div className="table-container">
      <table className="products-table">
        <thead>
          <tr>
            <th className="title-hd">Title</th>
            <th className="required-hd">Required</th>
            <th className="options-hd">Options</th>
            <th className="min-hd">Min Select</th>
            <th className="max-hd">Max Select</th>
            <th className="actions-hd">Actions</th>
          </tr>
        </thead>
        <tbody>
          {customizations.map((c) => <Customization key={c.id} customization={c} remove={deleteCustomization} />)}
        </tbody>
      </table>
    </div>
  )
}

export default function CustomizationsPage() {
  const { customizations, addCustomizations, deleteCustomization } = useCustomizations()
  const [error, setError] = useState(null)

  const { catalogueId } = useParams()
  const { loading } = useQuery(GET_CUSTOMIZATIONS, {
    variables: {
      catalogueId,
    },
    fetchPolicy: 'cache-and-network',
    onError: (e) => { setError(e) },
    onCompleted: (data) => { addCustomizations(data.customizations) },
  })
  if (error) return <ErrorLoading visible />
  return (
    <Box sx={{
      width: '100%', height: 'fit-content',
    }}
    >
      {loading ? (
        <Box sx={{
          width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <CircularProgress size={20} />
        </Box>
      ) : null}
      {!loading && !error ? <CustomizationsTable customizations={customizations} deleteCustomization={deleteCustomization} /> : null}
      <NoData visible={!loading && !error && !customizations?.length} />
    </Box>
  )
}
