/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, InputLabel } from '@mui/material'
import React from 'react'
import Switch from 'react-switch'

export default function InstockSwitch({ checked, label, onChange }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <InputLabel sx={{ mr: 3 }}>{label}</InputLabel>
      <Switch onChange={onChange} checked={checked} />
    </Box>
  )
}
