/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { connect } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import '../styles/auth.css'
import AuthInput from '../components/auth/AuthInput'
import BtnLoader from '../components/spinners/BtnLoader'
import { REGISTER } from '../graphql/auth'
import Logo from '../img/logo.png'

const initialValues = {
  email: '',
  phone: '',
  businessName: '',
  password: '',
  confirmPassword: '',
}
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required('Email is a required field'),
  phone: yup
    .string()
    .required('phone is a required field')
    .min(9, 'Phone must be at least 9 characters'),
  businessName: yup
    .string()
    .required('Name is a required field')
    .min(3, 'Name must be at least 3 characters'),
  password: yup
    .string()
    .required('Please enter your password')
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least 8 characters, one uppercase, one number and one special case character',
    ),
  confirmPassword: yup
    .string()
    .required('Please confirm your password')
    .when('password', {
      is: (password) => (!!(password && password.length > 0)),
      then: yup.string().oneOf([yup.ref('password')], "Password doesn't match"),
    }),
})

function Register() {
  const history = useHistory()
  const handleErrors = (e) => {
    try {
      const errors = { ...e }
      const errorsArr = errors.graphQLErrors
      const firstObj = errorsArr[0]
      const msg = firstObj.message
      toast.error(msg)
    } catch (error) {
      toast.error('An error occurred, try again.')
    }
  }

  const handleResponse = () => {
    toast.success('Business Registered Successfully')
    history.push('/login')
  }

  const [sendRegisterReq, { loading }] = useMutation(REGISTER, { onError: handleErrors, onCompleted: handleResponse })

  const handleSubmit = (values) => {
    const {
      email, phone, businessName, password,
    } = values
    sendRegisterReq({
      variables: {
        email,
        phone,
        businessName,
        password,
      },
    })
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })
  const emailProps = formik.getFieldProps('email')
  const phoneProps = formik.getFieldProps('phone')
  const nameProps = formik.getFieldProps('businessName')
  const passwordProps = formik.getFieldProps('password')
  const confirmPasswordProps = formik.getFieldProps('confirmPassword')
  return (
    <div className="auth-page">
      <Toaster />
      <header className="auth-header">
        <div className="brand">
          <img src={Logo} alt="logo" />
        </div>
      </header>
      <div className="auth-holder">
        <div className="auth-intro">
          <h3>Sokolink Dashboard</h3>
          <p>Business Register</p>
        </div>
        <form className="auth-form" onSubmit={formik.handleSubmit}>
          <AuthInput type="email" required label="Email address" placeholder="your@email.com" {...emailProps} />
          {formik.touched.email && formik.errors.email ? (
            <div className="form-error">{formik.errors.email}</div>
          ) : null}
          <AuthInput type="text" required label="Phone number" placeholder="e.g. 0706064714" {...phoneProps} />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="form-error">{formik.errors.phone}</div>
          ) : null}
          <AuthInput type="text" required label="Business name" placeholder="e.g. mama otis bakery" {...nameProps} />
          {formik.touched.businessName && formik.errors.businessName ? (
            <div className="form-error">{formik.errors.businessName}</div>
          ) : null}
          <AuthInput type="password" required label="Password" placeholder="password" {...passwordProps} />
          {formik.touched.password && formik.errors.password ? (
            <div className="form-error">{formik.errors.password}</div>
          ) : null}
          <AuthInput type="password" required label="Confirm password" placeholder="repeat password" {...confirmPasswordProps} />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <div className="form-error">{formik.errors.confirmPassword}</div>
          ) : null}
          <button type="submit" disabled={!(formik.isValid && formik.dirty) || loading} className="auth-submit">
            {!loading ? <h5>Register</h5> : null}
            <BtnLoader color="#fff" visible={loading} />
          </button>
        </form>
      </div>
    </div>
  )
}
const mapStateToProps = () => ({
})
export default connect(mapStateToProps, {})(Register)
