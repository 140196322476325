import firebase from './firebase'

const messaging = firebase.messaging()

export const requestFirebaseNotificationPermission = () => new Promise((resolve, reject) => {
  messaging
    .requestPermission()
    .then(() => messaging.getToken())
    .then((firebaseToken) => {
      resolve(firebaseToken)
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err)
      reject(err)
    })
})

export const deleteToken = () => new Promise((resolve, reject) => {
  messaging
    .deleteToken()
    .then((firebaseToken) => {
      resolve(firebaseToken)
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err)
      reject(err)
    })
})

export const onMessageListener = () => new Promise((resolve) => {
  messaging.onMessage((payload) => {
    resolve(payload)
  })
})
