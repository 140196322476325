/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

export default function AuthInput({
  type, label, placeholder, required, readOnly, ...rest
}) {
  return (
    <div className="auth-input">
      <label>{label}</label>
      <input type={type} readOnly={readOnly} required={!!required} placeholder={placeholder} {...rest} />
    </div>
  )
}
