import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Alert, Box } from '@mui/material'
import { BALANCE } from '../graphql/wallet'
import ErrorLoading from '../components/layout/ErrorLoading'
import CenterLoader from '../components/spinners/CenterLoader'
import Balances from '../components/wallet/Balances'
import BankDetails from '../components/wallet/BankDetails'
import '../styles/wallet.css'
import SelectStore from '../components/locations/SelectStore'

function WalletInfo({ balance }) {
  return (
    <>
      <div className="wallet-section">
        <div className="wallet-section-header">
          <h3>Balance</h3>
        </div>
        <Balances balance={balance} />
        <Alert severity="info" sx={{ m: 4 }}>Your total balance is how much you have received to your Sokolink account. Your available balance is how much will be settled into your registered bank account in the next settlement window.</Alert>
      </div>
    </>
  )
}

export default function Wallet() {
  const [storeId, setStoreId] = useState('all')

  const { loading, error, data } = useQuery(BALANCE, {
    variables: {
      storeId: storeId !== 'all' ? storeId : null,
    },
  })

  if (loading) return (<CenterLoader visible />)
  if (error) return (<ErrorLoading visible />)

  return (
    <div className="page-content">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="page-tittle no-margin">Balances and Banking Details</div>
        <SelectStore noLabel hasAll value={storeId} changeHandler={(e) => { setStoreId(e.target.value) }} sx={{ width: 'fit-content', outline: 'none' }} />
      </Box>
      <WalletInfo balance={data.balance} />
      { storeId && storeId !== 'all' ? (<BankDetails bankDetails={data.balance.bankDetails} storeId={storeId} />) : null }
    </div>
  )
}
