import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

function RoutePlanner({ merchantInfo }) {
  if (merchantInfo?.category === 'Mall') {
    return <Redirect to="/mall/overview" />
  }
  return <Redirect to="/business/overview" />
}
const mapStateToProps = (state) => ({
  userToken: state.user.userToken,
  userInfo: state.user.userInfo,
  merchantInfo: state.merchant.merchantInfo,
})
export default connect(mapStateToProps, { })(RoutePlanner)
