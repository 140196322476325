/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { TextField } from '@mui/material'

export default function FilterName({
  type, label, changeHandler, clear, value,
}) {
  return (
    <div className="menu-filter-input">
      <TextField type={type} size="small" value={value} onChange={changeHandler} label={label} variant="outlined" style={{ width: '100%' }} />
      {value ? <div className="clear-icon" onClick={clear}><ClearIcon style={{ color: 'gray', fontSize: '24' }} /></div> : null}
    </div>
  )
}
