import React, { useState } from 'react'
import { HexColorPicker } from 'react-colorful'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import { Button } from '@mui/material'
import { UPDATE_MERCHANT } from '../../graphql/merchant'
import './styles/Color.css'

function PrimaryPreview({ color }) {
  return (
    <div className="pri-preview">
      <style>
        {
        `.pri-preview {
            width: 100%;
            height: 100px;
            background: ${color};
            margin: 10px 0;
            border-radius: 5px;
            box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        }
        `
      }
      </style>
    </div>
  )
}

function SecondaryPreview({ color }) {
  return (
    <div className="sec-preview">
      <style>
        {
          `.sec-preview {
              width: 100%;
              height: 100px;
              background: ${color};
              margin: 10px 0;
              border-radius: 5px;
              box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
          }
          `
        }
      </style>
    </div>
  )
}

export default function Color({ merchant }) {
  const [primaryColor, setPrimaryColor] = useState(merchant?.primaryColor || '#0a82f3')
  const [secondaryColor, setSecondaryColor] = useState(merchant?.secondaryColor || '#fff')

  const handleErrors = () => {
    toast.error('error updating business colors')
  }

  const handleResponse = () => {
    toast.success('colors updated')
  }

  const [updateSettingsReq, { loading }] = useMutation(UPDATE_MERCHANT, { onError: handleErrors, onCompleted: handleResponse })

  const handleSubmit = (e) => {
    e.preventDefault()
    updateSettingsReq({
      variables: {
        primaryColor,
        secondaryColor,
      },
    })
  }

  return (
    <div className="color-picker">
      <div className="settings-section-wrapper">
        <div>
          <h3>Primary Color</h3>
          <PrimaryPreview color={primaryColor} />
          <HexColorPicker color={primaryColor} onChange={setPrimaryColor} />
        </div>
        <div>
          <h3>Secondary Color</h3>
          <SecondaryPreview color={secondaryColor} />
          <HexColorPicker color={secondaryColor} onChange={setSecondaryColor} />
        </div>
      </div>
      <Button variant="contained" onClick={handleSubmit} sx={{ mb: 1 }}>
        {!loading ? 'Update Colors' : 'Updating'}
      </Button>
    </div>
  )
}
