import { gql } from '@apollo/client'

export const STORES = gql`
query getStores($limit: Int!, $offset: Int!, $name: String, $phone: String) {
  stores(limit: $limit, offset: $offset, name: $name, phone: $phone) {
    id
    storeNo
    name
    details
    mapLocation {
      placeId
      name
      lat
      lng
    }
    phone
    logo
    template
    active
    mallActive
    whatsappAlerts
    acceptUnpaidOrders
    smsAlerts
    deliveryOption
    acceptPastRadiusOrders
    minimumOrderAmount
    freeDeliveryAmount
    deliveryDistance
    maxOwnDeliveryDistance
    deliveryBaseRate
    costPerKm
    deliveryZones {
      id
      amount
      paths { lat lng }
    }
    deliveryCharges { from to amount }
    timings {
      sunday { status from to }
      monday { status from to }
      tuesday { status from to }
      wednesday { status from to }
      thursday { status from to }
      friday { status from to }
      saturday { status from to }
    }
    serviceCharges {
      delivery { type value maxValue }
      pickup { type value maxValue }
      dinein { type value maxValue }
      takeaway { type value maxValue }
    }
    mpesaTill
    mpesaShortCode
    mpesaConsumerKey
    mpesaConsumerSecret
    mpesaPassKey
  }
}
`

export const ADD_STORE = gql`
mutation AddStore(
  $name: String!
  $details: String!
  $mapLocation: MapLocationInput!
  $phone: String!
) {
  addStore(
    name: $name
    details: $details
    mapLocation: $mapLocation
    phone: $phone
  ) {
    id
    storeNo
    mallId
    name
    details
    mapLocation {
      placeId
      name
      lat
      lng
    }
    phone
    logo
    template
    active
    mallActive
    acceptUnpaidOrders
    whatsappAlerts
    smsAlerts
    deliveryOption
    acceptPastRadiusOrders
    minimumOrderAmount
    freeDeliveryAmount
    deliveryDistance
    maxOwnDeliveryDistance
    deliveryBaseRate
    costPerKm
    deliveryZones {
      id
      amount
      paths { lat lng }
    }
    deliveryCharges { from to amount }
    timings {
      sunday { status from to }
      monday { status from to }
      tuesday { status from to }
      wednesday { status from to }
      thursday { status from to }
      friday { status from to }
      saturday { status from to }
    }
    serviceCharges {
      delivery { type value maxValue }
      pickup { type value maxValue }
      dinein { type value maxValue }
      takeaway { type value maxValue }
    }
    mpesaTill
    mpesaShortCode
    mpesaConsumerKey
    mpesaConsumerSecret
    mpesaPassKey
  }
}
`

export const UPDATE_STORE = gql`
mutation UpdateStore(
  $id: ID!
  $mallId: ID
  $name: String
  $details: String
  $mapLocation: MapLocationInput
  $phone: String
  $logoUrl: String
  $logo: Upload
  $template: String
  $deliveryOption: String
  $minimumOrderAmount: Float
  $freeDeliveryOrderAmount: Float
  $deliveryAreaDistance: Float
  $maxOwnDeliveryDistance: Float
  $baseRate: Float
  $costPerKm: Float
  $deliveryCharges: [DeliveryChargesInput]
  $deliveryZones: [DeliveryZonesInput]
  $timings: TimingsInput
  $serviceCharges: ServiceChargesInput
  $active: Boolean
  $mallActive: Boolean
  $acceptUnpaidOrders: Boolean
  $whatsappAlerts: Boolean
  $smsAlerts: Boolean
  $acceptPastRadiusOrders: Boolean
  $mpesaTill: String
  $mpesaShortCode: String
  $mpesaConsumerKey: String
  $mpesaConsumerSecret: String
  $mpesaPassKey: String
) {
  updateStore(
    id: $id
    mallId: $mallId
    name: $name
    details: $details
    mapLocation: $mapLocation
    phone: $phone
    logo: $logo
    logoUrl: $logoUrl
    template: $template
    deliveryOption: $deliveryOption
    minimumOrderAmount: $minimumOrderAmount
    freeDeliveryAmount: $freeDeliveryOrderAmount
    deliveryDistance: $deliveryAreaDistance
    maxOwnDeliveryDistance: $maxOwnDeliveryDistance
    deliveryBaseRate: $baseRate
    costPerKm: $costPerKm
    deliveryCharges: $deliveryCharges
    deliveryZones: $deliveryZones
    timings: $timings
    serviceCharges: $serviceCharges
    active: $active
    mallActive: $mallActive
    acceptUnpaidOrders: $acceptUnpaidOrders
    whatsappAlerts: $whatsappAlerts
    smsAlerts: $smsAlerts
    acceptPastRadiusOrders: $acceptPastRadiusOrders
    mpesaTill: $mpesaTill
    mpesaShortCode: $mpesaShortCode
    mpesaConsumerKey: $mpesaConsumerKey
    mpesaConsumerSecret: $mpesaConsumerSecret
    mpesaPassKey: $mpesaPassKey
  ) {
    id
    storeNo
    mallId
    name
    details
    mapLocation {
      placeId
      name
      lat
      lng
    }
    phone
    logo
    template
    active
    mallActive
    acceptUnpaidOrders
    whatsappAlerts
    smsAlerts
    deliveryOption
    acceptPastRadiusOrders
    minimumOrderAmount
    freeDeliveryAmount
    deliveryDistance
    maxOwnDeliveryDistance
    deliveryBaseRate
    costPerKm
    deliveryZones {
      id
      amount
      paths { lat lng }
    }
    deliveryCharges { from to amount }
    timings {
      sunday { status from to }
      monday { status from to }
      tuesday { status from to }
      wednesday { status from to }
      thursday { status from to }
      friday { status from to }
      saturday { status from to }
    }
    serviceCharges {
      delivery { type value maxValue }
      pickup { type value maxValue }
      dinein { type value maxValue }
      takeaway { type value maxValue }
    }
    mpesaTill
    mpesaShortCode
    mpesaConsumerKey
    mpesaConsumerSecret
    mpesaPassKey
  }
}

`

export const GET_STORE = gql`
query GetStore($id: ID!){
  store(id: $id) {
    id
    storeNo
    mallId
    name
    details
    mapLocation {
      placeId
      name
      lat
      lng
    }
    phone
    logo
    template
    active
    mallActive
    whatsappAlerts
    acceptUnpaidOrders
    smsAlerts
    deliveryOption
    acceptPastRadiusOrders
    minimumOrderAmount
    freeDeliveryAmount
    deliveryDistance
    maxOwnDeliveryDistance
    deliveryBaseRate
    costPerKm
    deliveryZones {
      id
      amount
      paths { lat lng }
    }
    deliveryCharges { from to amount }
    timings {
      sunday { status from to }
      monday { status from to }
      tuesday { status from to }
      wednesday { status from to }
      thursday { status from to }
      friday { status from to }
      saturday { status from to }
    }
    serviceCharges {
      delivery { type value maxValue }
      pickup { type value maxValue }
      dinein { type value maxValue }
      takeaway { type value maxValue }
    }
    mpesaTill
    mpesaShortCode
    mpesaConsumerKey
    mpesaConsumerSecret
    mpesaPassKey
  }
}    
`

export const DELETE_STORE = gql`
mutation DELETE_STORE($id: ID!) {
  removeStore(id: $id) {
    id
  }
}
`
