/* eslint-disable react/no-unescaped-entities */
import { Save } from '@mui/icons-material'
import {
  Box, DialogActions, TextField, Button,
} from '@mui/material'
import { useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { UPDATE_STORE } from '../../graphql/locations'
import ResponsiveModal from '../layout/ResponsiveModal'

function Actions({ loading, close }) {
  return (
    <DialogActions sx={{
      display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
    }}
    >
      <Button onClick={close} sx={{ mr: 2 }}>Cancel</Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<Save />}
        variant="contained"
        form="save-mpesa-integration-form"
      >
        {loading ? 'Saving...' : 'Save'}
      </Button>
    </DialogActions>
  )
}
export default function MpesaIntegration({ store, update, close }) {
  const [mpesaTill, setMpesaTill] = useState(store?.mpesaTill || '')
  const [mpesaShortCode, setShortCode] = useState(store?.mpesaShortCode || '')
  const [mpesaConsumerKey, setConsumerKey] = useState(store?.mpesaConsumerKey || '')
  const [mpesaConsumerSecret, setConsumerSecret] = useState(store?.mpesaConsumerSecret || '')
  const [mpesaPassKey, setPassKey] = useState(store?.mpesaPassKey || '')

  const [updateReq, { loading }] = useMutation(UPDATE_STORE, {
    onError: () => { toast.error('Error updating mpesa integration.') },
    onCompleted: (data) => {
      update(data.updateStore)
      toast.success('Mpesa integration updated successfully.')
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    updateReq({
      variables: {
        id: store.id,
        mpesaTill,
        mpesaShortCode,
        mpesaConsumerKey: mpesaConsumerKey === store?.mpesaConsumerKey ? undefined : mpesaConsumerKey,
        mpesaConsumerSecret: mpesaConsumerSecret === store?.mpesaConsumerSecret ? undefined : mpesaConsumerSecret,
        mpesaPassKey: mpesaPassKey === store?.mpesaPassKey ? undefined : mpesaPassKey,
      },
    })
  }
  return (
    <ResponsiveModal
      title="Mpesa API Configuration"
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions loading={loading} close={close} />}
    >
      <Box component="form" id="save-mpesa-integration-form" onSubmit={onSave}>
        <TextField fullWidth type="number" label="Mpesa Till" value={mpesaTill} size="small" onChange={(e) => { setMpesaTill(e.target.value) }} />
        <TextField fullWidth sx={{ mt: 2 }} type="number" label="Business Short Code" value={mpesaShortCode} size="small" onChange={(e) => { setShortCode(e.target.value) }} />
        <TextField fullWidth sx={{ mt: 2 }} type="password" label="Consumer Key" value={mpesaConsumerKey} size="small" onChange={(e) => { setConsumerKey(e.target.value) }} />
        <TextField fullWidth sx={{ mt: 2 }} type="password" label="Consumer Secret" value={mpesaConsumerSecret} size="small" onChange={(e) => { setConsumerSecret(e.target.value) }} />
        <TextField fullWidth sx={{ mt: 2 }} type="password" label="Pass Key" value={mpesaPassKey} size="small" onChange={(e) => { setPassKey(e.target.value) }} />
      </Box>
    </ResponsiveModal>
  )
}
