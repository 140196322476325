/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react'
import Autocomplete from 'react-google-autocomplete'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import Geocode from 'react-geocode'
import {
  Button, Chip, Divider, Typography,
} from '@mui/material'
import '../../styles/setaddress.css'

Geocode.setApiKey('AIzaSyAXEkv3ohotrk5e_9rNXxhXZ9RkOjZk9pY')
Geocode.setLanguage('en')
Geocode.setRegion('ke')

export default function LocationInput({ onChange }) {
  const [geocoding, setGeoCoding] = useState(false)

  const handlePlaceSelection = (place) => {
    const placeLocation = {
      placeId: place.place_id,
      name: place.formatted_address,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    }
    onChange(placeLocation)
  }
  const handleCurrentLocation = () => {
    setGeoCoding(true)
    navigator.geolocation.getCurrentPosition(async (position) => {
      const geoCodeReq = Geocode.fromLatLng(position.coords.latitude.toString(), position.coords.longitude.toString())
      try {
        localStorage.setItem('currentLocation', JSON.stringify(position))
        const req = await geoCodeReq
        if (req.results.length) {
          const res = req.results[0]
          const placeLocation = {
            placeId: res.place_id,
            name: res.formatted_address,
            lat: res.geometry.location.lat,
            lng: res.geometry.location.lng,
          }
          setGeoCoding(false)
          onChange(placeLocation)
        }
      } catch (error) {
        setGeoCoding(false)
      }
    })
  }
  useEffect(() => {
    if (!localStorage.getItem('currentLocation')) {
      handleCurrentLocation()
    }
  }, [])
  return (
    <div className="location-search-modal">
      <div className="location-input-holder">
        <Typography fontSize={20} sx={{ mb: 2 }}>Deliver To</Typography>
        <Typography>Search For Your Street, Estate, Locality...</Typography>
        <div className="location-input-container">
          <Autocomplete
            apiKey="AIzaSyAXEkv3ohotrk5e_9rNXxhXZ9RkOjZk9pY"
            style={{
              width: '100%', height: '100%', paddingLeft: '10px', borderRadius: '5px',
            }}
            onPlaceSelected={handlePlaceSelection}
            options={{
              types: ['establishment'],
              componentRestrictions: { country: 'ke' },
            }}
            placeholder="Search for your location..."
            autoFocus
          />
        </div>
        <div><Divider textAlign="center" sx={{ my: 2 }}><Chip label="OR" /></Divider></div>
        <Button variant="contained" size="large" onClick={handleCurrentLocation} startIcon={!geocoding ? <MyLocationIcon /> : null}>
          { geocoding ? 'Requesting your location...' : 'Use My Current Location'}
        </Button>
      </div>
    </div>
  )
}
