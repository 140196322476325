import { gql } from '@apollo/client'

export const ADD_CATALOGUE = gql`
mutation AddCatalogue($title: String!, $label: String, $storeIds: [ID]) {
  createCatalogue(title: $title, label: $label, storeIds: $storeIds) {
    id
    title
    label
    storeIds
    categoriesCount
    productsCount
  }
}
`

export const EDIT_CATALOGUE = gql`
mutation EditCatalogue($id: ID! $title: String!, $label: String,  $storeIds: [ID]) {
  updateCatalogue(id: $id, title: $title, label: $label, storeIds: $storeIds) {
    id
    title
    label
    storeIds
    categoriesCount
    productsCount
  }
}
`

export const CATALOGUES = gql`
query getCatalogues($limit: Int!, $offset: Int!) {
  catalogues(limit: $limit, offset: $offset) {
    id
    title
    label
    storeIds
    categoriesCount
    productsCount
  }
}
`

export const DELETE_CATALOGUE = gql`
mutation DeleteCatalogue($id: ID!) {
  deleteCatalogue(id: $id) {
    id
  }
}
`

export const SORT_CATALOGUES = gql`
mutation SortCatalogues($ids: [ID!]!) {
  sortCatalogues(ids: $ids) {
    id
  }
}
`

export const PRODUCTS = gql`
query getProducts(
  $storeId: ID
  $limit: Int!
  $offset: Int!
  $search: String
  $categoryId: ID
) {
  products(
    storeId: $storeId
    limit: $limit
    offset: $offset
    search: $search
    categoryId: $categoryId
  ) {
    totalCount
    products {
      id
      name
      price
      discountPrice
      variants {
        name
        price
        discountPrice
      }
      customizations {
        id
        title
        required
        minSelect
        maxSelect
        options {
          name
          price
        }
      }
      categoryId
      category {
        id
        category
      }
      inStock
      updated
      images
      timings {
        sunday {
          status
          from
          to
        }
        monday {
          status
          from
          to
        }
        tuesday {
          status
          from
          to
        }
        wednesday {
          status
          from
          to
        }
        thursday {
          status
          from
          to
        }
        friday {
          status
          from
          to
        }
        saturday {
          status
          from
          to
        }
      }
      description
    }
  }
}    
`
export const GET_CATEGORIES = gql`
query CATEGORIES($catalogueId: ID!) {
  categories(catalogueId: $catalogueId) {
    id
    category
    details
    imageUrl
  }
}
`
export const GET_PRODUCT = gql`
query GET_PRODUCT($id: ID!){
  product(id: $id) {
    id
    name
    price
    discountPrice
    variants {
      name
      price
      discountPrice
    }
    customizations {
      id
      title
      required
      options {
        name
        price
      }
    }
    categoryId
    category {
      id
      category
    }
    inStock
    updated
    images
    description
    timings {
      sunday { status from to }
      monday { status from to }
      tuesday { status from to }
      wednesday { status from to }
      thursday { status from to }
      friday { status from to }
      saturday { status from to }
    }
  }
}    
`

export const ADD_CATEGORY = gql`
mutation AddCategory(
  $category: String!
  $catalogueId: ID!
  $categoryId: ID
  $image: Upload
  $details: String
) {
  addCategory(
    category: $category
    catalogueId: $catalogueId
    categoryId: $categoryId
    details: $details
    image: $image
  ) {
    id
    category
    details
    imageUrl
  }
}
`

export const UPDATE_CATEGORY = gql`
mutation UpdateCategory(
  $categoryId: ID!
  $category: String
  $image: Upload
  $details: String
  $imageUrl: String
) {
  updateCategory(
    categoryId: $categoryId
    category: $category
    details: $details
    image: $image
    imageUrl: $imageUrl
  ) {
    id
    category
    details
    imageUrl
  }
}
`

export const UPDATE_CATEGORIES = gql`
mutation UpdateCategories($ids: [ID]!) {
  updateCategories(ids: $ids) {
    id
    category
    details
    imageUrl
  }
}
`

export const SORT_CATEGORIES = gql`
mutation SortCategories($ids: [ID!]!) {
  sortCategories(ids: $ids) {
    id
  }
}
`

export const REMOVE_CATEGORY = gql`
mutation RemoveCategory($id: ID!) {
  removeCategory(id: $id) {
    id
    category
    details
    imageUrl
  }
}
`

export const ADD_PRODUCT = gql`
mutation AddProduct(
  $files: [Upload]
  $name: String!
  $catalogueId: ID!
  $categoryId: ID!
  $price: Float!
  $discountPrice: Float
  $variants: [ProductVariantInput]
  $customizationIds: [Int!]
  $inStock: Boolean!
  $desc: String
  $timings: TimingsInput!
) {
  addProduct(
    name: $name
    categoryId: $categoryId
    catalogueId: $catalogueId
    price: $price
    discountPrice: $discountPrice
    variants: $variants
    customizationIds: $customizationIds
    inStock: $inStock
    description: $desc
    images: $files
    timings: $timings
  ) {
    id
    name
    price
    discountPrice
    variants {
      name
      price
      discountPrice
    }
    customizations {
      id
      title
      required
      options {
        name
        price
      }
    }
    categoryId
    category {
      id
      category
    }
    inStock
    updated
    images
    description
    timings {
      sunday { status from to }
      monday { status from to }
      tuesday { status from to }
      wednesday { status from to }
      thursday { status from to }
      friday { status from to }
      saturday { status from to }
    }
  }
}
`
export const ADD_PRODUCTS = gql`
mutation AddProducts(
  $catalogueId: ID!
  $categoryId: ID!
  $products: [BulkUploadInput!]
) {
  bulkAddProducts(
    catalogueId: $catalogueId
    categoryId: $categoryId
    products: $products
  ) {
    id
    name
    price
    discountPrice
    variants {
      name
      price
      discountPrice
    }
    customizations {
      id
      title
      required
      options {
        name
        price
      }
    }
    categoryId
    category {
      id
      category
    }
    inStock
    updated
    images
    description
    timings {
      sunday { status from to }
      monday { status from to }
      tuesday { status from to }
      wednesday { status from to }
      thursday { status from to }
      friday { status from to }
      saturday { status from to }
    }
  }
}
`

export const UPDATE_PRODUCT = gql`
mutation UPDATE_PRODUCT(
  $id: ID!
  $inStock: Boolean
  $name: String
  $categoryId: ID
  $price: Float
  $discountPrice: Float
  $variants: [ProductVariantInput]
  $customizationIds: [Int!]
  $desc: String
  $timings: TimingsInput
  $files: [Upload]
) {
  updateProduct(
    id: $id
    inStock: $inStock
    name: $name
    categoryId: $categoryId
    price: $price
    discountPrice: $discountPrice
    variants: $variants
    customizationIds: $customizationIds
    description: $desc
    timings: $timings
    images: $files
  ) {
    id
    name
    price
    discountPrice
    variants {
      name
      price
      discountPrice
    }
    customizations {
      id
      title
      required
      options {
        name
        price
      }
    }
    categoryId
    category {
      id
      category
    }
    inStock
    updated
    images
    description
    timings {
      sunday { status from to }
      monday { status from to }
      tuesday { status from to }
      wednesday { status from to }
      thursday { status from to }
      friday { status from to }
      saturday { status from to }
    }
  }
}
`
export const DELETE_IMAGE = gql`
mutation RemoveImage($productId: ID!, $imageLink: String!) {
  removeImage(productId: $productId, imageLink: $imageLink) {
    images
  }
}
`

export const SORT_PRODUCTS = gql`
mutation SortProducts($ids: [ID!]!) {
  sortProducts(ids: $ids) {
    id
  }
}
`

export const DELETE_PRODUCT = gql`
mutation DELETE_PRODUCT($id: ID!) {
  removeProduct(id: $id) {
    id
  }
}
`
export const ADD_CUSTOMIZATION = gql`
mutation AddCustomization(
  $catalogueId: ID!
  $title: String!
  $required: Boolean!
  $minSelect: Int
  $maxSelect: Int
  $options: [ProductExtraInput]!
) {
  addCustomization(
    catalogueId: $catalogueId
    title: $title
    required: $required
    minSelect: $minSelect
    maxSelect: $maxSelect
    options: $options
  ) {
    id
    title
    required
    minSelect
    maxSelect
    options {
      name
      price
    }
  }
}
`

export const EDIT_CUSTOMIZATION = gql`
mutation EditCustomization(
  $id: ID!
  $title: String!
  $required: Boolean!
  $minSelect: Int
  $maxSelect: Int
  $options: [ProductExtraInput]!
) {
  updateCustomization(
    id: $id
    title: $title
    required: $required
    minSelect: $minSelect
    maxSelect: $maxSelect
    options: $options
  ) {
    id
    title
    required
    minSelect
    maxSelect
    options {
      name
      price
    }
  }
}
`

export const GET_CUSTOMIZATIONS = gql`
query CUSTOMIZATIONS($catalogueId: ID!) {
  customizations(catalogueId: $catalogueId) {
    id
    title
    required
    minSelect
    maxSelect
    options {
      name
      price
    }
  }
}
`

export const DELETE_CUSTOMIZATION = gql`
mutation DeleteCustomization($customizationId: ID!, $catalogueId: ID!) {
  deleteCustomization(customizationId: $customizationId, catalogueId: $catalogueId) {
    id
  }
}
`
