import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { GET_CUSTOMIZATIONS } from '../../graphql/products'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(customization, customizations, theme) {
  return {
    fontWeight:
    customizations.indexOf(customization) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  }
}

export default function SelectCustomizations({ values, updateValues }) {
  const theme = useTheme()
  const [customizations, setCustomizations] = useState([])
  const { catalogueId } = useParams()
  const { loading } = useQuery(GET_CUSTOMIZATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      catalogueId,
    },
    onError: () => {},
    onCompleted: (data) => { setCustomizations(data.customizations) },
  })

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    updateValues(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  return (
    <div>
      <FormControl sx={{ mb: 2 }} fullWidth>
        <InputLabel>Customizations</InputLabel>
        <Select
          multiple
          value={values}
          onChange={handleChange}
          input={<OutlinedInput label="Customizations" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={JSON.parse(value).title} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {loading ? <MenuItem value="">loading customizations...</MenuItem> : null}
          {customizations.map((c) => (
            <MenuItem
              key={c.id}
              value={JSON.stringify({ id: c.id, title: c.title })}
              style={getStyles(c, customizations, theme)}
            >
              {c.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
