/* eslint-disable react/no-array-index-key */
import React from 'react'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { useMutation } from '@apollo/client'
import DeleteIcon from '@mui/icons-material/Delete'
import './styles/ItemImages.css'
import { Box } from '@mui/material'
import { DELETE_IMAGE, UPDATE_CATEGORY } from '../../graphql/products'

function ProductImage({
  file, productId, removeImage,
}) {
  const src = typeof (file) === 'object' ? URL.createObjectURL(file) : file
  const handleErrors = () => {
    // do nothing
  }
  const handleResponse = () => {
    removeImage()
  }

  const [mutate, { loading }] = useMutation(DELETE_IMAGE, { onError: handleErrors, onCompleted: ((res) => handleResponse(res)) })

  const removeImg = () => {
    if (typeof (file) === 'object') {
      removeImage()
    } else {
      mutate({
        variables: {
          productId,
          imageLink: file,
        },
      })
    }
  }

  return (
    <div className={`image-holder ${loading ? 'deleting-opacity' : ''}`}>
      <div className="delete-img" onClick={removeImg}>
        <DeleteIcon style={{ fontSize: '24px', color: 'red' }} />
      </div>
      <img src={src} alt="product" />
    </div>
  )
}

export function CategoryImage({
  image, categoryId, removeImage, handleImageUpload,
}) {
  const src = image && typeof (image) === 'object' ? URL.createObjectURL(image) : image
  const handleErrors = () => {
    // do nothing
  }
  const handleResponse = () => {
    // do nothing
    removeImage()
  }

  const [mutate, { loading }] = useMutation(UPDATE_CATEGORY, { onError: handleErrors, onCompleted: ((res) => handleResponse(res)) })

  const removeImg = () => {
    if (typeof (image) === 'object') {
      removeImage()
    } else {
      mutate({
        variables: {
          categoryId,
          imageUrl: image,
        },
      })
    }
  }
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      { src
        ? (
          <div className={`image-holder ${loading ? 'deleting-opacity' : ''}`}>
            <div className="delete-img" onClick={removeImg}>
              <DeleteIcon style={{ fontSize: '24px', color: 'red' }} />
            </div>
            <img src={src} alt="product" />
          </div>
        )
        : (
          <div className="upload-btn-wrapper">
            <button type="button" className="upload-btn upload-btn-row">
              <h5>Add Image</h5>
              <AddBoxIcon style={{ fontSize: '20px', color: ' #808080' }} />
            </button>
            <input type="file" name="file" accept="images/*" onChange={handleImageUpload} />
          </div>
        )}
    </Box>
  )
}

export default function ItemImages({
  images, required, handleImageUpload, removeImage, productId,
}) {
  return (
    <div className="image-inputs">
      <div className="upload-btn-wrapper">
        <button type="button" className="upload-btn upload-btn-row">
          <h5>Add Images</h5>
          <AddBoxIcon style={{ fontSize: '20px', color: ' #808080' }} />
        </button>
        <input type="file" name="file" accept="images/*" multiple required={required} onChange={handleImageUpload} />
      </div>
      <div className="item-images">
        { images?.map((img, index) => <ProductImage key={index} file={img} productId={productId} removeImage={() => removeImage(index)} />) }
      </div>
    </div>
  )
}
