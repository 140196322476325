/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import './styles/Variants.css'
import {
  Box, Button, Divider, FormControl, IconButton, InputAdornment, OutlinedInput, TextField, Typography,
} from '@mui/material'
import { Delete, KeyboardArrowUp } from '@mui/icons-material'

function Variant({
  index, variant, deleteOption, handleOptionChange,
}) {
  const { name, price, discountPrice } = variant
  return (
    <Box sx={{ mt: 1 }}>
      <FormControl sx={{ mb: 1, width: '100%' }} variant="filled">
        <OutlinedInput
          id="variant-name"
          placeholder="Variant name"
          size="small"
          required
          value={name}
          onChange={(e) => { handleOptionChange(index, 'name', e) }}
          sx={{ mb: '5px' }}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                onClick={() => { deleteOption(index) }}
                edge="end"
              >
                <Delete />
              </IconButton>
            </InputAdornment>
            )}
        />
      </FormControl>
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
      }}
      >
        <TextField
          label="Price(Ksh)"
          type="number"
          value={price}
          onChange={(e) => { handleOptionChange(index, 'price', e) }}
          required
          size="small"
          sx={{ flex: 0.48 }}
        />
        <TextField
          label="Discount Price(Ksh)"
          type="number"
          value={discountPrice}
          onChange={(e) => { handleOptionChange(index, 'discountPrice', e) }}
          size="small"
          sx={{ flex: 0.48 }}
        />
      </Box>
      <Divider sx={{ mt: 1 }} />
    </Box>
  )
}

export default function ProductVariants({
  hasOptions, custom, setHasOptions, variants, addOption, deleteOption, handleOptionChange,
}) {
  const [hidden, toggleHidden] = useState(false)
  return (
    <Box sx={{ marginY: 2 }}>
      {!custom ? (
        <>
          <Box sx={{
            width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <Typography>Variants</Typography>
            { hidden ? (<KeyboardArrowDownIcon onClick={() => { toggleHidden(false) }} style={{ fontSize: '23px', color: ' #000' }} />)
              : (<KeyboardArrowUp onClick={() => { toggleHidden(true) }} style={{ fontSize: '23px', color: ' #000' }} />) }
          </Box>
          <div className="variant-check">
            <input type="checkbox" checked={hasOptions} onChange={setHasOptions} />
            <p>This product has multiple price options</p>
          </div>
        </>
      ) : null}
      {
        !hidden ? (
          <Box sx={{ transition: 'ease' }}>
            { hasOptions ? variants.map((v, i) => <Variant key={i} index={i} variant={v} deleteOption={deleteOption} handleOptionChange={handleOptionChange} />) : null }
          </Box>
        ) : null
      }
      { hasOptions && !hidden ? <Button size="small" sx={{ textDecoration: 'underline', textTransform: 'capitalize' }} onClick={addOption}>Add Variant</Button> : null }
    </Box>
  )
}
