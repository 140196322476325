import React, { useEffect, useState } from 'react'
import { Delete, Edit } from '@mui/icons-material'
import {
  Box, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip,
} from '@mui/material'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import ErrorLoading from '../layout/ErrorLoading'
import { wordsSearch } from '../../utils/search'
import NoData from '../layout/NoData'
import DeleteDialog from '../shared/DeleteDialog'

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api' : 'https://sokolink-api.herokuapp.com/api'

function Template({ template, firebaseUser, removeTemplate }) {
  const {
    name, category, status, quality_score: score,
  } = template
  const [deleteOpen, toggleDelete] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    try {
      setLoading(true)
      const token = await firebaseUser?.getIdToken()
      // Send DEL request to the server
      const url = `${baseUrl}/waba/templates?name=${name}`
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          AuthToken: `${token}`,
        },
      })
      if (!response.ok) {
        throw new Error('Failed to remove template')
      }
      setLoading(false)
      removeTemplate(name)
      toast.success('Template removed successfully')
      toggleDelete(false)
    } catch (error) {
      setLoading(false)
      toast.error('Error removing template:', error.message)
    }
  }

  return (
    <>
      { deleteOpen ? (
        <DeleteDialog
          visible
          title="Remove Template"
          msg={`Are you sure you want to remove template ${name}`}
          onDelete={handleDelete}
          loading={loading}
          close={() => (toggleDelete(false))}
          maxWidth="xs"
        />
      ) : null }
      <tr className="template">
        <td style={{ minWidth: '150px' }}>
          <p>{name}</p>
        </td>
        <td style={{ minWidth: '100px' }}>
          <p>{status}</p>
        </td>
        <td style={{ minWidth: '100px' }}>
          <p>{category}</p>
        </td>
        <td style={{ minWidth: '100px' }}>
          <p>{score?.score || '--'}</p>
        </td>
        <td style={{ minWidth: '200px' }}>
          <Tooltip title="Edit" placement="bottom">
            <Link to={`/business/whatsapp/templates/${name}`}>
              <IconButton sx={{ mr: 5 }} onClick={() => {}}>
                <Edit />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Delete" placement="bottom">
            <IconButton onClick={() => { toggleDelete(!deleteOpen) }}>
              <Delete />
            </IconButton>
          </Tooltip>
        </td>
      </tr>
    </>
  )
}

function TemplatesTable({ firebaseUser }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [templates, setTemplates] = useState([])
  const [filteredTemplates, setFilteredTemplates] = useState([])
  const [status, setStatus] = useState('all')

  const statusArray = ['APPROVED', 'IN_APPEAL', 'PENDING', 'REJECTED', 'PENDING_DELETION', 'DELETED', 'DISABLED', 'PAUSED', 'LIMIT_EXCEEDED']

  const removeTemplate = (name) => {
    setFilteredTemplates(filteredTemplates.filter((t) => (t.name !== name)))
  }

  useEffect(async () => {
    try {
      setLoading(true)
      const token = await firebaseUser?.getIdToken()
      // Send GET request to the server
      const response = await fetch(`${baseUrl}/waba/templates?${status === 'all' ? '' : `status=${status}`}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          AuthToken: `${token}`,
        },
      })
      if (!response.ok) {
        setLoading(false)
        setError(true)
      }
      const res = await response.json()
      setTemplates(res.data)
      setFilteredTemplates(res.data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(e)
    }
  }, [status])

  if (loading) {
    return (
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', my: 3,
      }}
      >
        <CircularProgress size={20} />
      </Box>
    )
  }

  if (error) return (<ErrorLoading visible reload={() => { window.location.reload() }} />)

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
        <FormControl sx={{ mr: 3 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={status}
            label="Status"
            onChange={(e) => { setStatus(e.target.value) }}
            type="text"
            size="small"
          >
            <MenuItem value="all">ALL</MenuItem>
            {statusArray.map((s, index) => (
              <MenuItem key={`${index + s}`} value={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          disabled={loading || error}
          type="search"
          size="small"
          onChange={(e) => {
            if (e.target.value) {
              setFilteredTemplates(templates.filter((i) => (wordsSearch(i.name, [e.target.value]))))
              return
            }
            setFilteredTemplates(templates)
          }}
          label="Search name..."
          variant="outlined"
        />
      </Box>
      <div className="table-container">
        <table className="templates-table">
          <thead>
            <tr>
              <th className="name">Name</th>
              <th className="status">Status</th>
              <th className="category">Category</th>
              <th className="score">Quality Score</th>
              <th className="actions-hd">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredTemplates.map((t) => <Template key={t.id} template={t} firebaseUser={firebaseUser} removeTemplate={removeTemplate} />)}
          </tbody>
        </table>
      </div>
      <NoData visible={!loading && !error && !filteredTemplates?.length} />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  firebaseUser: state.user.user,
})
export default connect(mapStateToProps, { })(TemplatesTable)
