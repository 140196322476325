/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import {
  TextField, Button, Typography, Grid, Box, Paper, IconButton, Popover, FormControl, InputLabel, Select, MenuItem, Divider,
} from '@mui/material'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import AddIcon from '@mui/icons-material/Add'
import EmojiPicker from 'emoji-picker-react'
import {
  CloudUpload, Delete, Launch,
} from '@mui/icons-material'
import toast from 'react-hot-toast'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import VisuallyHiddenInput from '../shared/VisuallyHiddenInput'

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api' : 'https://sokolink-api.herokuapp.com/api'

// Preview component to display chat message
export function ChatPreview({ components, headerImageSrc, bodyParams }) {
  const renderMessage = (component) => {
    switch (component.type) {
      case 'HEADER':
        const src = headerImageSrc || component.example?.header_handle[0]
        return (
          <Box>
            { component.format === 'IMAGE'
              ? (
                <Box sx={{
                  width: '100%', height: '200px', border: '1px solid #ddd', borderRadius: '10px',
                }}
                >
                  <img
                    src={src}
                    alt="header"
                    style={{
                      width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px',
                    }}
                  />
                </Box>
              ) : <Typography variant="body1">{component.text}</Typography>}
          </Box>
        )
      case 'BODY':
        const sampleData = bodyParams || component.example?.body_text?.[0] || []
        const replacedText = component.text.replace(/\{\{(\d+)\}\}/g, (_, variable) => {
          const data = sampleData[parseInt(variable, 10) - 1]
          return data !== undefined && data !== '' ? data : `{{${variable}}}`
        })
        // Replace remaining placeholders with their original form if no sample data is available
        const finalText = replacedText.replace(/\{\{(\d+)\}\}/g, (_, variable) => `{{${variable}}}`)

        return (
          <Typography
            variant="body2"
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: finalText.replace(/\n/g, '<br />') }}
          />
        )
      case 'BUTTONS':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            { component.buttons.length ? <Divider /> : null }
            {component.buttons.map((button, index) => (
              <Button startIcon={<Launch />} key={index} variant="text" color="primary" sx={{ m: '4px', textTransform: 'capitalize' }}>
                {button.text}
              </Button>
            ))}
          </Box>
        )
      default:
        return null
    }
  }

  return (
    <Paper variant="outlined" elevation={3} style={{ padding: '16px', minHeight: '200px' }}>
      {components.map((component, index) => (
        <div key={index} style={{ marginBottom: '8px' }}>
          {renderMessage(component)}
        </div>
      ))}
    </Paper>
  )
}

const TemplateBuilder = ({ merchant, firebaseUser, template: existingTemplate }) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [headerImage, setHeaderImage] = useState(null)

  const defaultTemplate = {
    name: 'sokolink_',
    components: [
      {
        type: 'HEADER',
        format: 'TEXT',
        text: '',
      },
      {
        type: 'BODY',
        text: '',
        example: {
          body_text: [
            [],
          ],
        },
      },
      {
        type: 'BUTTONS',
        buttons: [],
      },
    ],
    language: 'en_GB',
    category: 'MARKETING',
  }
  const initialTemplate = existingTemplate ? { ...existingTemplate } : defaultTemplate
  // Sort components by type: HEADER, BODY, BUTTONS
  const sortedComponents = [];
  ['HEADER', 'BODY', 'BUTTONS'].forEach((type) => {
    const defaultComponent = defaultTemplate.components.find((component) => component.type === type)
    const existingComponent = initialTemplate.components.find((component) => component.type === type)
    if (existingComponent) {
      sortedComponents.push(existingComponent)
    } else {
      sortedComponents.push(defaultComponent)
    }
  })

  // Update the template with sorted components
  initialTemplate.components = sortedComponents
  const [template, setTemplate] = useState(initialTemplate)
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleHeaderFormatChange = (index, value) => {
    const updatedComponents = [...template.components]
    if (value === 'TEXT') {
      updatedComponents[index] = {
        type: 'HEADER',
        format: 'TEXT',
        text: '',
      }
    }
    if (value === 'IMAGE') {
      if (!existingTemplate) {
        updatedComponents[index] = {
          type: 'HEADER',
          format: 'IMAGE',
        }
      } else {
        // Assume existing sample header image can't be updated
        // eslint-disable-next-line prefer-destructuring
        updatedComponents[index] = existingTemplate.components[0]
      }
    }
    setTemplate({ ...template, components: updatedComponents })
  }

  const handleHeaderTextChange = (index, value) => {
    const updatedComponents = [...template.components]
    updatedComponents[index].text = value
    setTemplate({ ...template, components: updatedComponents })
  }

  const handleImageUpload = (e) => {
    setHeaderImage(e.target.files[0])
  }

  const handleAddVariable = () => {
    // Get the current body text
    const bodyText = template.components.find((comp) => comp.type === 'BODY').text

    // Find the highest variable count
    const variableRegex = /\{\{(\d+)\}\}/g
    let maxVariableCount = 0
    let match
    // eslint-disable-next-line no-cond-assign
    while ((match = variableRegex.exec(bodyText)) !== null) {
      const currentVariableCount = parseInt(match[1], 10)
      if (currentVariableCount > maxVariableCount) {
        maxVariableCount = currentVariableCount
      }
    }

    // Increment the variable count by one
    const incrementedVariableCount = maxVariableCount + 1

    // Append the incremented variable to the body text
    const newBodyText = `${bodyText} {{${incrementedVariableCount}}}`

    // Update the template with the new body text
    const updatedComponents = [...template.components]
    const bodyComponentIndex = updatedComponents.findIndex((comp) => comp.type === 'BODY')
    updatedComponents[bodyComponentIndex].text = newBodyText
    setTemplate({ ...template, components: updatedComponents })
  }

  const handleEmojiClick = (event) => {
    setAnchorEl(event.currentTarget)
    setEmojiPickerOpen(true)
  }

  const addEmoji = (e) => {
    const { emoji } = e

    // Get the current cursor position in the body text
    const textField = document.getElementById('body-input') // Assuming you have set an id for the TextField
    const cursorPosition = textField.selectionStart

    // Get the current body text
    const bodyText = template.components.find((comp) => comp.type === 'BODY').text

    // Insert the emoji at the cursor position in the body text
    const updatedBodyText = bodyText.slice(0, cursorPosition) + emoji + bodyText.slice(cursorPosition)

    // Update the template with the new body text
    const updatedComponents = [...template.components]
    const bodyComponentIndex = updatedComponents.findIndex((comp) => comp.type === 'BODY')
    updatedComponents[bodyComponentIndex].text = updatedBodyText
    setTemplate({ ...template, components: updatedComponents })
    setAnchorEl(null)
    setEmojiPickerOpen(false)
  }

  const handleBodyTextChange = (index, value) => {
    const updatedComponents = [...template.components]
    updatedComponents[index].text = value

    // Remove sample data if corresponding variable is removed from body text
    const variableMatches = value.match(/\{\{(\d+)\}\}/g)
    if (variableMatches) {
      const uniqueVariables = [...new Set(variableMatches.map((match) => parseInt(match.match(/\d+/)[0], 10)))]
      const bodyComponent = updatedComponents[index]
      const exampleData = bodyComponent.example?.body_text[0] || []

      // Filter out sample data corresponding to the remaining variables
      const updatedExampleData = exampleData.filter((_, i) => uniqueVariables.includes(i + 1))

      // Update the example data in the component
      if (bodyComponent.example) {
        bodyComponent.example.body_text[0] = updatedExampleData
      } else {
        // Handle the case where example does not exist
        bodyComponent.example = { body_text: [updatedExampleData] }
      }
    } else {
      // If no variables are present, clear the example data
      delete updatedComponents[index].example
    }

    setTemplate({ ...template, components: updatedComponents })
  }

  const handleButtonText = (index, btnIndex, value) => {
    const updatedComponents = [...template.components]
    updatedComponents[index].buttons[btnIndex].text = value
    setTemplate({ ...template, components: updatedComponents })
  }

  const handleButtonUrl = (index, btnIndex, value) => {
    const updatedComponents = [...template.components]
    updatedComponents[index].buttons[btnIndex].url = value
    setTemplate({ ...template, components: updatedComponents })
  }

  const handleDeleteButton = (componentIndex, buttonIndex) => {
    const updatedComponents = [...template.components]
    const buttonsComponentIndex = updatedComponents.findIndex((comp) => comp.type === 'BUTTONS')

    // Remove the button at the specified index
    updatedComponents[buttonsComponentIndex].buttons.splice(buttonIndex, 1)

    // Update the state with the modified components
    setTemplate({ ...template, components: updatedComponents })
  }

  const handleAddLinkButton = () => {
    // Check the number of buttons in the template components
    const buttonsCount = template.components.filter((comp) => comp.type === 'BUTTONS')[0]?.buttons.length || 0

    const storeLink = merchant?.customDomain ? `https://${merchant?.customDomain}` : `https://${merchant?.subdomain}.sokolink.com/`

    // If the number of buttons is less than or equal to one, add a new link button
    if (buttonsCount <= 1) {
      const updatedComponents = [...template.components]
      const buttonsComponentIndex = updatedComponents.findIndex((comp) => comp.type === 'BUTTONS')
      updatedComponents[buttonsComponentIndex].buttons.push({
        type: 'URL',
        text: 'Button Text',
        url: storeLink,
      })
      setTemplate({ ...template, components: updatedComponents })
    }
  }

  const renderSampleDataInputs = (component) => {
    const variableMatches = component.text.match(/\{\{(\d+)\}\}/g)
    if (!variableMatches) return null

    const uniqueVariables = [...new Set(variableMatches.map((match) => parseInt(match.match(/\d+/)[0], 10)))]
    const exampleData = component.example?.body_text[0] || []

    return (
      <Box>
        {uniqueVariables.length && <Typography variant="subtitle1" sx={{ mt: 1, mb: -1, fontSize: 12 }}>Body Sample Data:</Typography>}
        {uniqueVariables.map((variable, i) => (
          <TextField
            size="small"
            required
            key={i}
            label={`{{${variable}}}`}
            value={exampleData[variable - 1] || ''}
            onChange={(e) => {
              const updatedComponents = [...template.components]
              updatedComponents.find((comp) => comp.type === 'BODY').example.body_text[0][variable - 1] = e.target.value
              setTemplate({ ...template, components: updatedComponents })
            }}
            fullWidth
            sx={{ mt: 2 }}
          />
        ))}
      </Box>
    )
  }

  const renderComponent = (component, index) => {
    switch (component.type) {
      case 'HEADER':
        return (
          <Box>
            <Typography sx={{ my: 1 }}>Template Header: </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="header-format">Header Format</InputLabel>
              <Select
                value={component.format}
                labelId="header-format"
                label="Header Format"
                onChange={(e) => { handleHeaderFormatChange(index, e.target.value) }}
                type="text"
                size="small"
              >
                <MenuItem value="TEXT">TEXT</MenuItem>
                <MenuItem value="IMAGE">IMAGE</MenuItem>
              </Select>
            </FormControl>
            { component.format === 'IMAGE'
              ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<CloudUpload />}
                  >
                    Select Image
                    <VisuallyHiddenInput type="file" onChange={handleImageUpload} />
                  </Button>
                  <InputLabel sx={{ ml: 2 }}>{headerImage?.name}</InputLabel>
                </Box>
              ) : (
                <TextField
                  label="Header Text"
                  size="small"
                  required={false}
                  value={component.text}
                  onChange={(e) => handleHeaderTextChange(index, e.target.value)}
                  fullWidth
                />
              )}
          </Box>
        )
      case 'BODY':
        return (
          <Box>
            <Typography sx={{ my: 1 }}>Template Body: </Typography>
            <TextField
              id="body-input"
              label="Body Text"
              value={component.text}
              onChange={(e) => handleBodyTextChange(index, e.target.value)}
              fullWidth
              multiline
              required
              rows={6}
              InputProps={{
                endAdornment: (
                  <div style={{ position: 'absolute', bottom: '8px', right: '8px' }}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <IconButton onClick={handleEmojiClick} size="small">
                          <InsertEmoticonIcon />
                        </IconButton>
                        <Popover
                          open={emojiPickerOpen}
                          anchorEl={anchorEl}
                          onClose={() => setEmojiPickerOpen(false)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <EmojiPicker onEmojiClick={addEmoji} />
                        </Popover>
                      </Grid>
                      <Grid item>
                        <Button size="small" startIcon={<AddIcon />} sx={{ textTransform: 'capitalize' }} onClick={handleAddVariable}>
                          Add Variable
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                ),
              }}
            />
            {renderSampleDataInputs(component)}
          </Box>
        )
      case 'BUTTONS':
        return (
          <Box>
            <Typography sx={{ my: 1 }}>Template Buttons: </Typography>
            {component.buttons.map((button, btnIndex) => (
              <Box
                key={btnIndex}
                sx={{
                  display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                }}
              >
                <TextField
                  required
                  label="Button text"
                  value={button.text}
                  onChange={(e) => handleButtonText(index, btnIndex, e.target.value)}
                  fullWidth
                  size="small"
                  sx={{ flex: 0.47 }}
                />
                <TextField
                  required
                  type="url"
                  label="Website URL"
                  value={button.url}
                  onChange={(e) => handleButtonUrl(index, btnIndex, e.target.value)}
                  fullWidth
                  sx={{ flex: 0.47 }}
                  placeholder="https://example.com"
                  size="small"
                />
                <IconButton onClick={() => handleDeleteButton(index, btnIndex)} size="small">
                  <Delete />
                </IconButton>
              </Box>
            ))}
            <Button
              size="small"
              startIcon={<AddIcon />}
              sx={{ textTransform: 'capitalize' }}
              onClick={handleAddLinkButton}
              disabled={template.components.filter((comp) => comp.type === 'BUTTONS')[0]?.buttons.length >= 1}
            >
              Add Store Link Button
            </Button>
          </Box>
        )
      default:
        return null
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const update = !!template.id
    try {
      const finalTemplate = { ...template }
      // Remove Empty Sections
      const filteredComponents = template.components.filter((component) => {
        if (component.type === 'HEADER' && component.format === 'TEXT' && component.text.trim() === '') {
          return false // Remove if it's a HEADER component with no text
        }
        if (component.type === 'BUTTONS' && component.buttons.length === 0) {
          return false // Remove if it's a BUTTONS component and has no buttons
        }
        return true // Keep all other components
      })
      finalTemplate.components = filteredComponents
      setLoading(true)
      const token = await firebaseUser?.getIdToken()

      // Create FormData object
      const formData = new FormData()
      formData.append('template', JSON.stringify(finalTemplate))

      // Check if there's a headerImage file to upload
      if (headerImage) {
        formData.append('headerImage', headerImage)
      }

      // Send POST request to the server
      const url = update ? `${baseUrl}/waba/templates/update` : `${baseUrl}/waba/templates`
      const headers = {
        AuthToken: token,
      }

      const options = {
        method: 'POST',
        headers,
        body: update ? JSON.stringify(finalTemplate) : formData,
      }

      if (update) {
        headers['Content-Type'] = 'application/json'
      }
      const response = await fetch(url, options)
      if (!response.ok) {
        throw new Error(`Failed to ${update ? 'update' : 'create'} template`)
      }

      const res = await response.json()
      const newTemplate = res.data
      toast.success(update ? 'Template updated successfully' : 'Template created successfully')
      if (!update) {
        history.push(newTemplate.name)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(`Error ${update ? 'updating' : 'creating'} template: ${error.message}`)
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2, pt: 2, pb: 1,
      }}
      >
        <Typography variant="h5" fontSize={20}>{`${template.id ? 'Edit' : 'Create'} Whatsapp Template`}</Typography>
        <Button variant="contained" color="primary" type="submit" disabled={loading}>
          { loading ? 'Submitting...' : 'Submit Template' }
        </Button>
      </Box>
      <Divider />
      <Grid container spacing={2} sx={{ px: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ my: 1 }}>Template Details:</Typography>
          <FormControl fullWidth>
            <InputLabel id="template-category-select">Template Category</InputLabel>
            <Select
              labelId="template-category-select"
              value={template.category}
              label="Template Category"
              size="small"
            >
              <MenuItem value="MARKETING">MARKETING</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Template Name"
            size="small"
            required
            value={template.name}
            onChange={(e) => {
              const value = e.target.value?.trim().replace(/\s+/g, '_') // Remove leading/trailing spaces and replace spaces with underscores
              const newName = value && value.startsWith('sokolink_') ? `${value}` : 'sokolink_' // Add 'sokolink_' only if there's a value, otherwise empty string
              setTemplate({ ...template, name: newName })
            }}
            fullWidth
            sx={{ mt: 2 }}
          />
          {template.components.map((component, index) => (
            <Grid item xs={12} key={index}>
              {renderComponent(component, index)}
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography sx={{ my: 1 }}>Message Preview</Typography>
            <ChatPreview components={template.components} headerImageSrc={headerImage ? URL.createObjectURL(headerImage) : null} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  firebaseUser: state.user.user,
  merchant: state.merchant.merchantInfo,
})
export default connect(mapStateToProps, { })(TemplateBuilder)
