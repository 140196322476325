import { Button, Card, CardContent } from '@mui/material'
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import ChangeStoreLink from './ChangeLink'

export default function WelcomeCard({ merchant, updateMerchant }) {
  const [changeOpen, setChangeOpen] = useState(false)
  return (
    <>
      { changeOpen ? <ChangeStoreLink link={merchant?.subdomain} updateMerchant={updateMerchant} close={() => { setChangeOpen(false) }} /> : null}
      <Card
        variant="outlined"
        sx={{
          width: '100%', height: 'fit-content', mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
      >
        <MobileFriendlyIcon color="info" style={{ fontSize: 50 }} />
        <CardContent sx={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <Typography variant="h6" component="div">
            Your customers can now place direct orders through your own branded online store.
          </Typography>
          <Button
            size="small"
            variant="outlined"
            sx={{ mt: 1 }}
            onClick={() => { setChangeOpen(true) }}
          >
            Change Store Link
          </Button>
        </CardContent>
      </Card>
    </>
  )
}
