import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { useMutation } from '@apollo/client'
import Papa from 'papaparse'
import FileSaver from 'file-saver'
import { useParams } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import BtnLoader from '../spinners/BtnLoader'
import CategorySelect from '../add-item/CategorySelect'
import '../../styles/add-item.css'
import { ADD_PRODUCTS } from '../../graphql/products'
import ResponsiveBigModal from '../layout/ResponsiveModal'

const validateCsvData = (data) => {
  data.forEach((p) => {
    if (!p['Product Name']) toast.error("An item in your file don't have a name, check and try again", { id: 'file-error', duration: 30000 })
    if (!p['Base Price']) toast.error("An item in your file don't have a price, check and try again", { id: 'file-error', duration: 30000 })
    if (!p['Items in Stock']) toast.error("An item in your file don't have the number in stock, check and try again", { id: 'file-error', duration: 30000 })
  })
}

export default function UploadBulkModal({ close }) {
  const [category, setCategory] = useState('')
  const [fileName, setFileName] = useState('')
  const [products, setProducts] = useState([])
  const { catalogueId } = useParams()

  const downloadSample = () => {
    const sampleProductDetails = [{
      'Product Name': 'PowerHouse Burger',
      'Base Price': '950',
      'Items in Stock': '10',
      'Product Description': 'Grilled beef, chicken, steak patty, cheese and bbq onion on a bed of lettuce and tomato',
    }]

    const sampleStr = JSON.stringify(sampleProductDetails)
    const csv = Papa.unparse(sampleStr)
    const blob = new Blob([decodeURIComponent(encodeURI(csv))], {
      type: 'text/csv;charset=utf-8;',
    })
    FileSaver.saveAs(blob, 'bulk-sample.csv')
  }

  const handleFileUpload = (e) => {
    setProducts([])
    toast.dismiss('file-error')
    const fileInput = e.target.files[0]
    setFileName(fileInput.name)
    Papa.parse(fileInput, {
      download: true,
      header: true,
      skipEmptyLines: true,
      error: () => { toast.error('error getting data from CSV file') },
      complete: (results) => {
        if (results.data.length === 0) {
          toast.error('No data in the CSV file')
        }
        validateCsvData(results.data)
        const newProducts = results.data.map((p) => ({
          name: p['Product Name'],
          price: parseInt(p['Base Price'], 10),
          inStock: !!parseInt(p['Items in Stock'], 10),
          description: p['Product Description'],
        }))
        setProducts(newProducts)
      },
    })
  }

  const handleErrors = () => {
    toast.error('error adding products')
  }
  const handleResponse = () => {
    toast.success(`Added ${products.length} products to ${category}`)
    close()
  }
  const [addProductsReq, { loading }] = useMutation(ADD_PRODUCTS, { onError: handleErrors, onCompleted: handleResponse })

  const handleSubmit = (e) => {
    e.preventDefault()
    addProductsReq({
      variables: {
        catalogueId,
        categoryId: category.id,
        products,
      },
    })
  }
  return (
    <ResponsiveBigModal
      title="Upload Bulk"
      visible
      cancellable
      cancel={close}
    >
      <form onSubmit={handleSubmit}>
        <Box>
          <div className="create-item-inputs">
            <div className="sample">
              <div>
                To bulk upload products in a category, prepare a csv file like the sample below and upload into that category. &nbsp;
                <u onClick={downloadSample}>Download Sample File</u>
              </div>
            </div>
          </div>
          <div className="create-item-inputs">
            <CategorySelect label="Menu category" required value={category} changeHandler={(e) => setCategory(e.target.value)} />
            <div className="upload-btn-wrapper">
              <button type="button" className="upload-btn upload-btn-row">
                <h5>Select File</h5>
                <AddBoxIcon style={{ fontSize: '20px', color: ' #808080', marginLeft: '10px' }} />
              </button>
              <input type="file" name="file" accept="images/*" multiple required onChange={handleFileUpload} />
            </div>
            <div className="csv-name">{fileName}</div>
          </div>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button type="submit" variant="contained" disabled={loading} sx={{ my: 2 }}>
            {!loading ? 'Upload File' : null}
            <BtnLoader color="#fff" visible={loading} />
          </Button>
        </Box>
      </form>
    </ResponsiveBigModal>
  )
}
