import React, { useState } from 'react'
import {
  Box, Button, Chip, Dialog, DialogActions, DialogContent, Tooltip, Typography,
} from '@mui/material'
import {
  DirectionsBike, DirectionsWalk, TableRestaurant, LocationOn, PersonRounded, WhatsApp, Hail,
} from '@mui/icons-material'
import {
  formatDate, formatMoney, formatOrderLabel, formatWhatsappNumber,
} from '../../utils/formatter'
import ResponsiveBigModal from '../layout/ResponsiveBigModal'
import Map from '../maps/DeliveryMap'
import './styles/OrderModal.css'
import {
  AcceptBtn, CancelBtn, DispatchBtn, CompleteBtn,
} from './OrderBtns'
import AssignRiderDialog from './AssignRiderDialog'

function Actions({ order, updateOrder }) {
  const { orderStatus } = order
  return (
    <DialogActions>
      <AcceptBtn visible={orderStatus === 'pending'} order={order} updateOrder={updateOrder} />
      <CancelBtn visible={orderStatus === 'pending'} order={order} updateOrder={updateOrder} />
      <DispatchBtn visible={orderStatus === 'preparing'} order={order} updateOrder={updateOrder} />
      <CompleteBtn visible={orderStatus === 'dispatched'} order={order} updateOrder={updateOrder} />
    </DialogActions>
  )
}

function DeliveryLocation({ visible, close, deliveryLocation }) {
  return (
    <Dialog fullScreen fullWidth open={visible}>
      <DialogContent sx={{ padding: 0 }}>
        <Map deliveryLocation={deliveryLocation} />
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={close} sx={{ borderRadius: 20 }} variant="contained" fullWidth>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

function OrderItem({ item }) {
  const {
    category, name, quantity, price, instructions, variantOption, customizations,
  } = item
  const variantName = variantOption ? ` - ${variantOption}` : ''
  const hasCustomizations = customizations?.length

  return (
    <Box sx={{
      width: '100%', border: '1px dashed grey', borderRadius: 2, mb: 2,
    }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', m: 1, border: '1px solid #e0e0e0', height: '28px', width: '28px',
        }}
        >
          {quantity}
        </Box>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Tooltip title={category}>
            <Typography sx={{ cursor: 'pointer' }}>{`${name}${variantName}`}</Typography>
          </Tooltip>
          <Typography sx={{ mr: 2 }}>{formatMoney(price * quantity)}</Typography>
        </Box>
      </Box>
      <Box>
        {
          hasCustomizations ? customizations.map((c) => (
            <Box key={c.title} sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
              <Typography>
                {`${c.title} - `}
&nbsp;
              </Typography>
              {c.options.map((o) => <Chip label={o.name} sx={{ mr: 0.5 }} key={o.name} />)}
            </Box>
          )) : null
        }
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
          {instructions ? <Typography fontStyle="italic">{`"${instructions}"`}</Typography> : null}
        </Box>
      </Box>
    </Box>
  )
}

export default function OrderModal({
  visible, order, updateOrder, close,
}) {
  const {
    orderNo, orderType, deliveryType, customer, orderStatus, deliveryFees, postedOn, storeId, rider, amount,
    total, paymentStatus, note, products, deliveryLocation, discountAmount, discountCode, table, serviceFee,
    trackingLink,
  } = order
  const orderTypeLabel = formatOrderLabel(orderType, deliveryType)
  const [mapOpen, toggleMap] = useState(false)
  const [assignOpen, toggleAssign] = useState(false)

  const formattedNumber = formatWhatsappNumber(customer.phone)

  const OrderStatusColor = {
    pending: '#0a82f3',
    preparing: '#b800a9a9',
    dispatched: '#ff009db2',
    completed: '#008000a2',
    cancelled: '#ff7575e7',
  }

  const PaymentStatusColor = {
    unpaid: '#0a82f3',
    paid: '#008000a2',
    reversed: '#ff7575e7',
  }

  return (
    <>
      <ResponsiveBigModal visible={visible} title={`#${orderNo}`} cancellable cancel={close} dialogActions={<Actions order={order} updateOrder={updateOrder} />}>
        <div className="order-modal">
          <div className="order-details">
            <Box
              width="100%"
              border="1px solid #ddd"
              sx={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 0.5, mb: 1, borderRadius: 2,
              }}
            >
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  { orderType === 'delivery' ? <DirectionsBike sx={{ color: 'gray', mr: 1 }} /> : null }
                  { orderType === 'pickup' ? <DirectionsWalk sx={{ color: 'gray', mr: 1 }} /> : null }
                  { orderType === 'dinein' ? <TableRestaurant sx={{ color: 'gray', mr: 1 }} /> : null }
                  { orderType === 'takeaway' ? <Hail sx={{ color: 'gray', mr: 1 }} /> : null }
                  <Typography fontWeight={500}>{orderTypeLabel}</Typography>
                </Box>
                <Typography fontSize={12}>{formatDate(postedOn)}</Typography>
              </Box>
              <Chip label={orderStatus} sx={{ color: '#fff', background: OrderStatusColor[orderStatus] }} />
            </Box>
            <Box
              width="100%"
              border="1px solid #ddd"
              sx={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 0.5, mb: 1, borderRadius: 2,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PersonRounded sx={{ fontSize: 30, mr: 1, color: 'gray' }} />
                <Box>
                  <Typography fontWeight={500}>{customer.name}</Typography>
                  <Typography>{customer.phone}</Typography>
                </Box>
              </Box>
              <a href={`https://wa.me/${formattedNumber}`} rel="noreferrer" target="_blank">
                <WhatsApp sx={{ color: 'green', fontSize: 30, mr: 1 }} />
              </a>
            </Box>
            <Box
              width="100%"
              border="1px solid #ddd"
              sx={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 0.5, mb: 1, borderRadius: 2,
              }}
            >
              <Box>
                <Typography fontSize={10}>{`Order Amount - ${formatMoney(amount)}`}</Typography>
                {discountAmount ? <Typography fontSize={10}>{`Discount - ${formatMoney(discountAmount)} - ${discountCode}`}</Typography> : null}
                <Typography fontSize={10}>{`Delivery Fees - ${formatMoney(deliveryFees)}`}</Typography>
                <Typography fontSize={10}>{`Service Fee - ${formatMoney(serviceFee)}`}</Typography>
                <Typography fontSize={13} fontWeight={700}>{`Total Amount - ${formatMoney(total)}`}</Typography>
              </Box>
              <Chip label={paymentStatus} sx={{ color: '#fff', background: PaymentStatusColor[paymentStatus] }} />
            </Box>
            <Box
              width="100%"
              border="1px solid #ddd"
              sx={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 0.5, mb: 1, borderRadius: 2,
              }}
            >
              <Box>
                { orderType === 'dinein' ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocationOn sx={{ color: 'red' }} />
                    <Typography fontSize={12}>{`${table?.room?.name} - ${table?.name}`}</Typography>
                  </Box>
                ) : null }
                { orderType === 'delivery' ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocationOn sx={{ color: 'red' }} />
                    <Typography fontSize={12}>{deliveryLocation?.name}</Typography>
                  </Box>
                ) : null }
                <Typography fontSize={12} fontStyle="italic">{note}</Typography>
              </Box>
              {orderType === 'delivery' ? (
                <Button onClick={() => (toggleMap(true))} sx={{ minWidth: 110, textTransform: 'capitalize' }}>
                  <u>See on Map</u>
                </Button>
              ) : null}
              <DeliveryLocation visible={mapOpen} close={() => (toggleMap(false))} deliveryLocation={deliveryLocation} />
            </Box>
            { orderType === 'delivery' && deliveryType === 'self' ? (
              <Box width="100%" border="1px solid #ddd" sx={{ p: 0.5, mb: 1, borderRadius: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex' }}>
                    <DirectionsBike sx={{ mr: 1 }} />
                    <div>
                      <Typography fontSize={12}>{`${rider?.name || 'Not Assigned'}`}</Typography>
                      <Typography fontSize={12}>{`${rider?.phone || ''}`}</Typography>
                    </div>
                  </Box>
                  <Button onClick={() => (toggleAssign(true))} sx={{ minWidth: 110, textTransform: 'capitalize' }}><u>Assign Rider</u></Button>
                </Box>
                {assignOpen ? <AssignRiderDialog close={() => (toggleAssign(false))} orderNo={orderNo} storeId={storeId} /> : null }
              </Box>
            ) : null }
            { trackingLink ? (
              <Box width="100%" border="1px solid #ddd" sx={{ p: 0.5, mb: 1, borderRadius: 2 }}>
                <Button href={trackingLink} target="_blank"><u>Track Rider</u></Button>
              </Box>
            ) : null}
          </div>
          <div className="order-items">
            { products.map((p, i) => <OrderItem key={p.id} i={i} item={p} />)}
          </div>
        </div>
      </ResponsiveBigModal>
    </>
  )
}
