import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Box, Button } from '@mui/material'
import ErrorLoading from '../components/layout/ErrorLoading'
import CenterLoader from '../components/spinners/CenterLoader'
import '../styles/stores.css'
import { CATALOGUES } from '../graphql/products'
import Pagination from '../components/layout/Pagination'
import AddCatalogue from '../components/products/AddCatalogue'
import CataloguesView from '../components/products/CataloguesView'
import NoData from '../components/layout/NoData'

export default function Catalogues() {
  const [catalogues, setCatalogues] = useState(null)
  const limit = 10
  const [page, setPage] = useState(1)
  const [error, setError] = useState(null)
  const [addModalOpen, toggleAddModal] = useState(false)
  const start = page === 1 ? 0 : (page - 1) * limit

  const handleResponse = (data) => {
    setError(null)
    setCatalogues(data.catalogues)
  }
  const [getCatalogues, { loading }] = useLazyQuery(CATALOGUES, {
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })

  const getCataloguesReq = () => {
    getCatalogues({
      variables: {
        limit,
        offset: start,
      },
    })
  }

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextPage = () => {
    if (!(catalogues.length < limit)) {
      setPage(page + 1)
    }
  }

  const addNewCatalogue = (catalogue) => {
    setCatalogues([catalogue, ...catalogues])
  }

  const updateCatalogue = (catalogue) => {
    const newCoupons = catalogues.map((c) => {
      if (c.id === catalogue.id) return catalogue
      return c
    })
    setCatalogues(newCoupons)
  }

  useEffect(() => {
    getCataloguesReq()
  }, [page])

  return (
    <div className="page-content">
      <Box sx={{
        my: 1.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between',
      }}
      >
        <div className="page-tittle no-margin">Business Catalogues</div>
        <Button variant="contained" onClick={() => { toggleAddModal(true) }}>Create Catalogue</Button>
      </Box>
      {addModalOpen ? (
        <AddCatalogue
          close={() => { toggleAddModal(false) }}
          add={addNewCatalogue}
          update={updateCatalogue}
        />
      ) : null}
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getCatalogues} />
      {!loading && !error && catalogues
        ? <CataloguesView catalogues={catalogues} deleteCatalogue={(id) => setCatalogues(catalogues.filter((el) => el.id !== id))} /> : null}
      <NoData visible={!loading && !error && !catalogues?.length} />
      <Pagination visible={!loading && !error && (catalogues ? catalogues.length : false)} page={page} next={nextPage} prev={prevPage} />
    </div>
  )
}
