import { React, useState } from 'react'
import {
  Badge,
  Box, Button, DialogActions, Typography,
} from '@mui/material'
import { Task } from '@mui/icons-material'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-hot-toast'
import ResponsiveModal from '../layout/ResponsiveModal'
import ErrorLoading from '../layout/ErrorLoading'
import MainSpinner from '../shared/MainSpinner'
import { ASSIGN_RIDER_ORDER, GET_STORE_RIDERS } from '../../graphql/orders'

function Actions({
  riderId, assign, loading, close,
}) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        disabled={loading || !riderId}
        startIcon={<Task />}
        variant="contained"
        sx={{ mr: 2 }}
        onClick={assign}
      >
        {loading ? 'Assigning...' : 'Assign'}
      </Button>
    </DialogActions>
  )
}

export default function AssignRiderDialog({
  orderNo, storeId, close,
}) {
  const [riders, setRiders] = useState([])
  const [riderId, setRiderId] = useState(null)

  const { loading, error } = useQuery(GET_STORE_RIDERS, {
    variables: { storeId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => { setRiders(data.storeRiders) },
  })

  const [assignReq, { loading: assigning }] = useMutation(ASSIGN_RIDER_ORDER, {
    onError: () => {
      toast.error('error assigning order to rider')
    },
    onCompleted: () => {
      toast.success('rider assigned order successfully')
      close()
    },
  })

  return (
    <ResponsiveModal
      title="Assign Rider"
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions riderId={riderId} assign={() => { assignReq({ variables: { orderNo, riderId } }) }} loading={assigning} close={close} />}
    >
      { loading && <MainSpinner /> }
      { error && <ErrorLoading visible /> }
      { riders.length ? (
        <>
          <Box sx={{
            display: 'flex', alignItems: 'center', bgcolor: 'ButtonShadow', border: '1px solid #ddd', borderBottom: 'none',
          }}
          >
            <Box sx={{ flex: 4, padding: 1 }}><Typography>Details</Typography></Box>
            <Box sx={{ flex: 3, padding: 1 }}><Typography textAlign="center">Status</Typography></Box>
            <Box sx={{ flex: 3, padding: 1 }}><Typography textAlign="center">Distance</Typography></Box>
          </Box>
          <Box sx={{ borderBottom: '1px solid #ddd' }}>
            {
        riders.map((r) => {
          const {
            id, name, phone, storeDistance,
          } = r
          const isActive = riderId === r.id
          const dotColor = r.isOnline ? 'success' : 'error'
          return (
            <Box
              key={id}
              sx={{
                display: 'flex', alignItems: 'center', border: '1px solid #ddd', borderBottom: 'none', bgcolor: isActive ? 'lightblue' : 'none', cursor: 'pointer',
              }}
              onClick={() => { setRiderId(r.id) }}
            >
              <Box sx={{ flex: 4, padding: 1 }}><Typography fontSize={12}>{`${name} ${phone}`}</Typography></Box>
              <Box sx={{
                flex: 3, padding: 1, display: 'flex', alignItems: 'center', justifyContent: 'center',
              }}
              >
                <Badge color={dotColor} variant="dot" />
              </Box>
              <Box sx={{ flex: 3, padding: 1 }}><Typography fontSize={12} textAlign="center">{`${storeDistance || '---'} Kms`}</Typography></Box>
            </Box>
          )
        })
      }
          </Box>
        </>
      ) : null }
    </ResponsiveModal>
  )
}
