import { React, useState } from 'react'
import {
  Box, Button, DialogActions, TextField,
} from '@mui/material'
import { SaveOutlined } from '@mui/icons-material'
import { useMutation } from '@apollo/client'
import { toast } from 'react-hot-toast'
import ResponsiveModal from '../layout/ResponsiveModal'
import { CREATE_ROOM, UPDATE_ROOM } from '../../graphql/rooms'

function Actions({ close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<SaveOutlined />}
        variant="contained"
        sx={{ mr: 2 }}
        form="save-room-form"
      >
        {loading ? 'Saving...' : 'Save Room'}
      </Button>
    </DialogActions>
  )
}

export default function SaveRoomDialog({
  close, storeId, room, addRoom, updateRoom,
}) {
  const [name, setName] = useState(room?.name || '')

  const [createReq, { loading }] = useMutation(CREATE_ROOM, {
    onError: () => {
      toast.error('error creating room')
    },
    onCompleted: (data) => {
      addRoom(data.createRoom)
      toast.success('room created successfully')
      close()
    },
  })

  const [updateReq, { loading: updating }] = useMutation(UPDATE_ROOM, {
    onError: () => {
      toast.error('error updating room')
    },
    onCompleted: (data) => {
      updateRoom(data.updateRoom)
      toast.success('room updated successfully')
      close()
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (room) {
      updateReq({ variables: { id: room.id, name } })
    } else {
      createReq({ variables: { storeId, name } })
    }
  }
  return (
    <ResponsiveModal
      title={room ? 'Edit Room' : 'Create Room'}
      visible
      cancellable
      cancel={close}
      sx={{ margin: 4 }}
      maxWidth="xs"
      dialogActions={<Actions onSave={() => {}} loading={loading || updating} close={close} />}
    >
      <Box component="form" id="save-room-form" onSubmit={handleSubmit}>
        <TextField fullWidth type="text" size="small" required value={name} onChange={(e) => { setName(e.target.value) }} label="Room Name" variant="outlined" style={{ flex: 0.48 }} />
      </Box>
    </ResponsiveModal>
  )
}
