import React from 'react'
import { formatDate, formatMoney } from '../../utils/formatter'
import ResponsiveModal from '../layout/ResponsiveModal'
import './styles/TransactionModal.css'

export default function SettlementModal({ visible, transaction, close }) {
  const {
    transactionId, transactionType, transactionStatus, postedOn, payoutBankName, payoutAccountName, payoutAccountNumber, grossAmount, gateWayCharges, totalCharges,
    netAmount,
  } = transaction
  return (
    <>
      <ResponsiveModal visible={visible} title="Transaction Details" cancellable cancel={close}>
        <div className="transaction-modal">
          <h5>{`Transaction Id:  ${transactionId}`}</h5>
          <p>{`Transaction Type: ${transactionType}`}</p>
          <p>
            Transaction Status:
            <span className={transactionStatus}>{transactionStatus}</span>
          </p>
          <p>{`Gross Amount: ${formatMoney(grossAmount)}`}</p>
          <p>{`Payment Gateway Charges: ${formatMoney(gateWayCharges)}`}</p>
          <p>{`Total Charges: ${formatMoney(totalCharges)}`}</p>
          <p>{`Net Amount: ${formatMoney(netAmount)}`}</p>
          <p>{`Payout Bank Name: ${payoutBankName}`}</p>
          <p>{`Payout Account Name: ${payoutAccountName}`}</p>
          <p>{`Payout Account Number: ${payoutAccountNumber}`}</p>
          <p>{`Date: ${formatDate(postedOn)}`}</p>
        </div>
      </ResponsiveModal>
    </>
  )
}
