import { gql } from '@apollo/client'

export const USER = gql`
query {
  user {
    id
    username
    name
    email
    role
    isVerified
  }
}
`

export const APP_INIT = gql`
query {
  user {
    id
    username
    name
    email
    role
    merchantId
    storeId
    isVerified
  }
  merchant {
    id
    businessName
    subdomain
    customDomain
    status
    logo
    primaryColor
    secondaryColor
  }
}
`

export const REGISTER = gql`
mutation REGISTER(
  $email: String!
  $phone: String!
  $businessName: String!
  $password: String!
) {
  signup(
    email: $email
    phone: $phone
    businessName: $businessName
    password: $password
  ) {
    id
    email
    name
    role
    merchantId
    storeId
    isVerified
  }
}
`
export const UPDATE_USER = gql`
mutation UpdateUserInfo($name: String!) {
  updateUser(userName: $name) {
    id
    email
    name
  }
}
`
