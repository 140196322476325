import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import SaveProductModal from './SaveProductModal'
import UploadBulkModal from './UploadBulkModal'
import SaveCustomization from './SaveCustomization'

export default function ProductsTabs({ value, handleChange }) {
  const [addOpen, toggleAdd] = useState(false)
  const [bulkOpen, setBulkOpen] = useState(false)
  const [createOpen, toggleOpen] = useState(false)
  return (
    <Box sx={{
      borderBottom: 1,
      borderColor: 'divider',
      mb: 2,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
    >
      {addOpen ? <SaveProductModal close={() => { toggleAdd(false) }} /> : null}
      { bulkOpen ? <UploadBulkModal close={() => { setBulkOpen(false) }} /> : null }
      { createOpen ? <SaveCustomization close={() => { toggleOpen(false) }} /> : null }
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Products" value="categories" />
        <Tab label="Customizations" value="customizations" />
      </Tabs>
      <Box>
        { value === 'categories' ? (
          <>
            <Button
              variant="contained"
              onClick={() => { toggleAdd(true) }}
            >
              Add Product
            </Button>
            <Button
              variant="outlined"
              sx={{ ml: 1, mr: 1 }}
              onClick={() => { setBulkOpen(true) }}
            >
              Bulk Upload
            </Button>
          </>
        ) : (
          <Button variant="contained" sx={{ mr: 1 }} onClick={() => { toggleOpen(true) }}>Add Customization</Button>
        )}
      </Box>
    </Box>
  )
}
