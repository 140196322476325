import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import firebase from '../../services/firebase'
import ResponsiveModal from '../layout/ResponsiveModal'
import BtnLoader from '../spinners/BtnLoader'
import OverviewInput from '../inputs/OverviewInput'

export default function ResetModal({ visible, close }) {
  if (!visible) { return null }
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        setLoading(false)
        toast.success('Password reset link sent, check your inbox.')
      })
      .catch((error) => {
        setLoading(false)
        const errorMessage = error.message
        toast.error(errorMessage)
      })
  }
  return (
    <>
      <ResponsiveModal visible title="Reset Password" cancellable cancel={close}>
        <form className="modal-form" onSubmit={handleSubmit}>
          <OverviewInput type="email" required label="Email" placeholder="your email" value={email} changeHandler={(e) => setEmail(e.target.value)} />
          <button type="submit" disabled={loading} className="auth-submit">
            {!loading && <h5>Send Link</h5>}
            <BtnLoader visible={loading} color="#fff" />
          </button>
        </form>
      </ResponsiveModal>
    </>
  )
}
