import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Box, Button } from '@mui/material'
import ErrorLoading from '../components/layout/ErrorLoading'
import CenterLoader from '../components/spinners/CenterLoader'
import Pagination from '../components/layout/Pagination'
import { USERS } from '../graphql/users'
import SaveUserDialog from '../components/users/SaveUserDialog'
import UsersView from '../components/users/UsersView'

export default function Users() {
  const [users, setUsers] = useState(null)
  const limit = 10
  const [page, setPage] = useState(1)
  const [error, setError] = useState(null)
  const [addOpen, toggleAdd] = useState(false)
  const start = page === 1 ? 0 : (page - 1) * limit

  const handleResponse = (data) => {
    setError(null)
    setUsers(data.users)
  }
  const [getUsers, { loading }] = useLazyQuery(USERS, {
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  const getUsersReq = () => {
    getUsers({
      variables: {
        limit,
        offset: start,
      },
    })
  }
  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextPage = () => {
    if (!(users.length < limit)) {
      setPage(page + 1)
    }
  }
  useEffect(() => {
    getUsersReq()
  }, [page])

  return (
    <div className="page-content">
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
      }}
      >
        <div className="page-tittle no-margin">Employees</div>
        <Button variant="contained" onClick={() => { toggleAdd(true) }}>Add User</Button>
      </Box>
      { addOpen ? <SaveUserDialog reload={getUsersReq} add={(u) => (setUsers([...users, u]))} close={() => (toggleAdd(false))} /> : null }
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getUsersReq} />
      {!loading && !error && users ? <UsersView users={users} reload={getUsersReq} removeUser={(id) => (setUsers(users.filter((u) => (u.id !== id))))} /> : null }
      <Pagination visible={!loading && !error} page={page} next={nextPage} prev={prevPage} />
    </div>
  )
}
