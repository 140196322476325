/* eslint-disable react/jsx-props-no-spreading */
import { React, useState } from 'react'
import {
  Box, Button, DialogActions, TextField,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import ResponsiveModal from '../layout/ResponsiveModal'
import { ADD_CATALOGUE, EDIT_CATALOGUE } from '../../graphql/products'
import SelectStores from '../locations/SelectStores'

function Actions({ close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mr: 2 }}
        form="add-catalogue-form"
      >
        {loading ? 'Saving...' : 'Save Catalogue'}
      </Button>
    </DialogActions>
  )
}

export default function AddCatalogue({
  close, catalogue, add, update,
}) {
  const [title, setTitle] = useState(catalogue?.title || '')
  const [label, setLabel] = useState(catalogue?.label || '')
  const [stores, setStores] = useState(catalogue?.storeIds || [])

  const [createCatalogueReq, { loading }] = useMutation(ADD_CATALOGUE, {
    onError: () => { toast.error('Error creating catalaogue.') },
    onCompleted: (data) => {
      toast.success('Catalogue created successfully')
      add(data.createCatalogue)
      close()
    },
  })

  const [updateReq, { loading: updating }] = useMutation(EDIT_CATALOGUE, {
    onError: () => { toast.error('Error updating catalogue.') },
    onCompleted: (data) => {
      toast.success(`Catalogue ${title} updated successfully`)
      update(data.updateCatalogue)
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    if (catalogue) {
      updateReq({
        variables: {
          id: catalogue.id,
          title,
          label,
          storeIds: stores,
        },
      })
    } else {
      createCatalogueReq({
        variables: {
          title,
          storeIds: stores,
        },
      })
    }
  }
  return (
    <ResponsiveModal
      title={catalogue ? 'Edit Catalogue' : 'Create Catalogue'}
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions onSave={onSave} loading={loading || updating} close={close} />}
    >
      <Box component="form" id="add-catalogue-form" onSubmit={onSave}>
        <TextField type="text" size="small" required value={title} onChange={(e) => { setTitle(e.target.value) }} label="Title" variant="outlined" fullWidth />
        <TextField type="text" size="small" value={label} onChange={(e) => { setLabel(e.target.value) }} label="Label" helperText="Will appear as catalogue heading on storefront." variant="outlined" fullWidth sx={{ mt: 2 }} />
        <SelectStores hasAll values={stores} changeHandler={(v) => { setStores(v) }} />
      </Box>
    </ResponsiveModal>
  )
}
