import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { formatMoney, formatWhatsappNumber } from '../../utils/formatter'

function WhatsappBtn({ phone }) {
  const formattedNumber = formatWhatsappNumber(phone)
  return (
    <a href={`https://wa.me/${formattedNumber}`} rel="noreferrer" target="_blank" className="whatsapp-btn">
      <WhatsAppIcon sx={{ color: 'green', fontSize: 30, my: 0.8 }} />
    </a>
  )
}

function Customer({ customer }) {
  const {
    name, phone, email, orderStats,
  } = customer
  return (
    <tr>
      <td style={{ minWidth: '150px' }}>
        <p>{name}</p>
      </td>
      <td style={{ minWidth: '150px' }}>
        <p>{phone}</p>
      </td>
      <td style={{ minWidth: '150px' }}>
        <p>{email || '--'}</p>
      </td>
      <td style={{ minWidth: '150px' }}>
        <p>{orderStats.numOfOrders}</p>
      </td>
      <td style={{ minWidth: '150px' }}>
        <p>{formatMoney(orderStats.averageAmount)}</p>
      </td>
      <td style={{ minWidth: '200px' }}>
        <WhatsappBtn phone={phone} />
      </td>
    </tr>
  )
}

export default function CustomersView({ visible, customers, deleteCustomer }) {
  if (!visible) {
    return null
  }
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Phone No</th>
            <th>Email</th>
            <th>Total Orders</th>
            <th>Total Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {customers.map((c) => <Customer key={c.id} customer={c} deleteCustomer={deleteCustomer} />)}
        </tbody>
      </table>
    </div>

  )
}
