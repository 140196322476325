import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import {
  Box,
  Button, DialogActions, TextField,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import ResponsiveModal from '../layout/ResponsiveModal'
import { ADD_CATEGORY, UPDATE_CATEGORY } from '../../graphql/products'
import { CategoryImage } from '../add-item/ItemImages'

function Actions({ close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        form="save-category-form"
        disabled={loading}
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mr: 2 }}
      >
        {loading ? 'Saving...' : 'Save Category'}
      </Button>
    </DialogActions>
  )
}

export default function CreateCategory({
  close, addCategory, updateCategory, category: prevCategory,
}) {
  const [category, setCategory] = useState(prevCategory?.category || '')
  const [details, setDetails] = useState(prevCategory?.details || '')
  const [image, setImage] = useState(prevCategory?.imageUrl || null)
  const { catalogueId } = useParams()

  const handleErrors = () => {
    toast.error('error saving category')
  }

  const handleResponse = (data) => {
    toast.success('category saved')
    if (prevCategory) {
      updateCategory(data.updateCategory)
    } else {
      addCategory(data.addCategory)
    }
    close()
  }

  const [addCategoryReq, { loading }] = useMutation(ADD_CATEGORY, { onError: handleErrors, onCompleted: handleResponse })
  const [updateCategoryReq, { loading: updating }] = useMutation(UPDATE_CATEGORY, { onError: handleErrors, onCompleted: handleResponse })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (prevCategory?.id) {
      updateCategoryReq({
        variables: {
          categoryId: prevCategory?.id,
          category,
          details,
          image: image && typeof (image) === 'object' ? image : null,
        },
      })
    } else {
      addCategoryReq({
        variables: {
          image,
          catalogueId,
          categoryId: prevCategory?.id,
          category,
          details,
        },
      })
    }
  }
  return (
    <ResponsiveModal visible title={prevCategory ? 'Edit Category' : 'Add Category'} cancellable cancel={close} dialogActions={<Actions close={close} loading={loading || updating} />}>
      <Box component="form" onSubmit={handleSubmit} id="save-category-form">
        <TextField type="text" size="small" required value={category} onChange={(e) => setCategory(e.target.value)} label="Category Name" variant="outlined" fullWidth />
        <TextField type="text" sx={{ my: 2 }} multiline size="small" maxRows={3} rows={3} value={details} onChange={(e) => setDetails(e.target.value)} label="Category Description" variant="outlined" fullWidth />
        <CategoryImage image={image} handleImageUpload={(e) => { setImage(e.target.files[0]) }} removeImage={() => { setImage(null) }} categoryId={prevCategory?.id} />
      </Box>
    </ResponsiveModal>
  )
}
