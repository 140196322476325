import React from 'react'
import WhatsappPageHeader from '../../components/whatsapp/WhatsappPageHeader'
import TemplatesTable from '../../components/whatsapp/TemplatesTable'

export default function WhatsappTemplatesPage() {
  return (
    <div className="page-content">
      <WhatsappPageHeader activeTab="templates" />
      <TemplatesTable />
    </div>
  )
}
