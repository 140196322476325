/* eslint-disable no-unused-vars */
import { Download, Search } from '@mui/icons-material'
import {
  Button, DialogActions, Divider,
} from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'
import QRCodeStyling from 'qr-code-styling'
import ResponsiveModal from '../layout/ResponsiveModal'
import { formatMoney, formatShortDate } from '../../utils/formatter'

function QR({ data }) {
  const qrCode = new QRCodeStyling({
    width: 100,
    height: 100,
    dotsOptions: {
      color: '#4267b2',
      type: 'none',
    },
    imageOptions: {
      crossOrigin: 'anonymous',
    },
  })
  const ref = useRef(null)
  useEffect(() => {
    qrCode.append(ref.current)
  }, [])
  useEffect(() => {
    qrCode.update({
      data,
    })
  }, [])
  return <div ref={ref} />
}

function Actions({ onDownload, close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        onClick={onDownload}
        startIcon={<Download />}
        variant="contained"
        sx={{ mr: 2 }}
      >
        {loading ? 'Downloading...' : 'Download'}
      </Button>
    </DialogActions>
  )
}

function DownloadCouponDialog({
  coupon, visible, close, merchant,
}) {
  const {
    title, discountType,
    discountAmount, code, maxUsageLimit,
    // startDate, endDate
  } = coupon
  const { subdomain, logo, primaryColor } = merchant
  const link = `${subdomain}.sokolink.com`
  // const period = `${formatShortDate(startDate)} - ${formatShortDate(endDate)}`
  const amount = discountType === 'fixed' ? formatMoney(discountAmount) : `${discountAmount}%`

  const downloadCoupon = () => {
    const node = document.getElementById('coupon-holder')
    htmlToImage.toPng(node)
      .then((dataUrl) => {
        download(dataUrl, title)
      })
      .catch(() => {
        // console.error('oops, something went wrong!', error)
      })
  }
  return (
    <ResponsiveModal
      visible={visible}
      cancellable
      cancel={close}
      title="Download Coupon"
      dialogActions={<Actions onDownload={downloadCoupon} close={close} />}
    >
      <style>
        {
          `
          .cont{
            min-width: 500px;
            background:  ${primaryColor};
          }
        .coupon-body .amount {
            background:  ${primaryColor};
        }
        
        .coupon-body .amount:before,
        .coupon-body .amount:after {
            background: ${primaryColor};
        }
          `
        }
      </style>
      <div className="cont" id="coupon-holder">
        <div id="download-coupon">
          <div className="top">
            <div className="b-logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="title-hd">
              <div className="title">{`${title} 🎉🤑`}</div>
              {/* <div className="period">Hurry while stocks last.</div> */}
            </div>
          </div>
          <div className="coupon-body">
            <div className="amount">
              <div className="value">{`- ${amount}`}</div>
            </div>
            <div className="code">
              <div className="code-title">USE THIS CODE AT CHECKOUT:</div>
              <div className="code-value">{code}</div>
            </div>
          </div>
          <div className="visit">
            <div className="link">
              <Search />
              {link}
            </div>
            <div className="scan">
              <QR data={link} />
            </div>
          </div>
          <div className="terms">
            <div className="hd">TERMS & CONDITIONS</div>
            <Divider sx={{ marginY: 0.2 }} />
            <p>{` The amount of the discount coupon is ${amount}. This discount coupon can be deactivated at any time by the business.`}</p>
          </div>
        </div>
      </div>
    </ResponsiveModal>

  )
}

const mapStateToProps = (state) => ({ merchant: state.merchant.merchantInfo })

export default connect(mapStateToProps, {})(DownloadCouponDialog)
