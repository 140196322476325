import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import ErrorLoading from '../components/layout/ErrorLoading'
import CenterLoader from '../components/spinners/CenterLoader'
import '../styles/overview.css'
import { MERCHANT } from '../graphql/merchant'
import Logo from '../components/storefront/Logo'
import CoverPhoto from '../components/storefront/CoverPhoto'
import Color from '../components/storefront/Color'
import WelcomeCard from '../components/storefront/WelcomeCard'
import LinkCard from '../components/storefront/LinkCard'
import ShareLink from '../components/storefront/ShareLink'
import { setMerchant } from '../actions/merchantActions'

function StoreDetails({ updateMerchant, merchant }) {
  return (
    <div>
      <WelcomeCard merchant={merchant} updateMerchant={updateMerchant} />
      <Grid container spacing={1} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <LinkCard domain={merchant?.subdomain} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ShareLink domain={merchant?.subdomain} />
        </Grid>
      </Grid>
      <div className="settings-section">
        <div className="settings-section-header">
          <h3>Store Branding</h3>
        </div>
        <div className="settings-section-wrapper">
          <Logo merchant={merchant} />
          <CoverPhoto merchant={merchant} />
        </div>
        <Color merchant={merchant} />
      </div>
    </div>
  )
}

function Storefront({ merchant: globalMerchant, setMerchant: setMerchantAction }) {
  const [merchant, setMerchantInfo] = useState(null)
  const [error, setError] = useState(null)

  const onMerchantChange = (newMerchant) => {
    setMerchantAction({ ...globalMerchant, ...newMerchant })
    setMerchantInfo(newMerchant)
  }

  const handleResponse = (data) => {
    setError(null)
    if (data.merchant) {
      setMerchantInfo(data.merchant)
    }
  }
  const [getMerchant, { loading }] = useLazyQuery(MERCHANT, {
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  useEffect(() => {
    getMerchant()
  }, [])

  return (
    <div className="page-content">
      {!loading && !error && merchant ? <StoreDetails updateMerchant={onMerchantChange} merchant={merchant} /> : null}
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getMerchant} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  merchant: state.merchant.merchantInfo,
})
export default connect(mapStateToProps, { setMerchant })(Storefront)
