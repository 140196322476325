import { React, useState } from 'react'
import {
  Box, Button, DialogActions, IconButton, InputAdornment, TextField,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import {
  Add, Delete, Edit, KeyboardArrowUp,
} from '@mui/icons-material'
import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-hot-toast'
import ResponsiveModal from '../layout/ResponsiveModal'
import SaveRoomDialog from './SaveRoom'
import SaveTableDialog from './SaveTableDialog'
import DeleteDialog from '../shared/DeleteDialog'
import { DELETE_ROOM, DELETE_TABLE, GET_STORE_ROOMS } from '../../graphql/rooms'
import ErrorLoading from '../layout/ErrorLoading'
import MainSpinner from '../shared/MainSpinner'
import DownloadQrBanner from './DownloadQrBanner'

export function Table({ table: initialTable, deleteTable }) {
  const [table, setTable] = useState(initialTable)
  const [editOpen, toggleEdit] = useState(false)
  const [deleteOpen, toggleDelete] = useState(false)
  const [bannerOpen, toggleBanner] = useState(false)

  const [deleteReq, { loading }] = useMutation(DELETE_TABLE, {
    variables: { id: table.id },
    onError: () => {
      toast.error(`error deleting table ${table.name}`)
    },
    onCompleted: () => {
      deleteTable(table.id)
      toast.success(`table ${table.name} deleted successfully`)
      toggleDelete(false)
    },
  })

  return (
    <Box sx={{
      width: '100%',
      px: 2,
      py: 1,
      mb: 0.5,
      borderBottom: '1px solid #ddd',
      transform: 'revert-layer',
      // background: 'red',
    }}
    >
      { editOpen ? <SaveTableDialog table={table} updateTable={(t) => { setTable(t) }} close={() => (toggleEdit(false))} /> : null }
      { bannerOpen ? <DownloadQrBanner table={table} updateTable={(t) => { setTable(t) }} close={() => (toggleBanner(false))} /> : null }
      { deleteOpen ? (
        <DeleteDialog
          visible
          title="Delete Table"
          msg={`Are you sure you want to delete table ${table.name}?`}
          onDelete={deleteReq}
          loading={loading}
          close={() => (toggleDelete(false))}
          maxWidth="xs"
        />
      ) : null }
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
      >
        <TextField
          type="text"
          size="small"
          value={table.name}
          sx={{ flex: 70 }}
          InputProps={{
            readOnly: true,
            endAdornment:
  <InputAdornment position="end">
    <IconButton
      size="small"
      onClick={() => { toggleEdit(true) }}
    >
      <Edit fontSize="10" />
    </IconButton>
  </InputAdornment>,
          }}
        />
        <Box sx={{
          flex: 30, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
        }}
        >
          <IconButton onClick={() => (toggleBanner(true))}>
            <QrCodeScannerIcon />
          </IconButton>
          <IconButton onClick={() => (toggleDelete(true))}>
            <Delete />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export function Room({ room: initialRoom, deleteRoom }) {
  const [room, setRoom] = useState(initialRoom)
  const [open, toggleOpen] = useState(false)
  const [editOpen, toggleEdit] = useState(false)
  const [addOpen, toggleAdd] = useState(false)
  const [deleteOpen, toggleDelete] = useState(false)
  const [tables, setTables] = useState(initialRoom.tables || [])

  const [deleteReq, { loading }] = useMutation(DELETE_ROOM, {
    variables: { id: room.id },
    onError: () => {
      toast.error(`error deleting room ${room.name}`)
    },
    onCompleted: () => {
      deleteRoom(room.id)
      toast.success(`room ${room.name} deleted successfully`)
      toggleDelete(false)
    },
  })

  const deleteTable = (id) => {
    setTables(tables.filter((t) => (t.id !== id)))
  }

  return (
    <Box sx={{
      width: '100%', mb: 1,
    }}
    >
      { editOpen ? <SaveRoomDialog room={room} updateRoom={(r) => { setRoom(r) }} close={() => { toggleEdit(false) }} /> : null }
      { addOpen ? <SaveTableDialog room={room} addTable={(t) => { setTables([...tables, t]) }} close={() => (toggleAdd(false))} /> : null }
      { deleteOpen ? (
        <DeleteDialog
          visible
          title="Delete Room"
          msg={`Are you sure you want to delete room ${room.name}?`}
          onDelete={deleteReq}
          loading={loading}
          close={() => (toggleDelete(false))}
          maxWidth="xs"
        />
      ) : null }
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        bgcolor: 'ButtonShadow',
        p: 1,
        borderRadius: 1,
      }}
      >
        <IconButton onClick={() => { toggleOpen(!open) }}>
          { open ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon /> }
        </IconButton>
        <TextField
          type="text"
          size="small"
          value={room.name}
          sx={{ flex: 75 }}
          InputProps={{
            readOnly: true,
            endAdornment:
  <InputAdornment position="end">
    <IconButton
      size="small"
      onClick={() => { toggleEdit(true) }}
    >
      <Edit fontSize="10" />
    </IconButton>
  </InputAdornment>,
          }}
        />
        <Box sx={{
          flex: 30, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
        }}
        >
          <IconButton onClick={() => { toggleDelete(true) }}>
            <Delete />
          </IconButton>
        </Box>
      </Box>
      { open ? tables?.map((t) => (<Table table={t} deleteTable={deleteTable} />)) : null }
      { open ? (
        <Button
          size="small"
          endIcon={<Add />}
          onClick={() => (toggleAdd(true))}
          sx={{ ml: 2 }}
        >
          Add Table
        </Button>
      ) : null }
    </Box>
  )
}

function Actions({ addRoom, loading }) {
  return (
    <DialogActions sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
      <Button
        loading={loading}
        startIcon={<Add />}
        variant="outlined"
        sx={{ flex: 1 }}
        onClick={addRoom}
        disabled={loading}
      >
        Add Room
      </Button>
    </DialogActions>
  )
}

export default function QrControlsDialog({
  close, storeId,
}) {
  const [addOpen, toggleAdd] = useState(false)
  const [rooms, setRooms] = useState([])

  const { loading, error } = useQuery(GET_STORE_ROOMS, {
    variables: { id: storeId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => { setRooms(data?.store.rooms) },
  })

  const deleteRoom = (id) => {
    setRooms(rooms.filter((r) => (r.id !== id)))
  }

  return (
    <ResponsiveModal
      title="In-Store Configuration"
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions onSave={() => {}} loading={loading} addRoom={() => { toggleAdd(true) }} close={close} />}
    >
      {addOpen ? (
        <SaveRoomDialog
          storeId={storeId}
          addRoom={(room) => { setRooms([...rooms, room]) }}
          close={() => { toggleAdd(false) }}
        />
      ) : null}
      { loading && <MainSpinner /> }
      { error && <ErrorLoading visible /> }
      <Box>
        {rooms?.map((r) => <Room room={r} deleteRoom={deleteRoom} />)}
      </Box>
    </ResponsiveModal>
  )
}
