import { React } from 'react'
import { connect } from 'react-redux'
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share'
import {
  Box, Button, DialogActions, IconButton, Tooltip,
} from '@mui/material'
import toast from 'react-hot-toast'
import LinkIcon from '@mui/icons-material/Link'
import ResponsiveModal from '../layout/ResponsiveModal'
import { formatMoney } from '../../utils/formatter'

function Actions({ close }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
    </DialogActions>
  )
}

function ShareCoupon({
  visible, coupon, merchant, close,
}) {
  const {
    title: couponTitle, code, discountAmount, discountType,
  } = coupon
  const value = discountType === 'fixed' ? formatMoney(discountAmount) : `${discountAmount}%`
  const shareUrl = `https://${merchant.subdomain}.sokolink.com`
  const title = `🎉🤑 ${value} ${couponTitle}. \n Follow link, use this code at checkout: ${code}\n`
  const copyLink = () => {
    navigator.clipboard.writeText(title + shareUrl)
    toast.success('Link copied to clipboard')
  }
  return (
    <ResponsiveModal
      visible={visible}
      cancellable
      cancel={close}
      title="Share Coupon"
      dialogActions={<Actions close={close} />}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
        <Tooltip title="copy link">
          <IconButton variant="contained" style={{ background: 'orange' }} onClick={copyLink}>
            <LinkIcon style={{ fontSize: 32 }} />
          </IconButton>
        </Tooltip>
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          className="share-button"
        >
          <WhatsappIcon size={50} round />
        </WhatsappShareButton>
        <FacebookShareButton
          url={shareUrl}
          title={title}
          className="share-button"
        >
          <FacebookIcon size={50} round />
        </FacebookShareButton>
        <FacebookMessengerShareButton
          url={shareUrl}
          title={title}
          className="share-button"
        >
          <FacebookMessengerIcon size={50} round />
        </FacebookMessengerShareButton>
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="share-button"
        >
          <TwitterIcon size={50} round />
        </TwitterShareButton>
      </Box>
    </ResponsiveModal>
  )
}

const mapStateToProps = (state) => ({ merchant: state.merchant.merchantInfo })

export default connect(mapStateToProps, {})(ShareCoupon)
