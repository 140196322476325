/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
import { useSubscription } from '@apollo/client'
import './styles/OrdersView.css'
import { Chip } from '@mui/material'
import OrderModal from './OrderModal'
import { formatMoney, formatOrderLabel } from '../../utils/formatter'
import { ORDER_SUBSCRIPTION } from '../../graphql/orders'
import {
  CancelBtn, CompleteBtn, DispatchBtn, OrderProcessed,
} from './OrderBtns'

const OrderStatusColor = {
  pending: '#0a82f3',
  preparing: '#b800a9a9',
  dispatched: '#ff009db2',
  completed: '#008000a2',
  cancelled: '#ff7575e7',
}

const PaymentStatusColor = {
  unpaid: '#0a82f3',
  paid: '#008000a2',
  reversed: '#ff7575e7',
}

function Order({ order: initialOrder }) {
  const [order, setOrder] = useState(initialOrder)
  const [modalOpen, setModalOpen] = useState(false)
  const {
    orderNo, orderType, orderStatus, total, paymentStatus, customer, deliveryType,
  } = order

  const orderTypeLabel = formatOrderLabel(orderType, deliveryType)

  const updateOrder = ({ subscriptionData }) => {
    if (subscriptionData.data.orderUpdated) {
      const newOrder = subscriptionData.data.orderUpdated
      setOrder(newOrder)
    }
  }

  if (orderStatus !== 'completed' || orderStatus !== 'cancelled') {
    // eslint-disable-next-line no-unused-vars
    const { data, loading, error } = useSubscription(ORDER_SUBSCRIPTION, {
      shouldResubscribe: true,
      variables: {
        orderNo,
      },
      onSubscriptionData: updateOrder,
    })
  }
  return (
    <>
      <OrderModal visible={modalOpen} order={order} updateOrder={(newOrder) => setOrder(newOrder)} close={() => setModalOpen(false)} />
      <tr className="order">
        <td className="orderno" onClick={() => setModalOpen(true)}>
          <h4><u>{orderNo}</u></h4>
        </td>
        <td className="status">
          <Chip label={orderStatus} sx={{ color: '#fff', background: OrderStatusColor[orderStatus] }} />
        </td>
        <td className="amount">{formatMoney(total)}</td>
        <td className="paystatus">
          <Chip label={paymentStatus} sx={{ color: '#fff', background: PaymentStatusColor[paymentStatus] }} />
        </td>
        <td className="from">
          <p>{`${customer?.name} - ${customer?.phone}`}</p>
        </td>
        <td className="type">
          <Chip label={orderTypeLabel} sx={{ color: '#fff', background: '#000000be' }} />
        </td>
        <td className="actions">
          <DispatchBtn visible={orderStatus === 'preparing'} order={order} updateOrder={(newOrder) => setOrder(newOrder)} />
          <CompleteBtn visible={orderStatus === 'dispatched'} order={order} updateOrder={(newOrder) => setOrder(newOrder)} />
          <CancelBtn visible={orderStatus === 'pending'} order={order} updateOrder={(newOrder) => setOrder(newOrder)} />
          <OrderProcessed visible={orderStatus === 'completed' || orderStatus === 'cancelled'} orderStatus={orderStatus} />
        </td>
      </tr>
    </>
  )
}

export default function OrdersView({ visible, orders }) {
  if (!visible) {
    return null
  }
  return (
    <div className="table-container">
      <table className="orders-table">
        <thead>
          <tr>
            <th className="orderno-hd">Order No</th>
            <th className="status-hd">Status</th>
            <th className="amount-hd">Amount</th>
            <th className="payment-hd">Payment</th>
            <th className="from-hd">From</th>
            <th className="type-hd">For</th>
            <th className="actions-hd">Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => <Order key={order.orderNo} order={order} />)}
        </tbody>
      </table>
    </div>

  )
}
