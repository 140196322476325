/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'

import { STORES } from '../../graphql/locations'

export default function SelectStoreAnt({
  changeHandler, value, hasAll, required, disabled,
}) {
  const [stores, setStores] = useState([])
  const { loading } = useQuery(STORES, {
    variables: {
      limit: 100,
      offset: 0,
    },
    onError: () => {},
    onCompleted: (data) => { setStores(data.stores) },
  })
  return (
    <>
      <Select
        required={required}
        onChange={changeHandler}
    //   disabled={disabled}
        loading={loading}
        defaultValue="all"
        options={hasAll ? [
          { value: 'all', label: 'All Stores' },
          ...stores.map((s) => ({ value: s.id, label: s.name })),
        ] : stores.map((s) => ({ value: s.id, label: s.name }))}
      />
    </>

  )
}
