import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import ErrorLoading from '../../components/layout/ErrorLoading'
import CenterLoader from '../../components/spinners/CenterLoader'
import '../../styles/stores.css'
import { STORES } from '../../graphql/locations'
import Pagination from '../../components/layout/Pagination'
import FilterForm from '../../components/layout/FilterForm'
import FilterName from '../../components/inputs/FilterName'
import MallStoresView from '../../components/locations/MallStoresView'

export default function MallStores() {
  const [stores, setStores] = useState(null)
  const limit = 10
  const [page, setPage] = useState(1)
  const [error, setError] = useState(null)
  const start = page === 1 ? 0 : (page - 1) * limit
  const [name, setName] = useState('')
  const [nameSearch, setNameSearch] = useState('')
  const [phone, setPhone] = useState('')
  const [phoneSearch, setPhoneSearch] = useState('')

  const handleResponse = (data) => {
    setError(null)
    setStores(data.stores)
  }
  const [getStores, { loading }] = useLazyQuery(STORES, {
    fetchPolicy: 'cache-and-network',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  const getStoresReq = () => {
    getStores({
      variables: {
        limit,
        offset: start,
        name,
        phone,
      },
    })
  }
  const clearNameSearch = () => {
    setName('')
    setNameSearch('')
  }
  const handleNameSearch = (e) => {
    setName(e.target.value)
    if (e.target.value?.length >= 4) {
      setNameSearch(e.target.value)
    }
    if (!e.target.value) {
      clearNameSearch()
    }
  }
  const clearPhoneSearch = () => {
    setPhone('')
    setPhoneSearch('')
  }
  const handlePhoneSearch = (e) => {
    setPhone(e.target.value)
    if (e.target.value?.length >= 8) {
      setPhoneSearch(e.target.value)
    }
    if (!e.target.value) {
      clearPhoneSearch()
    }
  }
  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextPage = () => {
    if (!(stores.length < limit)) {
      setPage(page + 1)
    }
  }
  const handleFiltering = (e) => {
    e.preventDefault()
    if (page === 1) {
      getStoresReq()
    }
    setPage(1)
  }
  useEffect(() => {
    getStoresReq()
  }, [page, nameSearch, phoneSearch])
  return (
    <div className="page-content">
      <div className="page-tittle">Mall Businesses</div>
      <FilterForm submitHandler={handleFiltering}>
        <FilterName name="Name" value={name} clear={clearNameSearch} label="Search Name:" type="text" placeholder="name" changeHandler={handleNameSearch} />
        <FilterName name="Phone" value={phone} clear={clearPhoneSearch} label="Search Phone:" type="text" placeholder="phone" changeHandler={handlePhoneSearch} />
      </FilterForm>
      <CenterLoader visible={loading} />
      <ErrorLoading visible={error && !loading} reload={getStores} />
      <MallStoresView visible={!loading && !error && stores} stores={stores} deleteStore={(id) => setStores(stores.filter((el) => el.id !== id))} />
      <Pagination visible={!loading && !error && (stores ? stores.length : false)} page={page} next={nextPage} prev={prevPage} />
    </div>
  )
}
