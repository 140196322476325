/* eslint-disable react/jsx-props-no-spreading */
import { React, useReducer, useState } from 'react'
import {
  Box, Button, DialogActions, TextField,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import ResponsiveBigModal from '../layout/ResponsiveBigModal'
import CategorySelect from '../add-item/CategorySelect'
import { ADD_PRODUCT, UPDATE_PRODUCT } from '../../graphql/products'
import InstockSwitch from '../add-item/InstockSwitch'
import ProductVariants from '../add-item/ProductVariants'
import ItemImages from '../add-item/ItemImages'
import SelectCustomizations from './SelectCustomizations'
import DayAvailability from '../shared/DayAvailability'
import { useProducts } from './context/products'

const defaultTimings = {
  sunday: { status: true, from: '00:00', to: '00:00' },
  monday: { status: true, from: '00:00', to: '00:00' },
  tuesday: { status: true, from: '00:00', to: '00:00' },
  wednesday: { status: true, from: '00:00', to: '00:00' },
  thursday: { status: true, from: '00:00', to: '00:00' },
  friday: { status: true, from: '00:00', to: '00:00' },
  saturday: { status: true, from: '00:00', to: '00:00' },
}

function Actions({ close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        form="save-product-form"
        disabled={loading}
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mr: 2 }}
      >
        {loading ? 'Saving...' : 'Save Product'}
      </Button>
    </DialogActions>
  )
}

function variantsReducer(variants, action) {
  switch (action.type) {
    case 'UPDATE_VARIANT':
      return variants.map((variant, index) => (index === action.variantIndex
        ? { ...variant, [action.key]: action.newValue }
        : variant))
    case 'ADD_VARIANT':
      return [...variants, { name: '', price: '', discountPrice: '' }]
    case 'DELETE_VARIANT':
      return variants.filter((variant, index) => index !== action.variantIndex)
    default:
      return variants
  }
}

const timingsReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ITEM':
      return {
        ...state, // Copy the existing state
        [action.day]: { // Update the specific day
          ...state[action.day], // Copy the existing day's properties
          ...action.newValue, // Update with new values
        },
      }
    default:
      return state
  }
}

export default function SaveProductModal({
  close, product,
}) {
  const { addProduct, updateProduct } = useProducts()
  const [name, setName] = useState(product?.name || '')
  const [category, setCategory] = useState(product?.category?.id || '')
  const [price, setPrice] = useState(product?.price || '')
  const [discountPrice, setDiscountPrice] = useState(product?.discountPrice || '')
  const [hasOptions, setHasOptions] = useState(product?.variants?.length)
  const [variants, variantsDispatch] = useReducer(variantsReducer, product?.variants || [])
  const [inStock, toggleInstock] = useState(product?.inStock || true)
  const [desc, setDesc] = useState(product?.description || '')
  const [customizations, setCustomizations] = useState(product?.customizations.map((c) => (JSON.stringify({ id: c.id, title: c.title }))) || [])
  const [images, setImages] = useState(product?.images || [])
  const initialTimings = product?.timings || defaultTimings
  const timingsArr = Object.keys(initialTimings).filter((d) => d !== '__typename')
  const formattedTimings = {}
  timingsArr.forEach((d) => {
    const { status, from, to } = initialTimings[d]
    formattedTimings[d] = { status, from, to }
  })
  const [timings, timingsDispatch] = useReducer(timingsReducer, formattedTimings)

  const updateTiming = (day, newValue) => {
    timingsDispatch({ type: 'UPDATE_ITEM', day, newValue })
  }

  const { catalogueId } = useParams()

  const [addProductReq, { loading }] = useMutation(ADD_PRODUCT, {
    onError: () => { toast.error('Error adding product') },
    onCompleted: (data) => {
      addProduct(data.addProduct)
      toast.success('Product added successfully')
      close()
    },
  })

  const [updateProductReq, { loading: updating }] = useMutation(UPDATE_PRODUCT, {
    onError: () => { toast.error('Error updating product.') },
    onCompleted: (data) => {
      toast.success('Product updated successfully')
      updateProduct(data.updateProduct)
      close()
    },
  })

  const handleVariantOptionsCheck = (e) => {
    setHasOptions(e.target.checked)
    if (e.target.checked) {
      if (!variants.length) {
        variantsDispatch({ type: 'ADD_VARIANT' })
      }
    }
  }

  const addVariantOption = () => {
    variantsDispatch({ type: 'ADD_VARIANT' })
  }
  const handleVariantOptionChange = (i, key, e) => {
    variantsDispatch({
      type: 'UPDATE_VARIANT', variantIndex: i, key, newValue: e.target.value,
    })
  }
  const removeVariantOption = (i) => {
    variantsDispatch({ type: 'DELETE_VARIANT', variantIndex: i })
  }

  const onSave = (e) => {
    e.preventDefault()
    if (product) {
      const newImages = []
      images.forEach((image) => {
        if (typeof (image) === 'object') {
          newImages.push(image)
        }
      })
      updateProductReq({
        variables: {
          id: product.id,
          name,
          categoryId: category,
          price: parseInt(price, 10),
          discountPrice: parseInt(discountPrice, 10) || null,
          variants: hasOptions ? variants.map((v) => ({
            name: v.name,
            price: parseInt(v.price, 10),
            ...(v.discountPrice && { discountPrice: parseInt(v.discountPrice, 10) }),
          })) : [],
          customizationIds: customizations.map((c) => {
            const parsed = JSON.parse(c)
            return (parseInt(parsed.id, 10))
          }),
          inStock,
          desc,
          timings,
          files: newImages,
        },
      })
    } else {
      addProductReq({
        variables: {
          files: images,
          name,
          catalogueId,
          categoryId: category,
          price: parseInt(price, 10),
          discountPrice: parseInt(discountPrice, 10) || null,
          variants: hasOptions ? variants.map((v) => ({
            name: v.name,
            price: parseInt(v.price, 10),
            ...(v.discountPrice && { discountPrice: parseInt(v.discountPrice, 10) }),
          })) : [],
          customizationIds: customizations.map((c) => {
            const parsed = JSON.parse(c)
            return (parseInt(parsed.id, 10))
          }),
          inStock,
          desc,
          timings,
        },
      })
    }
  }
  return (
    <ResponsiveBigModal
      title={product ? 'Edit Product' : 'Add Product'}
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions loading={loading || updating} close={close} />}
    >
      <Box component="form" id="save-product-form" sx={{ display: 'flex', justifyContent: 'space-evenly' }} onSubmit={onSave}>
        <Box sx={{ flex: 0.45 }}>
          <TextField type="text" size="small" required value={name} onChange={(e) => { setName(e.target.value) }} label="Product Name" variant="outlined" fullWidth />
          <CategorySelect required value={category} changeHandler={(e) => { setCategory(e.target.value) }} />
          <InstockSwitch label="Stock Status" onChange={() => toggleInstock(!inStock)} checked={inStock} />
          <TextField
            type="text"
            size="small"
            value={desc}
            onChange={(e) => { setDesc(e.target.value) }}
            label="Product Description"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            sx={{ mb: 2 }}
          />
          <Box sx={{
            width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <TextField type="number" size="small" required value={price} onChange={(e) => { setPrice(e.target.value) }} label="Base Price" variant="outlined" sx={{ flex: 0.48 }} />
            <TextField type="number" size="small" value={discountPrice} onChange={(e) => { setDiscountPrice(e.target.value) }} label="Discount Price" variant="outlined" sx={{ flex: 0.48 }} />
          </Box>
          <ProductVariants hasOptions={hasOptions} setHasOptions={handleVariantOptionsCheck} variants={variants} deleteOption={removeVariantOption} addOption={addVariantOption} handleOptionChange={handleVariantOptionChange} />
        </Box>
        <Box sx={{ flex: 0.45, ml: 2 }}>
          <SelectCustomizations values={customizations} updateValues={(v) => { setCustomizations(v) }} />
          <DayAvailability title="Item Availability" timings={timings} handleChange={updateTiming} />
          <ItemImages images={images} required={false} handleImageUpload={(e) => { setImages([...images, ...e.target.files]) }} removeImage={(index) => setImages([...images.slice(0, index), ...images.slice(index + 1)])} productId={product?.id} />
        </Box>
      </Box>
    </ResponsiveBigModal>
  )
}
