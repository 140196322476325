import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { Button, Divider } from '@mui/material'
import { Link, useHistory } from 'react-router-dom'
import SaveCampaignDialog from './SaveCampaignDialog'

export default function WhatsappPageHeader({ activeTab, onNewCampaign }) {
  const [addCampaignOpen, toggleAddCampaign] = useState(false)

  const history = useHistory()

  const changeTab = (to) => {
    history.push(`/business/whatsapp/${to}`)
  }

  return (
    <Box>
      <Box sx={{
        mb: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      >
        { addCampaignOpen ? <SaveCampaignDialog close={() => { toggleAddCampaign(false) }} onNewCampaign={onNewCampaign} /> : null }
        <Tabs value={activeTab}>
          <Tab label="Marketing Campaigns" value="campaigns" onClick={() => { changeTab('campaigns') }} />
          <Tab label="Whatsapp Templates" value="templates" onClick={() => { changeTab('templates') }} />
        </Tabs>
        <Box>
          { activeTab === 'campaigns' ? (
            <Button
              variant="contained"
              onClick={() => { toggleAddCampaign(true) }}
            >
              Create New Campaign
            </Button>
          ) : (
            <Link to="/business/whatsapp/templates/create">
              <Button variant="contained" sx={{ mr: 1 }}>Create New Template</Button>
            </Link>
          )}
        </Box>
      </Box>
      <Divider sx={{ mx: -2 }} />
    </Box>
  )
}
