import React from 'react'
import './styles/FilterForm.css'
import DataDownloadMenu from './DataDownloadMenu'
import DateRange from '../shared/DateRange'

export default function FilterForm({
  children, dateRangeValue, onDateRangeChange, download,
  defaultRangeDays,
}) {
  return (
    <form className="filter-container">
      { dateRangeValue ? <DateRange value={dateRangeValue} onChange={onDateRangeChange} /> : null }
      {children}
      {download ? <DataDownloadMenu defaultRangeDays={defaultRangeDays} download={download} /> : null}
    </form>
  )
}
