import React, { useEffect, useState } from 'react'
import { Edit, Send } from '@mui/icons-material'
import {
  Box, Button, Chip, CircularProgress, IconButton, Tooltip,
} from '@mui/material'
import { connect } from 'react-redux'
import { useLazyQuery, useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import ErrorLoading from '../layout/ErrorLoading'
import NoData from '../layout/NoData'
import { BROADCAST_WHATSAPP_CAMPAIGN, WHATSAPP_CAMPAIGNS } from '../../graphql/whatsappcampaigns'
import Pagination from '../layout/Pagination'
import { formatDate, formatGraphqlError } from '../../utils/formatter'
import SaveCampaignDialog from './SaveCampaignDialog'

const CHIPCOLOR = {
  pending: 'primary',
  sent: 'success',
}

function Campaign({ campaign: initialCampaign }) {
  const [campaign, setCampaign] = useState(initialCampaign)
  const {
    id, name, templateName, status, createdOn, reach,
  } = campaign

  const [editOpen, toggleEdit] = useState(false)

  const [broadcastReq, { loading }] = useMutation(BROADCAST_WHATSAPP_CAMPAIGN, {
    onError: (e) => { toast.error(formatGraphqlError(e, 'Error broadcating campaign.')) },
    onCompleted: (data) => {
      toast.success(`${name} broadcasted`)
      setCampaign(data.broadcastWhatsappCampaign)
    },
  })

  const handleBroadcast = () => {
    broadcastReq({
      variables: {
        id,
      },
    })
  }

  return (
    <>
      { editOpen ? <SaveCampaignDialog campaign={campaign} close={() => { toggleEdit(false) }} onUpdateCampaign={(c) => { setCampaign(c) }} /> : null }
      <tr className="campaign">
        <td style={{ minWidth: '150px' }}>
          <p>{name}</p>
        </td>
        <td style={{ minWidth: '100px' }}>
          <p>{templateName}</p>
        </td>
        <td style={{ minWidth: '100px' }}>
          <Chip color={CHIPCOLOR[status]} label={status} />
        </td>
        <td style={{ minWidth: '100px' }}>
          <p>{formatDate(createdOn)}</p>
        </td>
        <td style={{ minWidth: '100px' }}>
          <p>{reach || '--'}</p>
        </td>
        <td style={{ minWidth: '200px' }}>
          <Tooltip title="Edit" placement="bottom">
            <IconButton sx={{ mr: 5 }} onClick={() => { toggleEdit(true) }}>
              <Edit />
            </IconButton>
          </Tooltip>
          <Button disabled={loading || status === 'sent'} variant="outlined" endIcon={<Send />} sx={{ my: 1 }} onClick={handleBroadcast}>

            { loading ? 'Sending...' : 'BroadCast' }
          </Button>
        </td>
      </tr>
    </>
  )
}

function CampaignsTable({ searchStr, status, newCampaigns }) {
  const [error, setError] = useState(null)
  const [campaigns, setCampaigns] = useState([])
  const limit = 10
  const [page, setPage] = useState(1)
  const start = page === 1 ? 0 : (page - 1) * limit
  const allCampaigns = [...newCampaigns, ...campaigns]

  const handleResponse = (data) => {
    setError(null)
    setCampaigns(data.whatsappCampaigns)
  }

  const [getCampaigns, { loading }] = useLazyQuery(WHATSAPP_CAMPAIGNS, {
    fetchPolicy: 'network-only',
    onError: () => { setError(true) },
    onCompleted: handleResponse,
  })
  const getCampaignsReq = () => {
    getCampaigns({
      variables: {
        limit,
        offset: start,
        name: searchStr || null,
        status: status === 'all' ? null : status,
      },
    })
  }

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }
  const nextPage = () => {
    if (!(campaigns.length < limit)) {
      setPage(page + 1)
    }
  }

  useEffect(() => {
    getCampaignsReq()
  }, [page, status, searchStr])

  if (loading) {
    return (
      <Box sx={{
        width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', my: 3,
      }}
      >
        <CircularProgress size={20} />
      </Box>
    )
  }

  if (error) return (<ErrorLoading visible reload={() => { window.location.reload() }} />)

  return (
    <Box sx={{ width: '100%' }}>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Template</th>
              <th>Status</th>
              <th>Sent On</th>
              <th>Contacts</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {allCampaigns.map((c) => <Campaign key={c.id} campaign={c} />)}
          </tbody>
        </table>
      </div>
      <NoData visible={!loading && !error && !allCampaigns?.length} />
      <Pagination visible={!loading && !error && allCampaigns?.length} page={page} next={nextPage} prev={prevPage} />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  firebaseUser: state.user.user,
})
export default connect(mapStateToProps, { })(CampaignsTable)
