import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api' : 'https://sokolink-api.herokuapp.com/api'

function SelectTemplate({
  firebaseUser, value, disabled, onChange, sx, fullWidth,
}) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [templates, setTemplates] = useState([])

  useEffect(async () => {
    try {
      setLoading(true)
      const token = await firebaseUser?.getIdToken()
      // Send GET request to the server
      const response = await fetch(`${baseUrl}/waba/templates?status=APPROVED`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          AuthToken: `${token}`,
        },
      })
      if (!response.ok) {
        setLoading(false)
        setError(true)
      }
      const res = await response.json()
      setTemplates(res.data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(e)
      console.log(error)
    }
  }, [])

  return (
    <FormControl fullWidth={fullWidth} sx={sx || { }}>
      <InputLabel sx={{ mt: -0.5 }}>Select Template</InputLabel>
      <Select
        required
        value={value}
        onChange={onChange}
        type="text"
        size="small"
        label="Select Template"
        disabled={disabled}
      >
        {loading ? <MenuItem value="">loading templates...</MenuItem> : null}
        {templates?.map((t) => (<MenuItem key={t.id} value={t.name}>{t.name}</MenuItem>))}
      </Select>
    </FormControl>
  )
}

const mapStateToProps = (state) => ({
  firebaseUser: state.user.user,
})
export default connect(mapStateToProps, { })(SelectTemplate)
