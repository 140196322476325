import { React, useState } from 'react'
import {
  Box, Button, DialogActions, TextField,
} from '@mui/material'
import { SaveOutlined } from '@mui/icons-material'
import { useMutation } from '@apollo/client'
import { toast } from 'react-hot-toast'
import ResponsiveModal from '../layout/ResponsiveModal'
import { CREATE_TABLE, UPDATE_TABLE } from '../../graphql/rooms'

function Actions({ close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<SaveOutlined />}
        variant="contained"
        sx={{ mr: 2 }}
        form="save-table-form"
      >
        {loading ? 'Saving...' : 'Save Table'}
      </Button>
    </DialogActions>
  )
}

export default function SaveTableDialog({
  close, room, table, addTable, updateTable,
}) {
  const [name, setName] = useState(table?.name || '')
  const [numOfSeats, setSeats] = useState(table?.seats || '')

  const [createReq, { loading }] = useMutation(CREATE_TABLE, {
    onError: () => {
      toast.error('error creating table')
    },
    onCompleted: (data) => {
      addTable(data.createRoomTable)
      toast.success('table created successfully')
      close()
    },
  })

  const [updateReq, { loading: updating }] = useMutation(UPDATE_TABLE, {
    onError: () => {
      toast.error('error updating table')
    },
    onCompleted: (data) => {
      updateTable(data.updateRoomTable)
      toast.success('table updated successfully')
      close()
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (table) {
      updateReq({ variables: { id: table.id, name, seats: parseInt(numOfSeats, 10) } })
    } else {
      createReq({ variables: { roomId: room.id, name, seats: parseInt(numOfSeats, 10) } })
    }
  }
  return (
    <ResponsiveModal
      title={table ? 'Edit Table' : 'Create Table'}
      visible
      cancellable
      cancel={close}
      sx={{ margin: 4 }}
      maxWidth="xs"
      dialogActions={<Actions onSave={() => {}} loading={loading || updating} close={close} />}
    >
      <Box component="form" id="save-table-form" onSubmit={handleSubmit}>
        <TextField fullWidth type="text" size="small" required value={name} onChange={(e) => { setName(e.target.value) }} label="Table Name" variant="outlined" sx={{ flex: 0.48, mb: 2 }} />
        <TextField fullWidth type="number" size="small" required value={numOfSeats} onChange={(e) => { setSeats(e.target.value) }} label="Number of Seats" variant="outlined" sx={{ flex: 0.48, mb: 2 }} />
      </Box>
    </ResponsiveModal>
  )
}
