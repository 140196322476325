/* eslint-disable react/jsx-props-no-spreading */
import { React, useState } from 'react'
import {
  Box, Button, DialogActions, FormControlLabel, Switch, TextField,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import ResponsiveModal from '../layout/ResponsiveModal'
import SelectStore from '../locations/SelectStore'
import { UPLOAD_CUSTOMERS } from '../../graphql/customers'

function Actions({ close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mr: 2 }}
        form="add-user-form"
      >
        {loading ? 'Saving...' : 'Save Customer'}
      </Button>
    </DialogActions>
  )
}

export default function SaveCustomer({
  close, customer, add, update,
}) {
  const [name, setName] = useState(customer?.name || '')
  const [email, setEmail] = useState(customer?.email || '')
  const [phone, setPhone] = useState(customer?.phone || '')
  const [storeId, setStoreId] = useState(customer?.storeId || '')
  const [consent, toggleConsent] = useState(customer?.consent || true)

  const [addCustomerReq, { loading }] = useMutation(UPLOAD_CUSTOMERS, {
    onError: () => { toast.error('Error adding customer.') },
    onCompleted: (data) => {
      toast.success('User added successfully')
      add(data.uploadCustomers)
      close()
    },
  })

  const [updateReq, { loading: updating }] = useMutation(UPLOAD_CUSTOMERS, {
    onError: () => { toast.error('Error updating customer.') },
    onCompleted: (data) => {
      toast.success('Customer updated successfully')
      update(data.uploadCustomers)
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    if (!phone.startsWith('+')) {
      toast.error("A customer's phone number must start with '+', check and try again")
      return
    }
    if (customer) {
      updateReq({
        variables: {
          id: customer.id,
          name,
          phone,
        },
      })
    } else {
      addCustomerReq({
        variables: {
          storeId,
          customers: {
            name,
            phone,
            email,
            consent,
          },
        },
      })
    }
  }
  return (
    <ResponsiveModal
      title={customer ? 'Edit Customer' : 'Add Customer'}
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions onSave={onSave} loading={loading || updating} close={close} />}
    >
      <Box component="form" id="add-user-form" onSubmit={onSave}>
        <TextField type="text" size="small" required value={name} onChange={(e) => { setName(e.target.value) }} label="Name" variant="outlined" fullWidth />
        <TextField type="text" size="small" required value={phone} onChange={(e) => { setPhone(e.target.value) }} label="Phone Number" variant="outlined" fullWidth sx={{ mt: 2 }} />
        <TextField type="email" size="small" value={email} onChange={(e) => { setEmail(e.target.value) }} label="Email" variant="outlined" fullWidth sx={{ mt: 2 }} />
        <SelectStore required value={storeId} changeHandler={(e) => { setStoreId(e.target.value) }} />
        <Box>
          <FormControlLabel control={<Switch checked={consent} onChange={() => { toggleConsent(!consent) }} />} labelPlacement="start" label="Message Consent" sx={{ pl: 0, ml: 0.5 }} />
        </Box>
      </Box>
    </ResponsiveModal>
  )
}
