/* eslint-disable react/jsx-props-no-spreading */
import { React, useState } from 'react'
import {
  Box, Button, DialogActions, FormControl, InputLabel, MenuItem, Select, TextField,
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'
import toast from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import ResponsiveModal from '../layout/ResponsiveModal'
import SelectStore from '../locations/SelectStore'
import { ADD_USER, UPDATE_USER } from '../../graphql/users'

function Actions({ close, loading }) {
  return (
    <DialogActions>
      <Button onClick={close} sx={{ mr: 2 }}>
        Cancel
      </Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<SaveIcon />}
        variant="contained"
        sx={{ mr: 2 }}
        form="add-user-form"
      >
        {loading ? 'Saving...' : 'Save User'}
      </Button>
    </DialogActions>
  )
}

export default function SaveUser({
  close, user, add, update,
}) {
  const [name, setName] = useState(user?.name || '')
  const [email, setEmail] = useState(user?.email || '')
  const [phone, setPhone] = useState(user?.phone || '')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState(user?.role || '')
  const [storeId, setStoreId] = useState(user?.storeId || '')

  const [addUserReq, { loading }] = useMutation(ADD_USER, {
    onError: () => { toast.error('Error adding user.') },
    onCompleted: (data) => {
      toast.success('User added successfully')
      add(data.inviteUser)
      close()
    },
  })

  const [updateReq, { loading: updating }] = useMutation(UPDATE_USER, {
    onError: () => { toast.error('Error updating user.') },
    onCompleted: (data) => {
      toast.success('User updated successfully')
      update(data.updateUser)
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    if (user) {
      updateReq({
        variables: {
          id: user.id,
          name,
          phone,
        },
      })
    } else {
      addUserReq({
        variables: {
          name,
          email,
          password,
          role,
          storeId: storeId || null,
        },
      })
    }
  }
  return (
    <ResponsiveModal
      title={user ? 'Edit User' : 'Add User'}
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions onSave={onSave} loading={loading || updating} close={close} />}
    >
      <Box component="form" id="add-user-form" onSubmit={onSave}>
        <TextField type="text" size="small" required value={name} onChange={(e) => { setName(e.target.value) }} label="Name" variant="outlined" fullWidth />
        { !user && <TextField type="email" size="small" required value={email} onChange={(e) => { setEmail(e.target.value) }} label="Email" variant="outlined" fullWidth sx={{ mt: 2 }} /> }
        <TextField type="number" size="small" value={phone} onChange={(e) => { setPhone(e.target.value) }} label="Phone Number" variant="outlined" fullWidth sx={{ mt: 2 }} />
        { !user && <TextField type="text" size="small" required value={password} onChange={(e) => { setPassword(e.target.value) }} label="Password" variant="outlined" fullWidth sx={{ mt: 2 }} /> }
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="role-select-label">Role</InputLabel>
          <Select
            disabled={!!user}
            value={role}
            onChange={(e) => (setRole(e.target.value))}
            size="small"
            required
            label="Role"
            labelId="role-select-label"
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="operator">Store Operator</MenuItem>
            <MenuItem value="rider">Store Rider</MenuItem>
            <MenuItem value="owner">Owner</MenuItem>
          </Select>
        </FormControl>
        { role !== 'owner' && role !== 'admin' ? <SelectStore disabled={!!user} required value={storeId} changeHandler={(e) => { setStoreId(e.target.value) }} /> : null }
      </Box>
    </ResponsiveModal>
  )
}
