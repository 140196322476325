// HorizontalBarChart.js
import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto' // Importing 'auto' for auto-registered controllers
import { Box } from '@mui/material'

const HorizontalBarChart = ({ data }) => {
  const chartRef = useRef(null)

  useEffect(() => {
    if (!chartRef.current || !data) {
      return // Skip if the chart or data is not ready
    }

    const ctx = chartRef.current.getContext('2d')

    // Extracting names and counts for the chart
    const names = data.map((item) => item.name)
    const counts = data.map((item) => item.count)

    // Update the existing chart or create a new one
    if (chartRef.current.chart) {
      // If the chart already exists, update its data
      chartRef.current.chart.data.labels = names
      chartRef.current.chart.data.datasets[0].data = counts
      chartRef.current.chart.update()
    } else {
      // If the chart doesn't exist, create a new one
      const myChart = new Chart(ctx, {
        type: 'bar', // Specify the chart type here
        data: {
          labels: names,
          datasets: [{
            label: 'Item Count',
            data: counts,
            backgroundColor: '#5e35b1',
            borderColor: '#9575cd',
            borderWidth: 1,
          }],
        },
        options: {
          indexAxis: 'y',
          // Elements options apply to all of the options unless overridden in a dataset
          // In this case, we are setting the border of each horizontal bar to be 2px wide
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'right',
            },
          },
          scales: {
            x: {
              beginAtZero: true,
            },
          },
        },
      })

      chartRef.current.chart = myChart
    }
  }, [data])

  return (
    <Box sx={{
      width: '100%', height: '340px', position: 'relative',
    }}
    >
      <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />
    </Box>
  )
}

export default HorizontalBarChart
