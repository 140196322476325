import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Box, Divider, Typography } from '@mui/material'
import Welcome from '../components/home/Welcome'
import OrderAnalytics from '../components/home/OrderAnalytics'
import DateRange from '../components/shared/DateRange'
import SelectStoreAnt from '../components/locations/SelectStoreAnt'
import { initDateRange, makePeriod } from '../utils/daterangeinit'

function Home() {
  // State for the selected range
  const { initialStartDate, initialEndDate } = initDateRange(7)
  const [selectedRange, setSelectedRange] = useState([initialStartDate, initialEndDate])
  const [storeId, setStoreId] = useState('all')

  const handleRangeChange = (newRange) => {
    setSelectedRange(newRange)
  }

  const period = makePeriod(selectedRange)

  return (
    <div className="page-content">
      <Welcome />
      <Box className="analytics-header">
        <Typography mr={1}>Filters</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ mr: 1 }}><SelectStoreAnt hasAll changeHandler={(v) => { setStoreId(v) }} /></Box>
          <DateRange value={selectedRange} onChange={handleRangeChange} size="medium" />
        </Box>
      </Box>
      <Divider sx={{ marginX: '-20px' }} />
      <OrderAnalytics storeId={storeId} period={period} />
    </div>
  )
}
const mapStateToProps = (state) => ({
  merchantInfo: state.merchant.merchantInfo,
})

export default connect(mapStateToProps, {})(Home)
