import { gql } from '@apollo/client'

export const BALANCE = gql`
query GET_BALANCE($storeId: ID) {
  balance(storeId: $storeId) {
    totalBalance
    availableBalance
    bankDetails {
      storeId
      bankName
      bankNo
      swiftCode
      bankAccountName
      bankBranch
      bankAccountNumber
    }
  }
}
`

export const UPDATE_WALLET_INFO = gql`
mutation updateWalletInfo(
  $storeId: ID!
  $bankName: String!
  $bankNo: String!
  $swiftCode: String!
  $bankAccountName: String!
  $bankBranch: String!
  $bankAccountNumber: String!
) {
  updateBankDetails(
    storeId: $storeId
    bankName: $bankName
    bankNo: $bankNo
    swiftCode: $swiftCode
    bankAccountName: $bankAccountName
    bankBranch: $bankBranch
    bankAccountNumber: $bankAccountNumber
  ) {
    storeId
    bankName
    bankNo
    swiftCode
    bankAccountName
    bankBranch
    bankAccountNumber
  }
}

`
