import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from '@apollo/client'
import {
  Alert,
  Box,
  Button, CircularProgress, DialogActions, IconButton, TextField, Typography,
} from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  Close, Check, DoneAll, DownloadDone, Remove, Add,
} from '@mui/icons-material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import ResponsiveModal from '../layout/ResponsiveModal'
import { UPDATE_ORDER } from '../../graphql/orders'
import { formatGraphqlError } from '../../utils/formatter'

export function OrderProcessed({ visible, orderStatus }) {
  if (!visible) return null
  if (orderStatus === 'completed') {
    return (
      <div className="order-processed">
        <h5>Completed</h5>
        <CheckCircleOutlineIcon style={{ fontSize: '24px', color: ' green', marginLeft: '5' }} />
      </div>
    )
  }
  if (orderStatus === 'cancelled') {
    return (
      <div className="order-processed">
        <h5>Cancelled</h5>
        <CancelIcon style={{ fontSize: '24px', color: 'red', marginLeft: '5' }} />
      </div>
    )
  }
  return null
}

export function CompleteBtn({ visible, order, updateOrder }) {
  const { orderNo } = order
  if (!visible) return null
  const handleErrors = (e) => {
    const msg = formatGraphqlError(e, 'error updating order')
    toast.error(msg)
  }

  const handleResponse = (data) => {
    toast.success('order completed')
    updateOrder(data.updateOrder)
  }

  const [updateOrderReq, { loading }] = useMutation(UPDATE_ORDER, { onError: handleErrors, onCompleted: handleResponse })

  const handleSubmit = (e) => {
    e.preventDefault()
    updateOrderReq({
      variables: {
        orderNo,
        status: 'completed',
      },
    })
  }

  return (
    <Button
      variant="contained"
      onClick={handleSubmit}
      sx={{
        minWidth: 85, height: 35, backgroundColor: '#0a82f3', ':hover': { backgroundColor: '#0a82f3' },
      }}
      endIcon={<DoneAll />}
      disabled={loading}
    >
      {loading ? <CircularProgress size={20} /> : 'Complete'}
    </Button>
  )
}

export function DispatchBtn({ visible, order, updateOrder }) {
  if (!visible) return null
  const { orderNo } = order
  const handleErrors = (e) => {
    const msg = formatGraphqlError(e, 'error updating order')
    toast.error(msg)
  }

  const handleResponse = (data) => {
    toast.success('order marked as ready')
    updateOrder(data.updateOrder)
  }

  const [updateOrderReq, { loading }] = useMutation(UPDATE_ORDER, { onError: handleErrors, onCompleted: handleResponse })

  const handleSubmit = (e) => {
    e.preventDefault()
    updateOrderReq({
      variables: {
        orderNo,
        status: 'dispatched',
      },
    })
  }

  return (
    <Button
      variant="contained"
      onClick={handleSubmit}
      sx={{
        minWidth: 85, height: 35, backgroundColor: '#ff009db2', ':hover': { backgroundColor: '#ff009db2' },
      }}
      endIcon={<DownloadDone />}
      disabled={loading}
    >
      {loading ? <CircularProgress size={20} /> : 'Ready'}
    </Button>
  )
}

export function AcceptBtn({ visible, order, updateOrder }) {
  if (!visible) return null
  const { orderNo, orderType, pastRadiusConsent } = order
  const [modalOpen, toggleModal] = useState(false)
  const [prepTime, setPrepTime] = useState(30)
  const handleErrors = (e) => {
    const msg = formatGraphqlError(e, 'error updating order')
    toast.error(msg)
  }

  const handleResponse = (data) => {
    toast.success('order accepted')
    updateOrder(data.updateOrder)
    toggleModal(false)
  }

  const [updateOrderReq, { loading }] = useMutation(UPDATE_ORDER, { onError: handleErrors, onCompleted: handleResponse })

  const handleSubmit = (e) => {
    e.preventDefault()
    updateOrderReq({
      variables: {
        orderNo,
        status: 'preparing',
        prepTime: parseInt(prepTime, 10),
      },
    })
  }

  function AcceptAction() {
    return (
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit} fullWidth disabled={loading}>
          {loading ? <CircularProgress size={20} /> : 'Accept'}
        </Button>
      </DialogActions>
    )
  }

  return (
    <>
      <ResponsiveModal
        visible={modalOpen}
        title="Accept Order"
        cancellable
        cancel={() => toggleModal(false)}
        dialogActions={<AcceptAction />}
      >
        <div className="modal-form" onSubmit={handleSubmit}>
          <Typography textAlign="start" sx={{ mb: 1 }} component="p">Confirm you have received this order and will fullfill as requested.</Typography>
          { orderType === 'delivery' && pastRadiusConsent ? (
            <Alert severity="warning" variant="outlined" sx={{ textAlign: 'start', mb: 2 }}>
              Customer is beyond the set delivery radius and they have agreed to pay extra delivery fee. Confirm this before accepting this order.
            </Alert>
          ) : null }
          <Box sx={{
            display: 'flex', alignItems: 'center', mt: 3,
          }}
          >
            <IconButton size="small" sx={{ mr: 1 }} onClick={() => { setPrepTime(parseInt(prepTime, 10) - 5) }}>
              <Remove />
            </IconButton>
            <TextField variant="outlined" size="small" type="number" value={prepTime} label="Preparation Time (Mins)" sx={{ width: '150px' }} onChange={(e) => { setPrepTime(parseInt(e.target.value, 10)) }} />
            <IconButton size="small" sx={{ ml: 1 }} onClick={() => { setPrepTime(parseInt(prepTime, 10) + 5) }}>
              <Add />
            </IconButton>
          </Box>
        </div>
      </ResponsiveModal>
      <Button
        variant="contained"
        onClick={() => toggleModal(true)}
        sx={{ height: 35 }}
        endIcon={<Check />}
      >
        Accept
      </Button>
    </>
  )
}

export function CancelBtn({ visible, order, updateOrder }) {
  if (!visible) return null
  const { orderNo } = order
  const [modalOpen, toggleModal] = useState(false)
  const [cancelReason, setCancelReason] = useState('incorrect details')

  const handleErrors = (e) => {
    const msg = formatGraphqlError(e, 'error updating order')
    toast.error(msg)
  }

  const handleResponse = (data) => {
    toast.success('order rejected')
    updateOrder(data.updateOrder)
    toggleModal(false)
  }

  const [updateOrderReq, { loading }] = useMutation(UPDATE_ORDER, { onError: handleErrors, onCompleted: handleResponse })

  const handleSubmit = (e) => {
    e.preventDefault()
    updateOrderReq({
      variables: {
        orderNo,
        cancelReason,
        status: 'cancelled',
      },
    })
  }

  function CancelAction() {
    return (
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit} fullWidth disabled={loading} sx={{ backgroundColor: 'darkred', ':hover': { backgroundColor: 'darkred' } }}>
          {loading ? <CircularProgress size={20} /> : 'Reject'}
        </Button>
      </DialogActions>
    )
  }

  return (
    <>
      <ResponsiveModal
        visible={modalOpen}
        title="Reject Order"
        cancellable
        cancel={() => toggleModal(false)}
        dialogActions={<CancelAction />}
      >
        <div className="modal-form" onSubmit={handleSubmit}>
          <Typography textAlign="start">Confirm order cannot be fullfilled as requested.</Typography>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <FormControl sx={{
              marginY: 2, alignSelf: 'start',
            }}
            >
              <InputLabel>Select Reason</InputLabel>
              <Select
                required
                label="Select Reason"
                value={cancelReason}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                onChange={(e) => { setCancelReason(e.target.value) }}
                type="text"
                size="small"
              >
                <MenuItem value="incorrect details">Incorrect Details</MenuItem>
                <MenuItem value="your request">Customer Request</MenuItem>
                <MenuItem value="payment failure">Payment Failure</MenuItem>
                <MenuItem value="items unavailability">Items Unavailability</MenuItem>
                <MenuItem value="store closed">Store Closed</MenuItem>
                <MenuItem value="rider unavailability">Rider Unavailability</MenuItem>
                <MenuItem value="other reason">Other Reason</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
      </ResponsiveModal>
      <Button
        variant="contained"
        onClick={() => toggleModal(true)}
        sx={{
          minWidth: 85, height: 35, backgroundColor: 'darkred', ':hover': { backgroundColor: 'darkred' },
        }}
        endIcon={<Close />}
      >
        Reject
      </Button>
    </>
  )
}
