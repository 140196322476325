import {
  Box, Button, DialogActions, TextField, Typography,
} from '@mui/material'
import { Close, Save } from '@mui/icons-material'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import ResponsiveModal from '../layout/ResponsiveModal'
import { UPDATE_STORE } from '../../graphql/locations'

function updateObjectInArray(array, updateIndex, key, newValue) {
  return array.map((item, index) => {
    if (index !== updateIndex) {
      return item
    }
    const newItem = { ...item }
    newItem[key] = newValue
    return {
      ...item,
      ...newItem,
    }
  })
}

function Actions({ loading, close }) {
  return (
    <DialogActions sx={{
      display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
    }}
    >
      <Button onClick={close} sx={{ mr: 2 }}>Cancel</Button>
      <Button
        type="submit"
        disabled={loading}
        startIcon={<Save />}
        variant="contained"
        form="save-delivery-charges-form"
      >
        {loading ? 'Saving...' : 'Save'}
      </Button>
    </DialogActions>
  )
}

export default function DistanceCharges({
  storeId, deliveryBaseRate, kmCost, storeDeliveryCharges, update, close,
}) {
  const initialDeliveryCharges = storeDeliveryCharges?.length ? storeDeliveryCharges.map((c) => ({
    from: c.from,
    to: c.to,
    amount: c.amount,
  })) : []
  const [deliveryCharges, setDeliveryCharges] = useState(initialDeliveryCharges)
  const [baseRate, setBaseRate] = useState(deliveryBaseRate || 0)
  const [costPerKm, setCostPerKm] = useState(kmCost || 0)

  const handleDeliveryChargeChange = (i, key, newValue) => {
    setDeliveryCharges(updateObjectInArray(deliveryCharges, i, key, Number(newValue)))
  }

  const handleRemoveCharge = (i) => {
    setDeliveryCharges([...deliveryCharges.slice(0, i), ...deliveryCharges.slice(i + 1)])
  }

  const handleAddCharge = () => {
    const newCharge = deliveryCharges.length
      ? {
        from: deliveryCharges[deliveryCharges.length - 1].to,
        to: deliveryCharges[deliveryCharges.length - 1].to + 5,
        amount: deliveryCharges[deliveryCharges.length - 1].amount + 50,
      } : {
        from: 0,
        to: 10,
        amount: 100,
      }
    setDeliveryCharges([...deliveryCharges, newCharge])
  }

  const [updateReq, { loading }] = useMutation(UPDATE_STORE, {
    onError: () => { toast.error('Error updating delivery charges.') },
    onCompleted: (data) => {
      update(data.updateStore)
      toast.success('Delivery charges  updated successfully')
      close()
    },
  })

  const onSave = (e) => {
    e.preventDefault()
    updateReq({
      variables: {
        id: storeId,
        deliveryCharges,
        baseRate: parseFloat(baseRate),
        costPerKm: parseFloat(costPerKm),
      },
    })
  }
  return (
    <ResponsiveModal
      title="Delivery Distance Charges"
      visible
      cancellable
      cancel={close}
      dialogActions={<Actions loading={loading} close={close} />}
    >
      <Box component="form" id="save-delivery-charges-form" onSubmit={onSave}>
        <Box sx={{
          display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', mb: 2,
        }}
        >
          <TextField sx={{ flex: 0.47 }} type="number" label="Base Rate (Ksh)" value={baseRate} size="small" required onChange={(e) => { setBaseRate(e.target.value) }} />
          <TextField sx={{ flex: 0.47 }} type="number" label="Per Km Cost (Ksh)" value={costPerKm} size="small" required onChange={(e) => { setCostPerKm(e.target.value) }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
          <Typography sx={{ flex: 0.3 }}>Distance From (Km)</Typography>
          <Typography sx={{ flex: 0.3 }}>Distance To (Km)</Typography>
          <Typography sx={{ flex: 0.3 }}>Price (Ksh)</Typography>
        </Box>
        { deliveryCharges.map((charge, i) => {
          const isFirst = i === 0
          const { from, to, amount } = charge
          return (
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              mb: 1,
            }}
            >
              <TextField sx={{ flex: 0.3 }} type="number" value={from} size="small" required onChange={(e) => { handleDeliveryChargeChange(i, 'from', e.target.value) }} />
              <TextField sx={{ flex: 0.3 }} type="number" value={to} size="small" required onChange={(e) => { handleDeliveryChargeChange(i, 'to', e.target.value) }} />
              <Box sx={{ flex: 0.3, display: 'flex', alignItems: 'center' }}>
                <TextField type="number" size="small" value={amount} required onChange={(e) => { handleDeliveryChargeChange(i, 'amount', e.target.value) }} />
                <Close sx={{ marginLeft: 2, cursor: 'pointer', visibility: !isFirst ? 'visible' : 'hidden' }} onClick={() => { handleRemoveCharge(i) }} />
              </Box>
            </Box>
          )
        }) }
        <Button variant="outlined" sx={{ width: '100%', my: 2 }} onClick={handleAddCharge}>Add Distance</Button>
      </Box>
    </ResponsiveModal>
  )
}
